import React, { useState, useRef, useCallback } from "react";
import {
 FiSearch,
 FiFilter,
//  FiBarChart2,
 FiChevronLeft,
//  FiX,
 FiUser,
 FiCheck,
} from "react-icons/fi";
import { useInboxContext } from "./InboxContext";
import { useEffect } from "react";
import {
 member_details,
 update_status,
 member_list,
 filterCarenavigatornameList,
} from "../../../services/whatsapp/Service";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import CalendarDatePicker from "../../componenents/CalendarDatePicker";
import moment from "moment";
// import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { MultiSelect } from "react-multi-select-component";

const ContactList = () => {
 const {
 contacts,
 setSelectedContact,
 selectedContacts,
 setSelectedContacts,
 currentPage,
 setCurrentPage,
 hasMore,
 setHasMore,
 loading,
 setFamily,
 setSearchTerm,
 search,
 setStatus,
 setContacts,
 sortOrder,
 setSortOrder,
 status,
 setShowKeyBoard,
 setChatResult,
 setChatpage,
 setLoadingMore,
 setchangeFilterState,
 setLoading,
 unreadCount,
 setUnreadCount,
 currentlySelectedContact,
 setCurrentlySelectedContact,
 chatPage,
 setTemplateStatus,
 optOut,
 setOptOut,
 cnOptions,
 setCnOptions,
 selectedCN,
 setSelectedCN,
 cnFilter,
 setCnFilter,
 } = useInboxContext();

 const observer = useRef();

 const backbutton = () => {
 console.log("here");
 console.log("here", search);

 setActiveMode(null);
 setContacts([]);
 setHasMore(true);
 setStatus("all");

 if (search != "") {
 if (currentPage > 1) {
 setCurrentPage(1);
 }
 setSearchTerm("");
 } else {
 setCurrentPage(1);
 // // fetchContacts("all",1,"",sortOrder)
 // if(currentPage>1){
 // setTimeout(() => {
 // }, 0);
 // }
 if (search == "" && currentPage == 1) {
 fetchContacts("all", 1, "", sortOrder, "");
 }
 }
 // if(currentPage>1){
 // setCurrentPage(1);
 // console.log("firstcall");
 // }else{
 // fetchContacts("all",1,"",sortOrder)
 // console.log("seoncdcalal");
 // }
 };

 const fetchContacts = async (status, page, search, sortOrder, cnId) => {
 setLoading(true);
 // setHasMore(true);
 
 
 try {
 const response = await member_list({
 status: status,
 page: page,
 search: search,
 sort: sortOrder,
 cnId: Array.isArray(cnId) ? cnId.toString() : cnId
 }); // Pass the current page to the API
 if (response.data.data.list && response.data.data.list.length > 0) {
 setContacts((prevContacts) => [
 ...prevContacts,
 ...response.data.data.list,
]); // Append new contacts
setUnreadCount(response.data.data.unreadcount || "0");
 setCnFilter(response.data.data.filterFlag === "1" ? true : false);
 console.log(response.data.data.filterFlag);
 //setChatpage(page + 1); // Update the current page
 } else {
 setHasMore(false); // No more data to load
 // setLoading(false);
 }
 } catch (error) {
 console.error("Failed to fetch contacts:", error);
 // setLoading(false);
 }
 // setHasMore(false)
 setLoading(false);
 };

console.log(cnFilter);


 const fetchCarenavigatorlist = async () => {
    setLoading(true);
    try {
        let resp = await filterCarenavigatornameList(); 
        if(resp.data.code === 200){
            console.log("CN List",resp.data)
            setCnOptions(resp.data.data.carenavigatorList.map(navigator => ({
                label: navigator.fullName,  // MultiSelect needs 'label' for display
                value: navigator.careNavigatorId  // MultiSelect needs 'value' for selection
              })));
            }else{
            console.error("No record found"); 
        }
        } catch (error) {
            console.error("Failed to fetch carenavigator list:", error);
        }
            setLoading(false);
 }


 useEffect(() => {
 fetchContacts(status, currentPage, search, sortOrder, selectedCN );
//  fetchCarenavigatorlist();
 }, [currentPage, search]);

 const dropdownFilter = (e) => {
 console.log("e.target.value", e.target.value);
 const selectedValue = e.target.value; // Capture selected value
 setStatus(selectedValue); // Update status in context
 setContacts([]); // Clear previous contacts
 setHasMore(true);
 //alert(currentPage)
 if (currentPage == 1) {
 fetchContacts(selectedValue, 1, "", sortOrder, selectedCN);
 } else {
 setCurrentPage(1);
 }
 };

 // Callback to handle loading the next page when reaching the bottom of the list
 const lastContactElementRef = useCallback(
 (node) => {
 console.log(loading, "loading");
 if (loading) return;
 if (observer.current) observer.current.disconnect();
 observer.current = new IntersectionObserver(
 (entries) => {
 if (entries[0].isIntersecting && hasMore) {
 setCurrentPage((currentPage) => currentPage + 1); // Load next pag
 // fetchContacts(status, currentPage);
 }
 },
 {
 // Add root margin to trigger slightly before reaching the bottom
 rootMargin: "20px",
 threshold: 0.5,
 }
 );
 if (node) observer.current.observe(node);
 },
 [loading, hasMore]
 );

 const [activeMode, setActiveMode] = useState(null); // 'search', 'filter', or 'selection'
 const [activeTooltip, setActiveTooltip] = useState(null);
 const [memberType, setMemberType] = useState(null);
 const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
 const [endDate, setEndDate] = useState(moment());

 const handleDateChange = ({ start, end }) => {
 setStartDate(start);
 setEndDate(end);
 };

 const toggleMode = (mode) => {
 if (mode === null) {
 setSearchTerm("");
 setContacts([]); // Clear results when exiting search mode
 }
 setActiveMode(activeMode === mode ? null : mode);
 };
 const handleReset = () => {
 setStatus("all"); // Reset chat status
 setSortOrder("Newest"); // Reset sort order
 //setCurrentPage(1); // Call API on Enter
 setSelectedCN([])
 };

 const filterReset = () => {
 // alert("here");
 setActiveMode(null);
 setSelectedCN([]);
 setContacts([]);
 setHasMore(true);
 if (currentPage == 1) {
 fetchContacts("all", 1, "", "","");
 } else {
 setCurrentPage(1);
 }
 setSearchTerm("");
 };

 const handleApply = () => {
 setActiveMode(null); // Close filter view
 setHasMore(true);
 setContacts([]);
 if (currentPage === 1) {
 fetchContacts(status, 1, "", sortOrder, selectedCN);

 } else {
 setCurrentPage(1);
 }
 };

 const handleCareNavigator = (selectedOptions) => {
    
    const selectedValues = selectedOptions.map(option => option.value);
    setSelectedCN(selectedValues);
    setUnreadCount("0")
  };

 const handleKeyDown = (event) => {
 if (!event.target.value) return;
 if (event.target.value?.length > 2) {
 setSearchTerm(event.target.value); // Set search term
 setContacts([]);
 setCurrentPage(1); // Call API on Enter
 setHasMore(true);
 }
 };

 const toggleSelection = (id) => {
 setSelectedContacts((prev) =>
 prev.includes(id)
 ? prev.filter((contactId) => contactId !== id)
 : [...prev, id]
 );
 };

 const refreshmembers = () => {
 setActiveMode(null);
 setSelectedCN([]);
 setContacts([]);
 setHasMore(true);
 setStatus("all"); // Update status in context

 if (currentPage > 1) {
 setCurrentPage(1);
 } else {
 fetchContacts("all", 1, "", sortOrder);
 }
 setSearchTerm("");
 };

 const handleContactClick = async (contact) => {
 if (!activeMode || activeMode !== "selection") {
 console.log("contact", contact);
 if (!contact) return;
 try {
 setChatpage(1);
 setLoadingMore(true);
 setChatResult([]);

 // If contact was unread, decrement the total unread count immediately
 if (contact.unreadCount > 0) {
 setUnreadCount(prevCount => Math.max(0, prevCount - 1));
 
 // Update the contacts array to mark this contact as read
 setContacts(prevContacts => 
 prevContacts.map(c => 
 c.id === contact.id 
 ? { ...c, unreadCount: 0 } 
 : c
 )
 );
 }

 const response = await member_details({ id: contact.id }); // Pass the current page to the API
 if (response.data.code == 200 && response.data.data.member.length > 0) {
 setSelectedContact(response.data.data.member);
 setCurrentlySelectedContact(contact.id);
 setFamily(response.data.data.family);
 setShowKeyBoard(response.data.data.showKeyboard);
 setTemplateStatus(response.data.data.templateStatus);
 setOptOut(response.data.data.optOut);
 console.log(response.data.data.showKeyboard, "ss");
 // if(chatPage>1){
 // //setChatpage(1);
 // }
 // setTimeout(() => {
 // setChatpage(1);
 // }, 1000);

 // setLoadingMore(true);
 // setChatResult([]);
 update_status({ id: contact.id });
 }
 } catch (error) {
 console.error("Failed to fetch contacts:", error);
 }
 }
 };

 return (
 <div className="tw-h-full tw-flex tw-flex-col">
 {/* <Header 
 activeMode={activeMode}
 toggleMode={toggleMode}
 selectedCount={selectedContacts.length}
 /> */}
 <div className="tw-h-full tw-flex tw-flex-col">
 <Header
 activeMode={activeMode}
 toggleMode={toggleMode}
 selectedCount={selectedContacts.length}
 setchangeFilterState={setchangeFilterState}
 fetchContacts={fetchContacts}
 currentPage={currentPage}
 dropdownFilter={dropdownFilter}
 unreadCount={unreadCount}
 refreshmembers={refreshmembers}
 setActiveTooltip={setActiveTooltip}
 activeTooltip={activeTooltip}
 cnFilter={cnFilter}
 fetchCarenavigatorlist={fetchCarenavigatorlist}
 loading={loading}
 />

 {/* Search and Filter View */}
 <div className="tw-h-full tw-overflow-hidden tw-mb-1.5">
 {loading && (
 <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-py-4 tw-text-gray-400">
 <svg
 xmlns="http://www.w3.org/2000/svg"
 width="48"
 height="48"
 viewBox="0 0 24 24"
 >
 <circle cx="4" cy="12" r="3" fill="currentColor">
 <animate
 id="svgSpinners3DotsFade0"
 fill="freeze"
 attributeName="opacity"
 begin="0;svgSpinners3DotsFade1.end-0.25s"
 dur="0.75s"
 values="1;0.2"
 />
 </circle>
 <circle cx="12" cy="12" r="3" fill="currentColor" opacity="0.4">
 <animate
 fill="freeze"
 attributeName="opacity"
 begin="svgSpinners3DotsFade0.begin+0.15s"
 dur="0.75s"
 values="1;0.2"
 />
 </circle>
 <circle cx="20" cy="12" r="3" fill="currentColor" opacity="0.3">
 <animate
 id="svgSpinners3DotsFade1"
 fill="freeze"
 attributeName="opacity"
 begin="svgSpinners3DotsFade0.begin+0.3s"
 dur="0.75s"
 values="1;0.2"
 />
 </circle>
 </svg>
 Loading...
 </div>
 )}
 {activeMode === "search" ? (
 <SearchView
 setActiveMode={setActiveMode}
 setSearchTerm={setSearchTerm}
 handleKeyDown={handleKeyDown}
 fetchContacts={fetchContacts}
 search={search}
 setContacts={setContacts}
 setHasMore={setHasMore}
 setCurrentPage={setCurrentPage}
 backbutton={backbutton}
 searchJSX={
 <div className="tw-mt-2 tw-relative tw-h-full tw-overflow-y-auto">
 {loading && (
 <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-py-4 tw-text-gray-400">
 <svg
 xmlns="http://www.w3.org/2000/svg"
 width="48"
 height="48"
 viewBox="0 0 24 24"
 >
 <circle cx="4" cy="12" r="3" fill="currentColor">
 <animate
 id="svgSpinners3DotsFade0"
 fill="freeze"
 attributeName="opacity"
 begin="0;svgSpinners3DotsFade1.end-0.25s"
 dur="0.75s"
 values="1;0.2"
 />
 </circle>
 <circle cx="12" cy="12" r="3" fill="currentColor" opacity="0.4">
 <animate
 fill="freeze"
 attributeName="opacity"
 begin="svgSpinners3DotsFade0.begin+0.15s"
 dur="0.75s"
 values="1;0.2"
 />
 </circle>
 <circle cx="20" cy="12" r="3" fill="currentColor" opacity="0.3">
 <animate
 id="svgSpinners3DotsFade1"
 fill="freeze"
 attributeName="opacity"
 begin="svgSpinners3DotsFade0.begin+0.3s"
 dur="0.75s"
 values="1;0.2"
 />
 </circle>
 </svg>
 Loading...
 </div>
 )}
 {contacts.length > 0 ? (
 <ContactItems
 contacts={contacts}
 selectionMode={activeMode === "selection"}
 selectedContacts={selectedContacts}
 toggleSelection={toggleSelection}
 handleContactClick={handleContactClick}
 currentlySelectedContact={currentlySelectedContact}
 lastContactElementRef={lastContactElementRef}
 setTemplateStatus={setTemplateStatus}
 />
 ) : (
 <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-py-4 tw-text-gray-400">
 <i class="bi bi-chat-right tw-text-5xl"></i>
 No results found
 </div>
 )}
 </div>
 }
 />
 ) : activeMode === "filter" ? (
 <FilterView
 setActiveMode={setActiveMode}
 sortOrder={sortOrder}
 setSortOrder={setSortOrder}
 setStatus={setStatus}
 status={status}
 handleReset={handleReset}
 handleApply={handleApply}
 setHasMore={setHasMore}
 setCurrentPage={setCurrentPage}
 setContacts={setContacts}
 setSearchTerm={setSearchTerm}
 filterReset={filterReset}
 memberType={memberType}
 setMemberType={setMemberType}
 handleDateChange={handleDateChange}
 startDate={startDate}
 endDate={endDate}
 cnOptions={cnOptions}
 selectedCN={selectedCN}
 handleCareNavigator={handleCareNavigator}
 />
 ) : (
 <div className="tw-mt-2 tw-relative tw-h-full tw-overflow-y-auto">
 {/* {contacts.length > 0 ? (
 <ContactItems
 contacts={contacts}
 selectionMode={activeMode === "selection"}
 selectedContacts={selectedContacts}
 toggleSelection={toggleSelection}
 handleContactClick={handleContactClick}
 currentlySelectedContact={currentlySelectedContact}
 lastContactElementRef={lastContactElementRef}
 setTemplateStatus={setTemplateStatus}
 />
 ) : (
 <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-py-4 tw-text-gray-400">
 <i class="bi bi-chat-right tw-text-5xl"></i>
 No results found
 </div>
 )} */}

 {contacts.length > 0 ? (
 <ContactItems
 contacts={contacts}
 selectionMode={activeMode === "selection"}
 selectedContacts={selectedContacts}
 toggleSelection={toggleSelection}
 handleContactClick={handleContactClick}
 currentlySelectedContact={currentlySelectedContact}
 lastContactElementRef={lastContactElementRef}
 setTemplateStatus={setTemplateStatus}
 />
 ) : (
 <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-py-4 tw-text-gray-400">
 <i className="bi bi-chat-right tw-text-5xl"></i>
 No results found
 </div>
 )}
 </div>
 )}
 </div>
 </div>
 </div>
 );
};

const CustomTooltip = ({ text, visible }) => {
 if (!visible) return null;

 return (
 <div className="tw-absolute -tw-left-[160%] -tw-bottom-[170%] tw--translate-y-1/2 tw-mr-2 tw-z-[999999] tw-pointer-events-none">
 <div className="tw-bg-[#1279BE] tw-text-white tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-whitespace-nowrap tw-relative">
 {text}
 {/* Arrow pointing up */}
 <div className="tw-absolute tw-left-1/2 tw-top-[-6px] tw--translate-x-1/2 tw-w-0 tw-h-0 tw-border-b-4 tw-border-x-4 tw-border-solid tw-border-x-transparent tw-border-b-[#1279BE]" />
 </div>
 </div>
 );
};

const Header = ({
 activeMode,
 toggleMode,
 selectedCount,
 setchangeFilterState,
 fetchContacts,
 currentPage,
 dropdownFilter,
 unreadCount,
 refreshmembers,
 setActiveTooltip,
 activeTooltip,
 cnFilter,
 fetchCarenavigatorlist,
  loading,
}) => {
 const {
 setStatus,
 setCurrentPage,
 setContacts,
 status,
 setHasMore,
 } = useInboxContext(); // Include status

 if (activeMode === "selection") {
 return (
 <div className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-border-b tw-bg-white tw-rounded-">
 <div className="tw-flex tw-items-center">
 <button onClick={() => toggleMode(null)} className="tw-mr-2">
 <FiChevronLeft />
 </button>
 <span>{selectedCount} selected</span>
 </div>
 <div>
 <button className="tw-mr-2">
 <FiUser />
 </button>
 <button>
 <FiCheck />
 </button>
 </div>
 </div>
 );
 }

 return (
 <div className="tw-flex tw-justify-between tw-items-center tw-p-2 tw-border-b tw-bg-white tw-relative tw-z-[9999]">
 <div className="tw-flex tw-items-center tw-gap-3 tw-flex-1 tw-max-w-[600px]">
 <div
 className={`tw-flex-1 ${
 unreadCount.toString().length === 1
 ? "tw-max-w-[150px]"
 : unreadCount.toString().length === 2
 ? "tw-max-w-[160px]"
 : unreadCount.toString().length === 3
 ? "tw-max-w-[170px]"
 : "tw-max-w-[180px]"
 } tw-relative`}
 >
 <select
 onChange={(e) => dropdownFilter(e)}
 value={status}
 className="form-select input-field-ui"
 >
 <option value="all">All</option>
 <option value="read">Read</option>
 <option value="unread">Unread</option>
 </select>
 {unreadCount >= 0 && (
 <div className="tw-absolute tw-right-8 tw-top-1/2 tw-transform -tw-translate-y-1/2">
 <span
 onMouseEnter={() => setActiveTooltip("unreadCount")}
 onMouseLeave={() => setActiveTooltip(null)}
 className="tw-inline-flex tw-items-center tw-justify-center tw-min-w-[30px] tw-h-[28px] tw-bg-[#03335b] tw-text-white tw-rounded-xl tw-px-2 tw-relative"
 >
 <span className="tw-text-sm">{unreadCount}</span>
 <CustomTooltip
 text="Unread Members"
 visible={activeTooltip === "unreadCount"}
 />
 </span>
 </div>
 )}
 </div>

 {/* {unreadCount>=0 && (
 <div className="tw-flex-shrink-0">
 <OverlayTrigger
 placement="top"
 overlay={
 <Tooltip>
 Unread Chats
 </Tooltip>
 }>
 <span className="tw-inline-flex tw-items-center tw-justify-center tw-min-w-[40px] tw-h-[38px] tw-bg-[#03335b] tw-text-white tw-rounded-xl tw-px-3">
 <span className="tw-text-base">{unreadCount}</span>
 </span>
 </OverlayTrigger>
 </div>
 )} */}
 </div>

 <div className="tw-flex tw-items-center tw-gap-2 tw-ml-2">
 <span
 onClick={!loading ? refreshmembers : undefined}
 className={`
   tw-flex-shrink-0 tw-inline-flex tw-cursor-pointer tw-items-center tw-justify-center tw-h-[38px] 
   tw-text-white tw-rounded-xl tw-px-3 tw-transition-colors tw-duration-200
   ${loading ? 
     'tw-bg-gray-400 tw-cursor-not-allowed' : 
     'tw-bg-[#03335b] hover:tw-bg-[#1279BE]'
   }
 `}
 >
 <i className="bi bi-arrow-clockwise tw-text-lg"></i>
 </span>
 <span
 onClick={() => toggleMode("search")}
 className="tw-inline-flex tw-items-center tw-cursor-pointer tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3"
 >
 <FiSearch size={18} />
 </span>

{cnFilter && (
 <span 
 onClick={() => {
    toggleMode("filter");
    fetchCarenavigatorlist();
 }} 
 className="tw-inline-flex tw-items-center tw-cursor-pointer tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3"
 >
 <FiFilter size={18} />
 </span>
)}
 </div>
 </div>
 );
};

const SearchView = ({
 setActiveMode,
 handleKeyDown,
 searchTerm,
 setSearchTerm,
 fetchContacts,
 search,
 setContacts,
 setHasMore,
 setCurrentPage,
 backbutton,
 searchJSX,
}) => (
 <div className="tw-absolute tw-inset-0 tw-bg-white tw-z-[999999] tw-flex tw-flex-col">
 <div className="tw-p-4">
 <div className="tw-flex tw-items-center tw-mb-4">
 <span
 onClick={backbutton}
 className="tw-mr-2 tw-mb-2 tw-cursor-pointer tw-text-gray-500 tw-text-lg tw-font-bold"
 >
 <FiChevronLeft />
 </span>
 <h5 className="tw-font-bold tw-text-gray-500">Search Inbox</h5>
 </div>
 <div className="tw-relative">
 <input
 type="text"
 placeholder="Search By Member ID"
 className="form-control input-field-ui tw-pl-9"
 value={searchTerm}
 onChange={handleKeyDown}
 />
 <FiSearch
 className="tw-absolute tw-left-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-text-gray-400"
 size={20}
 />
 </div>
 </div>
 <div className="tw-flex-1 tw-overflow-hidden">{searchJSX}</div>
 </div>
);

const FilterView = ({
 setActiveMode,
 setStatus,
 status,
 setContacts,
 setHasMore,
 setCurrentPage,
 setSearchTerm,
 sortOrder,
 setSortOrder,
 handleApply,
 handleReset,
 filterReset,
 memberType,
 setMemberType,
 handleDateChange,
 startDate,
 endDate,
 cnOptions,
 selectedCN,
 handleCareNavigator,
}) => {

    const selectedValues = cnOptions.filter(option => selectedCN.includes(option.value));
    
    console.log(selectedCN);
    

    return (
 <div className="tw-absolute tw-inset-0 tw-bg-white tw-z-[999999] tw-overflow-y-auto">
 <div className="tw-p-4">
 <div className="tw-flex tw-justify-start tw-items-center tw-mb-4">
 <span
 onClick={filterReset}
 className="tw-mr-2 tw-mb-1 tw-cursor-pointer tw-text-gray-500 tw-text-lg"
 >
 <FiChevronLeft className="tw-mb-1.5" />
 </span>
 <h5 className="tw-font-bold tw-text-gray-500">Select filters</h5>
 </div>
 {/* <div className="tw-mb-4">
 <p className="tw-font-semibold tw-mb-2 tw-text-gray-500">Chat Status</p>
 <select
 className="form-select input-field-ui"
 value={status} // Bind chatStatus prop
 onChange={(e) => setStatus(e.target.value)} // Update state on change
 >
 <option value="">All</option>
 <option value="Read">Read</option>
 <option value="Unread">Unread</option>
 </select>
 </div> */}
 {/* <div className="tw-mb-4">
 <p className="tw-font-semibold tw-mb-2 tw-text-gray-500">Sort</p>
 <select
 className="form-select input-field-ui"
 value={sortOrder} // Bind sortOrder prop
 onChange={(e) => setSortOrder(e.target.value)} // Update state on change
 >
 <option value="Newest">Newest</option>
 <option value="Oldest">Oldest</option>
 </select>
 </div> */}

 <div className="tw-mb-4">
 <p className="tw-font-semibold tw-mb-2 tw-text-gray-500">Filter By CareNavigator</p>
 <MultiSelect
  className="rmsc-2 form-select-2 select-input-ui-2 select-input-ui-select-2"
  options={cnOptions}
  value={selectedValues}
  onChange={handleCareNavigator}
  labelledBy="Select Teams to Utilize Template"
  overrideStrings={{
    selectSomeItems: "Choose CareNavigator",
    search: "Search...",
    noOptions: "Record not matched",
  }}
/>
 </div>
 {/* <div className="tw-mb-4">
 <p className="tw-font-semibold tw-mb-2 tw-text-gray-500">Date Range Filter</p>
 <div className="tw-w-full">
 <CalendarDatePicker
 startDate={startDate}
 endDate={endDate}
 onDateChange={handleDateChange}
 left={true}
 />
 </div>
 </div> */}
 <div className="tw-flex tw-justify-between">
 <button onClick={handleReset} className="btn btn-outline-dark hover:tw-bg-[#1279BE]">
 Reset
 </button>
 <button
 onClick={handleApply}
 className="btn btn-primary clear-search-btn"
 >
 Apply Filters
 </button>
 </div>
 </div>
 </div>
)};

const ContactItems = ({
 contacts,
 selectionMode,
 selectedContacts,
 toggleSelection,
 handleContactClick,
 currentlySelectedContact,
 lastContactElementRef,
 setTemplateStatus,
}) => {

 const [clearedUnreads, setClearedUnreads] = React.useState({});

 const truncateText = (text) => {
 const words = text.split(" ");
 if (words.length > 3) {
 return words.slice(0, 3).join(" ") + "...";
 }
 return text;
 };

 const replaceVariables = (content) => {
 if (!content) return "";

 return (
 content
 // Handle line breaks first
 .replace(/\\n/g, "<br />")

 // Bold: *text* (not preceded or followed by space)
 .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

 // Italic: _text_ (not preceded or followed by space)
 .replace(/(?<!\s)_(.*?)_(?!\s)/g, "<em>$1</em>")

 // Strikethrough: ~text~ (not preceded or followed by space)
 .replace(/(?<!\s)~(.*?)~(?!\s)/g, "<del>$1</del>")

 // Monospace: ```text```
 .replace(/```(.*?)```/g, "<code>$1</code>")

 // Single line code: `text`
 .replace(/`(.*?)`/g, "<code>$1</code>")

 // URLs: automatically link URLs
 .replace(
 /(https?:\/\/[^\s]+)/g,
 '<a href="$1" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">$1</a>'
 )

 // WhatsApp style quotes: > at start of line
 .replace(
 /(^|\n)&gt; ?([^\n]*)/g,
 '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>'
 )

 // Lists: - or • at start of line
 .replace(
 /(^|\n)[-•] ?([^\n]*)/g,
 '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>'
 )

 // Handle multiple underscores or asterisks in a single word
 .replace(/(\*{2}|_{2})(.*?)\1/g, "<strong>$2</strong>")
 .replace(/(\*{3}|_{3})(.*?)\1/g, "<strong><em>$2</em></strong>")

 // Fix any potentially broken HTML from nested formatting
 .replace(/<(\/?)(strong|em|del|code)>/g, "<$1$2>")
 );
 };

 const MEDIA_TYPES = {
 image: {
 icon: "bi-camera",
 label: "Image",
 },
 audio: {
 icon: "bi-music-note",
 label: "Audio",
 },
 video: {
 icon: "bi-camera-video",
 label: "Video",
 },
 document: {
 icon: "bi-file-earmark",
 label: "Document",
 },
 };

 const handleMediaMessage = (msgType) => {
 return Boolean(msgType && MEDIA_TYPES[msgType.toLowerCase()]);
 };

 const handleContactItemClick = (contact) => {
 // Mark this contact's unread as cleared
 setClearedUnreads(prev => ({
 ...prev,
 [contact.id]: true
 }));
 
 handleContactClick(contact);
 };

 const shouldShowUnreadCount = (contact) => {
 return contact.unreadCount > 0 && 
 currentlySelectedContact !== contact.id && 
 !clearedUnreads[contact.id];
 };

 return (
 <div className="tw-overflow-y-auto">
 {contacts.map((contact, index) => (
 <div
 key={contact.id}
 ref={index === contacts.length - 1 ? lastContactElementRef : null}
 className={`tw-flex tw-relative tw-z-0 border-bottom hover:tw-bg-gray-100 tw-rounded-xl tw-transition-all tw-duration-300 tw-items-center tw-px-3 tw-py-2 tw-cursor-pointer 
 ${
 selectionMode && selectedContacts.includes(contact.mobile)
 ? "tw-bg-blue-300"
 : ""
 }
 ${
 !selectionMode && currentlySelectedContact === contact.mobile
 ? "tw-bg-blue-100"
 : ""
 }
 ${
 currentlySelectedContact === contact.id
 ? "tw-bg-[#f0f4ff] tw-pointer-events-none"
 : ""
 } // Add styles for disabled
 `}
 onClick={() => handleContactItemClick(contact)}
 >
 <div className="tw-flex-grow tw-w-full">
 <div className="tw-flex tw-justify-between tw-items-start tw-w-full">
 <span className="tw-font-semibold tw-truncate tw-max-w-[70%]">
 {contact.memberName}
 </span>
 <span className="tw-text-xs tw-text-gray-500 tw-max-w-[25%] tw-text-right">
 {contact.messageTime}
 </span>
 </div>
 <div className="tw-flex tw-items-center tw-justify-between">
 <div className="tw-flex tw-items-center tw-gap-2">
 {handleMediaMessage(contact?.msgType) ? (
 <div className="tw-flex tw-items-center tw-gap-2">
 <i
 className={`bi ${
 MEDIA_TYPES[contact.msgType.toLowerCase()].icon
 }`}
 ></i>
 {contact.messageText ? (
 <span
 dangerouslySetInnerHTML={{
 __html: truncateText(
 replaceVariables(contact.messageText)
 ),
 }}
 className="tw-text-sm tw-text-gray-500 tw-break-words"
 />
 ) : (
 <span className="tw-text-sm tw-text-gray-500">
 {MEDIA_TYPES[contact.msgType.toLowerCase()].label}
 </span>
 )}
 </div>
 ) : (
 contact?.messageText && (
 <span
 dangerouslySetInnerHTML={{
 __html: truncateText(
 replaceVariables(contact.messageText)
 ),
 }}
 className="tw-text-sm tw-text-gray-500 tw-break-words"
 />
 )
 )}
 </div>

 {shouldShowUnreadCount(contact) && (
 <span className="tw-text-xs tw-text-white tw-px-2 tw-py-1 tw-bg-[#03335b] tw-rounded-full">
 {contact.unreadCount}
 </span>
 )}
 </div>
 </div>
 {/* <hr></hr> */}
 </div>
 ))}
 </div>
 );
};

export default ContactList;
