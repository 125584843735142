import React, { useState, useEffect } from 'react'
import PageDescription from '../../Components/PageDescription/PageDescription'
import { Link } from 'react-router-dom';
import DropDownActionNew from '../../../componenents/ContentPrograms/DropDownActionNew';
import DataTable from 'react-data-table-component';
import { fetch_reports,fetch_fileurl } from '../../../../services/hcc/Service';
import CalendarDatePicker from '../../../componenents/CalendarDatePicker';
import moment from 'moment';
import { decryptData } from '../../../utils/Utils';
import {LoaderLoader} from '../../../utils/CommonLibrary'





const HccReports = () => {

    const storedDates = localStorage.getItem("selectedDates")
        ? JSON.parse(decryptData(localStorage.getItem("selectedDates")))
        : "";

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );

    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const [reports, setReports] = useState([]); 
    const [isLoading, setIsLoading] = useState(false); 
    

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    useEffect(() => { 
        fetchReports();
    }, [startDate, endDate]); 

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

     const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end); 
      };

      const downloadLink = async (reportId)=>{
            try{
                setIsLoading(true);
                 let resp = await fetch_fileurl({reportId});
                 let downloadUrl = resp.data.data.url;
             
                 if (downloadUrl) {
                    const link = document.createElement('a');
                    const filename = `report_${reportId}.xls`;
                    link.href = downloadUrl;
                    link.setAttribute('download', filename); // Specify the filename here if necessary
                    document.body.appendChild(link);
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                  } else {
                    console.error('Download URL not found in response');
                  }
            }catch (error) {
                console.error(error);
            } 
            finally {
                  setIsLoading(false);  // Will be called regardless of success or failure
            }
        }

    const columns = [
        {
            name: 'Generated By',
            selector: row => row.generatedByName,
            sortable: true,
        },
        {
            name: 'Report Type',
            selector: row => row.reportType,
            sortable: true,
        },
       {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
        },

        { 
            name: 'End Date',
            selector: row => row.endDate,
            sortable: true,
        }, 
        {
            name: 'Generated On',
            selector: row => row.createdDate,
            sortable: true,
        },
        {
            name: 'Download Reports',
            cell: row => (
                <div>
                    {row.reportStatus === 'completed' ? <span onClick={()=>downloadLink(row.reportId)} style={{cursor:'pointer'}}> <i className="bi bi-download"></i></span>
                    : 'Pending'}
                </div>
            ),
        },
    ];


    async function fetchReports(){ 
        setIsLoading(true);
        
        try{ 
            let response = await fetch_reports({startDate: formatDate(startDate), endDate: formatDate(endDate)});

            if(response?.data?.code === 200){
                setReports(response?.data?.data?.reports);
            }
        }catch(e){ 

        }finally{ 
            setIsLoading(false);
        }
    }

    return (
        <div className='ReportsPrograms'>
            <LoaderLoader isLoading={isLoading} />
            <div className='container-fluid'>
                <div className='d-flex justify-content-between align-item-center'> 
                    <div className="col-md ">
                        <h1 className="main-title">Reports</h1>
                    </div>

                    <div className="col-md-3">
                        <CalendarDatePicker
                            startDate={startDate}
                            endDate={endDate}
                            onDateChange={handleDateChange}
                        />
                    </div>

                </div>

                <div className='mt-3'>          
                    <PageDescription text={"The Reports tab allows HCC Agents to track and download ticket-related reports generated from the All Tickets tab. It shows the report type, generated by, status (e.g., pending or completed), and download links for completed reports. This helps agents efficiently access and manage their generated reports."} image={"https://storage.googleapis.com/ksabupatest/2025/01/21/tefhz/tl5jbj8jsa.png"} />
                </div>

                
                <div className='reports-programs mb-3'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <DataTable 
                                    columns={columns}
                                    data={reports}
                                    pagination
                                    fixedHeader
                                    highlightOnHover
                                    customStyles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HccReports
