import React, { useState, useEffect, useRef} from "react";

import {
  agent_call_back_plan,
  agent_call_disconnect,
  direct_agent_call_disconnect,
  agent_call_hold,
  agent_call_initiate,
  agent_call_mute,
  agent_call_unhold,
  agent_create_pool,
  agent_enroll_user,
  agent_stats,
  agent_transfer_call,
  fetch_single_user_data_list,
  fetch_soft_number,
  direct_fetch_soft_number,
  fetch_soft_phone_token,
  fetch_user_callback_data_list,
  fetch_user_data_list,
  make_soft_phone_call,
  fetch_carenavigator_list,
  family_fetch_carenavigator_list,
  agent_call_user_status,
  reject_enrollment,
  family_reject_enrollment,
  fetch_available_slot,
  save_carenavigator_slot,
  call_disposition,
  direct_call_disposition,
  family_direct_call_disposition,
  call_disposition_list,
  data_quality_list,
  save_alt_number,
  cn_call_exit,
  accept_enrollment,
  direct_accept_enrollment,
  cn_call_user_status,
  soft_number_logger,
  data_quality_disposition_list,
  incorrect_data,
  family_incorrect_data,
  enroll_later,
  family_enroll_later,
  fetch_incorrect_data,
  save_members_notes,
  fetch_members_notes,
  fetch_family_user_data_list,
  getuserCallBack,
  updateMobileNo,
  getScripts,
  fetch_carenavigator_list_transfer,
  updateMainMembershipNo,
  fetch_program_condition,
  update_program_condition,
  fetchDoctorList, 
  fetchDoctorSlots, 
  bookMemberAppointment, 
  memberDetailsEdge

} from "../../../services/met/Service";
import {
  LoaderLoaderConnect,
} from "../../utils/CommonLibrary";
import { Device } from "twilio-client";
import { Modal } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import AddMember from "../components/addmember/AddMember";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import firebase from '../../../firebase/firebase';
import { getDatabase, ref, onValue, child, get, set } from "firebase/database";
import { useNavigate } from "react-router-dom";
import CommunicationModal from './CommunicationModal';
import { Mic, MicOff, AlertCircle } from 'lucide-react';
import MicrohponeCheck from "../../componenents/Modals/MicrohponeCheck";
import { setWebexTransferCall } from "../../../store/webexSlice";
import CallBackList from "./Lists/CallbackList/CallbackList";
import TeleHealthBook from "../../cn/components/TeleHealthBook/TeleHealthBook";


function ContentMemberConnectAgentDetails() {
  const [languageButton, setLanguageButton] = useState("English");
  const [connectType, setConnectType] = useState(["whatsapp"]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showA, setShowA] = useState(false);

  const languageOptions = [
    { label: 'English', value: 'English' },
    { label: 'Arabic', value: 'Arabic' }
  ];

  const communicationOptions = [
    // { label: 'Phone Call', value: 'phoneCall' },
    { label: 'WhatsApp', value: 'whatsapp' },
    // { label: 'SMS', value: 'sms' },
    // { label: 'Email', value: 'email' },
    // { label: 'App Notification', value: 'appNotification' },
  ];

  const handleChannelSelect = (value) => {
    setConnectType(value);
    // setIsModalOpen(true);
  };


  const [userDataList, setUserDataList] = useState([]);
  const [singleUserDataList, setSingleUserDataList] = useState([]);
  const [singleUserData, setSingleUserData] = useState({});
  const [userCallbackList, setUserCallbackList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [userDataId, setUserDataId] = useState("");
  const [memberDataId, setMemberDataId] = useState("");

  const [isCallDisabled, setIsCallDisabled] = useState(true);
  const [isHoldDisabled, setIsHoldDisabled] = useState(true);
  const [isMuteDisabled, setIsMuteDisabled] = useState(false);
  const [isDiconnectDisabled, setIsDisconnectDisabled] = useState(false);

  const [isUnHoldDisabled, setIsUnHoldDisabled] = useState(false);
  const [isUnMuteDisabled, setIsUnMuteDisabled] = useState(false);

  const [pendingCallback, setPendingCallback] = useState(0);
  const [totalRequestCallBackCount, setTotalRequestCallBackCount] = useState(0);

  const [pendingCallCount, setPendingCallCount] = useState(0);
  const [totalCallCount, setTotalCallCount] = useState(0);
  const [totalMemberReachout, setTotalMemberReachout] = useState(0);
  const [isMemberInterested, setIsMemberInterested] = useState(false);

  //soft call
  const [device, setDevice] = useState(null);
  const [currConnection, setCurrConnection] = useState(null);
  const [callStatus, setCallStatus] = useState("idle");
  const [displayCallStatus, setDisplayCallStatus] = useState("Calling");

  //enabled/disabled
  const [pollingLimit, setPoolingLimit] = useState("");
  const [callAttemptLimit, setCallAttemptLimit] = useState("");
  const [softCallEnabled, setSoftCallEnabled] = useState("");
  const [phoneCallEnabled, setPhoneCallEnabled] = useState("");
  //agentlist
  const [agentData, setAgentData] = useState([]);
  const [onlineAgentData, setOnlineAgentData] = useState([]);
  //button display
  const [buttonDisplay, setButtonDisplay] = useState(false);
  //carenavigotr information
  const [careNavigatorId, setCareNavigatorId] = useState("");
  const [careNavigatorName, setCareNavigatorName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  //call back info
  const [callBackDate, setCallBackDate] = useState("");
  const [callBackTime, setCallBackTime] = useState("");

  //summery data
  const [completedCall, setCompletedCall] = useState(0);
  const [callsCounducted, setCallsCounducted] = useState(0);
  const [enrolledCall, setEnrolledCall] = useState(0);
  const [completeRateCall, setCompleteRateCall] = useState(0);
  const [avgCallTime, setAvgCallTime] = useState(0);
  const [batchNo, setBatchNo] = useState([]);


  const [isCallReportEnabled, setIsCallReportEnabled] = useState(false);

  const [callReportSubmissionMessage, setCallReportSubmissinMessage] = useState(
    {
      status: false,
      message: "",
    }
  );

  // call reoprt state
  const [callReport, setCallReport] = useState({
    doesMemberWantToEnroll: "",
    reason: "",
  });

  const [callReportValidation, setCallReportValidation] = useState({
    doesMemberWantToEnroll: "",
    reason: "",
  });


  // tranfer call
  const [callTransferForm, setCallTransferForm] = useState({
    careNavigatorId: "",
  });

  const [callTransferFormErrors, setCallTransferFormErrors] = useState({
    careNavigatorId: "",
  });

  const [callTransferSubmissionMessage, setCallTransferSubmissionMessage] =

    useState({
      status: "success",
      message: "",
    });

  // timer
  const [timer, setTimer] = useState({
    seconds: "00",
    minutes: "00",
    hours: "00",
    isTimerAlreadyStarted: false,
    intervalId: null,
  });

  const [slotBookingFormData, setSlotBookingFormData] = useState({
    date: "",
    cnId: 0
  });

  const [slotBookingFormDataErrors, setSlotBookingFormDataErrors] = useState({
    date: "",
    selectedSlot: "",
    cnId: ""
  });

  const [enrollLaterForm, setEnrollLaterForm] = useState({
    date: new Date
  });


  const [familyEnrollLaterForm, setFamilyEnrollLaterForm] = useState({ date: new Date }); // possible values : date for each family member
  const [familyEnrollLaterFormErrors, setFamilyEnrollLaterFormErrors] = useState({ date: new Date });


  const [enrollLaterFormErrors, setEnrollLaterFormErrors] = useState({
    date: ""
  });

  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [areSlotsFetched, setAreSlotsFetched] = useState(false);
  const [selectedCareNavigator, setSelectedCareNavigator] = useState("");
  const [slotBookingSubmissionMessage, setSlotBookingSubmissionMessage] =
    useState({
      status: false,
      message: "",
    });

  const [slotsSearchSubmissionMessage, setSlotsSearchSubmissionMessage] =
    useState({
      status: false,
      message: "",
    });

  const [familySlotSearchSubmissionMessage, setFamilySlotSearchSubmissionMessage] = useState({});


  const [familySlots, setFamilySlots] = useState({});
  const [familySelectedSlot, setFamilySelectedSlot] = useState({});
  const [areFamilySlotsFetched, setAreFamilySlotsFetched] = useState({});
  const [familySlotBookingFormData, setFamilySlotBookingFormData] = useState({});
  const [familySlotBookingFormDataErrors, setFamilySlotBookingFormDataErrors] = useState({});
  const [familySelectedCareNavigator, setFamilySelectedCareNavigator] = useState({});
  const [familySlotBookingSubmissionMessage, setFamilySlotBookingSubmissionmessage] = useState({});
  const [isFamilyCnSlotAlareadyBooked, setIsFamilyCnSlotAlreadyBooked] = useState({});

  const [conferenceNumber, setConferenceNumber] = useState(null);
  const [universalConfNumber, setUniversalConfNumber] = useState(null);
  const [copyConferenceNumber, setCopyConferenceNumber] = useState(null);

  // callback form
  const [callbackForm, setCallbackForm] = useState({
    date: new Date(),
    time: "",
    notes: "",
  });

  const [callbackFormErrors, setCallbackFormErrors] = useState({
    date: "",
    time: "",
    notes: "",
  });

  const [callBackSubmissionMessage, setCallbackSubmissionMessage] = useState({
    status: false,
    message: "",
  });

  const [familyCallbackForm, setFamilyCallbackForm] = useState({})

  const [familyCallbackFormErrors, setFamilyCallbackFormErrors] = useState({});

  const [familyCallBackSubmissionMessage, setFamilyCallbackSubmissionMessage] = useState({})




  //  disposition

  const [shouldShowDisposition, setShouldShowDisposition] = useState(false);
  const [showDispositionModal, setShowDispositionModal] = useState(false);
  const [showMockDispositionModal, setShowMockDispositionModal] = useState(false);


  const closeModal = useRef(null);
  const closeMockModal = useRef(null);
  const callModalOpener = useRef(null);
  const familyCallModalOpener = useRef(null);
  const mobileEditInput = useRef(null);
  const conditionModalCloseBtn = useRef(null);

  const [dispositionForm, setDispositionForm] = useState({
    dataRating: "",
    remarks: "",
    reason: ""
  });

  const [dispositionFormErrors, setDispositionFormErrors] = useState({
    dataRating: "",
    remarks: "",
    reason: ""
  });


  const [mockDispositionForm, setMockDispositionForm] = useState({
    callStatus: "",
    dataRating: "",
    remarks: "",
    reason: ""
  });

  const [mockDispositionFormErrors, setMockDispositionFormErrors] = useState({
    callStatus: "",
    dataRating: "",
    remarks: "",
    reason: ""
  });

  const [isEligibleForPool, setIsEligibleForPool] = useState(false);

  const [dispositionReasons, setDispositionReasons] = useState({
    good: [],
    poor: []
  });


  const [dispositionSubmissionMessage, setDispositionSubmissionMssage] = useState({
    status: false,
    message: ""
  });

  const [poolId, setPoolId] = useState("");

  const [isCallMergedWithCn, setIsCallMergedWithCn] = useState(false);
  const [isCallPollOn, setIsCallPollOn] = useState(false);



  // handle disposition change
  const handleDispositionChange = (e) => {
    setDispositionForm({
      ...dispositionForm,
      [e.target.name]: e.target.value
    });


    setDispositionFormErrors({
      ...dispositionFormErrors,
      [e.target.name]: ""
    })
  }

  const handleMockDispositionChange = (e) => {
    setMockDispositionForm({
      ...mockDispositionForm,
      [e.target.name]: e.target.value
    });


    setMockDispositionFormErrors({
      ...mockDispositionFormErrors,
      [e.target.name]: ""
    })
  }

  // disposition state
  const [dispositionList, setDispositionList] = useState([]);
  const [incorrectDataList, setIncorrectDataList] = useState([]);
  const [memberCallList, setMemberCall] = useState([]);
  const [memberCallBackList, setMemberCallBackList] = useState([]);


  // script data 
  const [scriptData, setScriptData] = useState([]);
  const [arabicScriptData, setArabicScriptData] = useState([]);

  // stat for reflecting the data 
  const [reflect, setReflect] = useState(false);

  // alternate mobile number 
  const [alternateMobileNo, setAlternateMobileNo] = useState("");
  const [alternateMobileNoError, setAlternateMobileNoError] = useState("");

  // request status of each api for button loading state. 
  const [requestStatus, setRequestStatus] = useState({
    isAlternateMobileNoLoading: false,
    isRefreshPoolLoading: false,
    isCallbackLoading: false,
    isRejectEnrollmentLoading: false,
    isTransferCallLoading: false,
    isSearchLoading: false,
    isBookingLoading: false,
    isDispositionSaveLoading: false,
    isEnrolling: false,
    isEnrollLaterLoading: false,
    isIncorrectDataLoading: false,
    isCallDisconnectLoading: false,
    isCallMergeLoading: false, 
    isCallPlacingRequestLoading: false
  });

  const [alternateMobileNoSubmissionMessage, setAlternateMobileNoSubmissionmessage] = useState({
    status: false,
    message: ""
  });

  // carenavigator hold and unhold state
  const [cnHold, setCnHold] = useState(false);

  const [isSlotAlreadyBooked, setIsSlotAlreadyBooked] = useState(false);

  const [callReportActionCompleted, setCallReportActionCompleted] = useState(false);
  const [callReportActionType, setCallReportActionType] = useState(""); // only four possible values: 1. "" 2. "correct" 3. "in-correct" 4. "nutral"
  const [callReportAction, setCallReportAction] = useState("");

  const [familyCallReportActionCompleted, setFamilyCallReportActionCompleted] = useState({});
  const [familyCallReportActionType, setFamilyCallReportActionType] = useState("");
  const [familyCallReportAction, setFamilyCallReportAction] = useState({});

  const [careNavigatorTransferStatus, setCareNavigatorTransferStatus] = useState({
    callStatus: "idle",
    callDisplayStatus: "Connecting",
    cnCallPollInterval: null
  });

  const [isAlreadyEnrolled, setIsAlreadyEntrolled] = useState(false);

  const isAltNoChangedByUser = useRef(true);
  const [isMobilNoChangedByUser, setIsMobilNoChangedByUser] = useState({});
  const [isAltMobileNoChangedByUser, setIsAltMobilNoChangedByUser] = useState({});

  const [isCallConnected, setIsCallConnected] = useState(false);
  const [isCallPlaced, setIsCallPlaced] = useState(false);
  const [disconnectedBy, setDisconnectedBy] = useState("");

  const [todaysTarget, setTodaysTarget] = useState(0);
  const [showAdMemberModal, setShowAdMemberModal] = useState(false);
  const [callInfoMessaage, setCallInfoMessaage] = useState();
  const [cnBookingSlotLimit, setCnBookingSlotLimit] = useState(21);
  const [fNodeRef, setFNodeRef] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const [isMemberAlreadyEnrolled, setIsMemberAlreadyEnrolled] = useState(false);
  const [alreadyOnboardedMessgae, setAlreadyOnboardedMessage] = useState("");
  const [callNotesList, setCallNotesList] = useState([]);

  const [modalType, setModalType] = useState("agentCallModal2")

  const [familyCallReport, setFamilyCallReport] = useState({});
  const [familyCallReportValidation, setFamilyCallReportValidation] = useState({});
  const [familyCallReportSubmissionMessage, setFamilyCallReportSubmissionMessage] = useState({});
  const [isNewFamily, setIsNewFamily] = useState(false);

  // Setting calling agent: It will have only two possible values as of now: 1. mock_call (normal call) 2. webex
  const [callingAgent, setCallingAgent] = useState("mock_call");
  const [isValidTab, setIsValidTab] = useState(true); 

  const navigate = useNavigate();

  
  // useEffect(() => {
  //   // Function to handle tab validity check
  //   const checkIfTabIsValid = () => {
  //     const isActiveTab = localStorage.getItem("isAlreadyActiveTab");
  //     console.log(isActiveTab); // Check the current state of the storage

  //     if (isActiveTab === "true") {
  //       setIsValidTab(false); // Disable access to this tab
  //       return;
  //     }

  //     // If no active tab entry exists, mark this tab as active
  //     localStorage.setItem("isAlreadyActiveTab", "true");
  //   };

  //   // Check the validity of the current tab on component mount
  //   checkIfTabIsValid();

  //   // Event listener to listen for changes in `localStorage` across tabs
  //   const onStorageChange = (event) => {
  //     if (event.key === "isAlreadyActiveTab" && event.newValue === "true") {
  //       setIsValidTab(false);
  //     }
  //   };

  //   window.addEventListener("storage", onStorageChange);

  //   // Cleanup: remove the event listener and clear the `localStorage` item when the tab is closed
    

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("storage", onStorageChange);
  //     localStorage.removeItem("isAlreadyActiveTab");
  //   };
  // }, []);     
  useEffect(() => {
    window.bootstrap.Modal.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };
  }, []);

  useEffect(() => {
    ringtoneAudio.current.loop = true;
  }, []);

  // listening for teh window unbound event 
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      localStorage.removeItem("isAlreadyActiveTab");
      if (webexCalling) {
        webexCalling.deregister();
      }
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // initializing webex
  useEffect(() => {

    if (callingAgent === "webex") {
      initWebexCalling();

      return () => {
        if (webexCalling) {
          webexCalling.deregister();  
        }
      }
    }
  }, [callingAgent]);


  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === 'hidden') {
        
  //     } else if (document.visibilityState === 'visible') {
  //       initWebexCalling();
  //     }
  //   };
  
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  
  //   return () => { 
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  const [programConditions, setProgramConditions] = useState([]);
  const [fitlerProgramConditions, setFilterProgramConditions] = useState([]);
  const [isAddMemberAllowedInCalingPopup, setIsAddMemberAllowedInCalingPopup] = useState(false);


  // updating filter program conditon 
  useEffect(() => {
    setFilterProgramConditions(programConditions);
  }, [programConditions])

  // handle reason change in disposition
  useEffect(() => {
    if (mockDispositionForm.reason === "") return;

    if (dispositionReasons.good.includes(mockDispositionForm.reason)) {
      setMockDispositionForm({
        ...mockDispositionForm,
        dataRating: '1'
      });
    } else {
      setMockDispositionForm({
        ...mockDispositionForm,
        dataRating: '2'
      });
    }
  }, [mockDispositionForm.reason]);

  // getting clal statuses 
  useEffect(() => {
    const database = getDatabase(firebase);
    // just read values from this database 
    const dbRef = ref(database);
    let realtimeRef;
    let unSubscribe;

    if (copyConferenceNumber) {
      realtimeRef = ref(database, `agent/${agentId}/${copyConferenceNumber}`);
      unSubscribe = onValue(realtimeRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          if (data.status === "Connected") {
            setIsCallReportEnabled(true);
            setCallStatus("in-progress");
            setDisplayCallStatus(data.status);

            setIsMuteDisabled(true);
            setIsHoldDisabled(true);
            setIsDisconnectDisabled(true);
          }

          if (data.status === "Ringing") setDisplayCallStatus(data.status);
          if (data.status === "Calling") setDisplayCallStatus(data.status);

          if (data.status === "Completed") {
            setIsDisconnectDisabled(false);
            setIsHoldDisabled(false);
            setIsMuteDisabled(false);
            unSubscribe();
          }

        }
      });
    }

    return () => {

    }
  }, [copyConferenceNumber]);


  // Member info Modes - Modes will have only two possible valeus: 1. display 2. edit
  const [memberInfoMode, setMemberInfoMode] = useState({
    mobileNo: 'display',
    mainMembershipNo: 'display'
  });


  const [callScripts, setCallScripts] = useState({
    english: "",
    arabic: ""
  })

  // Inside your function where you want to click the button:
  useEffect(() => {
    if (showDispositionModal && closeModal.current) {

      // remove previous modal 
      closeModal.current.click();
      closeMockModal.current.click();

      // clear the state of modal   
      setIsCallReportEnabled(false);
      setCallReport({
        doesMemberWantToEnroll: "",
        reason: "",
      });


      // callback form
      setCallbackForm({
        date: "",
        time: "",
        notes: "",
      });

      setCallbackFormErrors({
        date: "",
        time: "",
        notes: "",
      });

      setCallbackSubmissionMessage({
        status: false,
        message: "",
      });

      setCallReportValidation({
        doesMemberWantToEnroll: "",
        reason: "",
      });

      // tranfer call
      setCallTransferForm({
        careNavigatorId: "",
      });

      setCallTransferFormErrors({
        careNavigatorId: "",
      });

      setCallTransferSubmissionMessage({
        status: "success",
        message: "",
      });

      setSlotBookingFormData({
        date: "",
        time: "",
      });
    }
  }, [showDispositionModal]);


  useEffect(() => {
    if (showMockDispositionModal && closeModal.current) {
      // remove previous modal 
      closeMockModal.current.click();

      setMockDispositionForm({
        callStatus: "",
        dataRating: callReportActionType === "incorrect" ? "2" : (callReportActionType === "correct" ? "1" : ""),
        remarks: "",
        reason: ""
      });

      setMockDispositionFormErrors({
        callStatus: "",
        dataRating: "",
        remarks: "",
        reason: ""
      });

      // clear the state of modal   
      setIsCallReportEnabled(false);
      setCallReport({
        doesMemberWantToEnroll: "",
        reason: "",
      });


      // callback form
      setCallbackForm({
        date: "",
        time: "",
        notes: "",
      });

      setCallbackFormErrors({
        date: "",
        time: "",
        notes: "",
      });

      setCallbackSubmissionMessage({
        status: false,
        message: "",
      });

      setCallReportValidation({
        doesMemberWantToEnroll: "",
        reason: "",
      });

      // tranfer call
      setCallTransferForm({
        careNavigatorId: "",
      });

      setCallTransferFormErrors({
        careNavigatorId: "",
      });

      setCallTransferSubmissionMessage({
        status: "success",
        message: "",
      });

      setSlotBookingFormData({
        date: "",
        time: "",
      });
    }
  }, [showMockDispositionModal]);

  useEffect(() => {
    setMockDispositionForm({
      callStatus: "",
      dataRating: familyCallReportActionType[singleUserData.memberId] === "incorrect" ? "2" : (familyCallReportActionType[singleUserData.memberId] === "correct" ? "1" : ""),
      remarks: "",
      reason: ""
    });
  }, [familyCallReportActionType]);



  // call notes 
  const [callNotes, setCallNotes] = useState("");
  const [callNotesErrors, setCallNotesErrors] = useState("");
  const [callNotesSubmissionMessage, setCallNotesSubmissionMessage] = useState("");

  // reloading all api's on page to show updated data 
  useEffect(() => {
    callDispositionList();
    agentStats();
    fetchUserDataList();
    fetchCallbackUserDataList();
    fetchDispositionReasonsList();
    fetchIncorrectDataList();
  }, [reflect]);


  useEffect(() => {
    loadScripts();
    fetchProgramConditions();
  }, []);


  // timer on call start 
  useEffect(() => {
    // Start timer only once when callStatus becomes "in-progress"
    if (!timer.isTimerAlreadyStarted && callStatus === 'in-progress') {
      toggleTimer();
      setTimer(prevTimer => ({
        ...prevTimer,
        isTimerAlreadyStarted: true,
      }));
    } else if ((callStatus === 'completed' || callStatus === 'idle') && timer.isTimerAlreadyStarted) {
      // Stop timer when callStatus becomes "completed" or "idle"
      toggleTimer(true);
      setTimer({
        ...timer,
        isTimerAlreadyStarted: false,
        intervalId: null,
      });
    }
  }, [callStatus, toggleTimer, timer.isTimerAlreadyStarted]);


  // updating display call status 
  useEffect(() => {
    if (callStatus === "in-progress" && !isCallConnected) {
      setIsCallConnected(true);
    }
  }, [callStatus]);

  // load scripts 
  async function loadScripts() {
    setIsWebexDeviceReady(true);
    let response;
    try {
      response = await getScripts();

      if (response.data.code === 200) {
        let english = response.data?.data?.scriptArr[0];
        let arabic = response.data?.data?.scriptArbArr[0];
        setCallingAgent(response.data?.data?.callingAgent);
        setCallScripts({
          english,
          arabic
        });
        setIsAddMemberAllowedInCalingPopup(response.data?.data?.addMemberEnable);
        setIsWebexDeviceReady(false);
      }
    } catch (e) {
      setIsWebexDeviceReady(false);
    }
  }


  // fetch program conditions 
  async function fetchProgramConditions() {

    let response;
    try {
      response = await fetch_program_condition();
      if (response.data.code === 200) {
        setProgramConditions(response.data.data);
      }
    } catch (e) {

    }
  }

  // fetch call notes list 
  async function fetchCallNotesList(userId) {
    let response;
    try {
      response = await fetch_members_notes({ userId });

      if (response.data?.code === 200) {
        setCallNotesList(response.data?.data || []);
      }
    } catch (error) {

    }
  }


  // ------------------- family states starts -----------------------------
  useEffect(() => {
    let enrollLaterObj = {};
    let enrollLaterErrorsObj = {};
    let familyCallReportObj = {};
    let callReportActionObj = {};
    let callReportActionTypeObj = {};
    let familyCallreportValidationObj = {};
    let familyCallReportSubmissionmessageObj = {};
    let callActionObj = {};
    let setFamlySlotBookingFormDataObj = {};
    let setFamilySlotBookingFormDataErrorsObj = {};
    let areSlotsFetchedObj = {};
    let familySlotSearchSubmissionmessageObj = {};
    let selectedCareNavigatorObj = {};
    let slotBookingSubmissionMessageObj = {};
    let familyCallReportActionObj = {};
    let familyCallbackFormObj = {};
    let familyCallbackFormErrorsObj = {};
    let familyCallBackSubmissionMessageObj = {};
    let mobileChanegdByUser = {};
    let altMobileNoChangedByUser = {};
    let familyCnSlotArleadyBookedObj = {};
    let memberInfoChangeErrorsObj = {};



    // checking if states are already initialized or not by taking any of the state's keys length
    if (isNewFamily) {
      singleUserDataList.map((user) => {
        // enroll later form 
        enrollLaterObj[user.memberId] = { date: new Date() };
        enrollLaterErrorsObj[user.memberId] = { date: "" };



        // family call report 
        familyCallReportObj[user.memberId] = {
          doesMemberWantToEnroll: "",
          reason: ""
        }

        // call report action 
        callReportActionObj[user.memberId] = false;
        callReportActionTypeObj[user.memberId] = "";

        familyCallreportValidationObj[user.memberId] = {
          doesMemberWantToEnroll: "",
          reason: ""
        }

        familyCallReportSubmissionmessageObj[user.memberId] = {
          status: false,
          message: ""
        }

        callActionObj[user.memberId] = "";

        setFamlySlotBookingFormDataObj[user.memberId] = {
          cnId: "",
          date: new Date(),
          time: ""
        }

        // setFamilySlotBookingFormDataErrorsObj[user.memberId] = { 
        //   date: "", 
        //   time: ""
        // }


        setFamilySlotBookingFormDataErrorsObj[user.memberId] = {
          date: "",
          time: "",
          cnId: "",
          selectedSlot: ""
        }


        areSlotsFetchedObj[user.memberId] = false;
        familySlotSearchSubmissionmessageObj[user.memberId] = {
          status: false,
          message: ""
        }

        selectedCareNavigatorObj[user.memberId] = "";
        slotBookingSubmissionMessageObj[user.memberId] = ""
        familyCallReportActionObj[user.memberId] = {};

        familyCallbackFormObj[user.memberId] = {
          date: new Date(),
          time: "",
          notes: ""
        };

        familyCallbackFormErrorsObj[user.memberId] = {
          date: "",
          time: "",
          notes: ""
        };

        familyCallBackSubmissionMessageObj[user.memberId] = {
          status: false,
          message: "",
        };

        altMobileNoChangedByUser[user.memberId] = false;
        memberInfoChangeErrorsObj[user.memberId] = {
          mainMembershipNo: "",
          altMobile: "",
          mobileNo: ""
        }

        familyCnSlotArleadyBookedObj[user.memberId] = false;

      });

      setFamilyEnrollLaterForm(enrollLaterObj);
      setFamilyEnrollLaterFormErrors(enrollLaterErrorsObj);
      setFamilyCallReport(familyCallReportObj);
      setFamilyCallReportActionCompleted(callReportActionObj);
      setFamilyCallReportActionType(callReportActionTypeObj);
      setFamilyCallReportSubmissionMessage(familyCallReportSubmissionmessageObj);
      setFamilyCallAction(callActionObj);
      setFamilySlotBookingFormData(setFamlySlotBookingFormDataObj);
      setFamilySlotBookingFormDataErrors(setFamilySlotBookingFormDataErrorsObj);
      setAreFamilySlotsFetched(areSlotsFetchedObj);
      setFamilySlotSearchSubmissionMessage(familySlotSearchSubmissionmessageObj);
      setFamilySelectedCareNavigator(selectedCareNavigatorObj);
      setFamilySlotBookingSubmissionmessage(slotBookingSubmissionMessageObj);
      setFamilyCallReportAction(familyCallReportActionObj);
      setIsNewFamily(false);

      setFamilyCallbackForm(familyCallbackFormObj);
      setFamilyCallbackFormErrors(familyCallbackFormErrorsObj);
      setFamilyCallbackSubmissionMessage(familyCallBackSubmissionMessageObj);
      setIsMobilNoChangedByUser(mobileChanegdByUser);
      setIsAltMobilNoChangedByUser(altMobileNoChangedByUser);
      setIsFamilyCnSlotAlreadyBooked(familyCnSlotArleadyBookedObj);
      setMemberInfoChangeErrors(memberInfoChangeErrorsObj);
    }
  }, [singleUserDataList]);


  // ------------------- family states ends -------------------------

  function setupNewFamilyMember(user){ 
    let enrollLaterObj = {};
    let enrollLaterErrorsObj = {};
    let familyCallReportObj = {};
    let callReportActionObj = {};
    let callReportActionTypeObj = {};
    let familyCallreportValidationObj = {};
    let familyCallReportSubmissionmessageObj = {};
    let callActionObj = {};
    let setFamlySlotBookingFormDataObj = {};
    let setFamilySlotBookingFormDataErrorsObj = {};
    let areSlotsFetchedObj = {};
    let familySlotSearchSubmissionmessageObj = {};
    let selectedCareNavigatorObj = {};
    let slotBookingSubmissionMessageObj = {};
    let familyCallReportActionObj = {};
    let familyCallbackFormObj = {};
    let familyCallbackFormErrorsObj = {};
    let familyCallBackSubmissionMessageObj = {};
    let mobileChanegdByUser = {};
    let altMobileNoChangedByUser = {};
    let familyCnSlotArleadyBookedObj = {};
    let memberInfoChangeErrorsObj = {};




    // enroll later form 
    enrollLaterObj[user.memberId] = { date: new Date() };
    enrollLaterErrorsObj[user.memberId] = { date: "" };



    // family call report 
    familyCallReportObj[user.memberId] = {
      doesMemberWantToEnroll: "",
      reason: ""
    }

    // call report action 
    callReportActionObj[user.memberId] = false;
    callReportActionTypeObj[user.memberId] = "";

    familyCallreportValidationObj[user.memberId] = {
      doesMemberWantToEnroll: "",
      reason: ""
    }

    familyCallReportSubmissionmessageObj[user.memberId] = {
      status: false,
      message: ""
    }

    callActionObj[user.memberId] = "";

    setFamlySlotBookingFormDataObj[user.memberId] = {
      cnId: "",
      date: new Date(),
      time: ""
    }

   setFamilySlotBookingFormDataErrorsObj[user.memberId] = {
      date: "",
      time: "",
      cnId: "",
      selectedSlot: ""
    }


    areSlotsFetchedObj[user.memberId] = false;
    familySlotSearchSubmissionmessageObj[user.memberId] = {
      status: false,
      message: ""
    }

    selectedCareNavigatorObj[user.memberId] = "";
    slotBookingSubmissionMessageObj[user.memberId] = ""
    familyCallReportActionObj[user.memberId] = {};

    familyCallbackFormObj[user.memberId] = {
      date: new Date(),
      time: "",
      notes: ""
    };

    familyCallbackFormErrorsObj[user.memberId] = {
      date: "",
      time: "",
      notes: ""
    };

    familyCallBackSubmissionMessageObj[user.memberId] = {
      status: false,
      message: "",
    };

    altMobileNoChangedByUser[user.memberId] = false;
    memberInfoChangeErrorsObj[user.memberId] = {
      mainMembershipNo: "",
      altMobile: "",
      mobileNo: ""
    }

    familyCnSlotArleadyBookedObj[user.memberId] = false;

      setFamilyEnrollLaterForm({ ...familyEnrollLaterForm , ...enrollLaterObj});
      setFamilyEnrollLaterFormErrors({...familyEnrollLaterFormErrors,...enrollLaterErrorsObj});
      setFamilyCallReport({...familyCallReport,...familyCallReportObj});
      setFamilyCallReportActionCompleted({...familyCallReportActionCompleted, ...callReportActionObj});
      setFamilyCallReportActionType({...familyCallReportActionType,...callReportActionTypeObj});
      setFamilyCallReportSubmissionMessage({...familyCallReportSubmissionMessage,...familyCallReportSubmissionmessageObj});
      setFamilyCallAction({...familyCallAction,...callActionObj});
      setFamilySlotBookingFormData({...familySlotBookingFormData,...setFamlySlotBookingFormDataObj});
      setFamilySlotBookingFormDataErrors({...familySlotBookingFormDataErrors,...setFamilySlotBookingFormDataErrorsObj});
      setAreFamilySlotsFetched({...areFamilySlotsFetched,...areSlotsFetchedObj});
      setFamilySlotSearchSubmissionMessage({...familySlotSearchSubmissionMessage,...familySlotSearchSubmissionmessageObj});
      setFamilySelectedCareNavigator({...familySelectedCareNavigator,...selectedCareNavigatorObj});
      setFamilySlotBookingSubmissionmessage({...familySlotBookingSubmissionMessage,...slotBookingSubmissionMessageObj});
      setFamilyCallReportAction({...familyCallAction, ...familyCallReportActionObj});
      setFamilyCallbackForm({...familyCallbackForm, ...familyCallbackFormObj});
      setFamilyCallbackFormErrors({...familyCallbackFormErrors, ...familyCallbackFormErrorsObj});
      setFamilyCallbackSubmissionMessage({...familyCallBackSubmissionMessage,...familyCallBackSubmissionMessageObj});
      setIsMobilNoChangedByUser({...isMobilNoChangedByUser, ...mobileChanegdByUser});
      setIsAltMobilNoChangedByUser({...isAltMobileNoChangedByUser, ...altMobileNoChangedByUser});
      setIsFamilyCnSlotAlreadyBooked({...isFamilyCnSlotAlareadyBooked, ...familyCnSlotArleadyBookedObj});
      setMemberInfoChangeErrors({...memberInfoChangeErrors,...memberInfoChangeErrorsObj});
  }

  // fetch online agent  
  async function fetchOnlineAgents() {
    setIsLoading(true);
    try {
      let resp = await fetch_carenavigator_list_transfer();
      if (resp.data.code === 200) {
        setOnlineAgentData(resp.data.data);
      }
    } catch (error) {

    } finally {
      setIsLoading(false); // Will be called regardless of success or failure
    }
  }

  // fetching agent users list 
  const fetchAgentUserlist = async () => {
    setIsLoading(true); //to show loader
    try {
      let resp = await fetch_carenavigator_list();
      if (resp.data.code === 200) {
        setAgentData(resp.data.data);
      }
    } catch (error) {

    } finally {
      setIsLoading(false); // Will be called regardless of success or failure
    }
  };

  // fetch the carenavigators for the family
  const fetchfamilyAgentuserList = async (userId) => {
    setIsLoading(true); //to show loader
    let selectedId;
    try {
      let resp = await family_fetch_carenavigator_list({ userId });
      if (resp.data.code === 200) {
        setAgentData(resp.data.data);

        resp?.data?.data?.forEach(element => {
          if (element.selected) {
            selectedId = element.id;
          }
        });
      }

      return selectedId;
    } catch (error) {

    } finally {
      setIsLoading(false); // Will be called regardless of success or failure
    }

  }


  const postAddMemberCheck = (member) => {
    let mainMembershipNo = singleUserData?.mainMembershipNo;
    if(member.mainMembershipNo === mainMembershipNo){ 
      setSingleUserDataList([...singleUserDataList, member]);  
      setupNewFamilyMember(member); 
    }
  }

  //get user data list
  const fetchUserDataList = async () => {
    setIsLoading(true); //to show loader
    try {
      let resp = await fetch_user_data_list();
      if (resp.data.code === 200) {
        let result = resp.data.data;
        setUserDataList(result || []);
        setCallAttemptLimit(resp.data?.callAttempt || "");
        setPoolingLimit(resp.data?.callAttempt || "");
        setSoftCallEnabled(resp.data?.softCallEnabled || "");
        setPhoneCallEnabled(resp.data?.phonecallEnabled || "");
        setPendingCallCount(resp.data?.pendingCallCount || 0);
        setTotalCallCount(resp.data?.totalCallCount || 0);
      }
      setIsLoading(false); //hide loader
    } catch (error) {
      console.error(error);
    } finally { 
      setIsLoading(false); // Will be called regardless of success or failure
    }
  };


  //get call back user data list
  const fetchCallbackUserDataList = async () => {
    setIsLoading(true); //to show loader
    try {
      let resp = await fetch_user_callback_data_list();
      if (resp.data.code === 200) {
        let result = resp.data.data;

        setUserCallbackList(result || []);
        setPendingCallback(resp.data.pendingCallback || 0);
        setTotalRequestCallBackCount(resp.data?.totalRequestCallBackCount || 0);
      }
      setIsLoading(false); //hide loader
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Will be called regardless of success or failure
    }
    // console.log("userDataList", userDataList);
  };


  const popupSingleUserDetailShow = async (userId, memberId, poolId, type = "single") => {
    setRequestStatus({
      isAlternateMobileNoLoading: false,
      isRefreshPoolLoading: false,
      isCallbackLoading: false,
      isRejectEnrollmentLoading: false,
      isTransferCallLoading: false,
      isSearchLoading: false,
      isBookingLoading: false,
      isDispositionSaveLoading: false,
      isEnrolling: false,
      isEnrollLaterLoading: false,
      isIncorrectDataLoading: false,
      isCallDisconnectLoading: false,
      isCallMergeLoading: false, 
      isCallPlacingRequestLoading: false
    });
    setIsLoading(true);
    setCallInfoMessaage("");
    setCallNotes("");
    setUniversalConfNumber(null);
    setConferenceNumber(null);
    setMemberInfoMode({
      mainMembershipNo: "display",
      mobileNo: "display",
    });

    let resp;
    try {
      setUserDataId(userId);
      setMemberDataId(memberId);
      setPoolId(poolId);
      // console.log("memberNoId", memberId);

      if (type === "family") {
        resp = await fetch_family_user_data_list(userId);
        // callModalOpener.current.setAttribute('data-bs-target', '#agentCallModal3');
      } else {
        resp = await fetch_single_user_data_list(userId);
        // callModalOpener.current.setAttribute('data-bs-target', '#agentCallModal2');
      }

      if (resp.data.code === 200) {
        if (!resp?.data?.actioned) {
          setIsMemberAlreadyEnrolled(true);
          setAlreadyOnboardedMessage(resp?.data?.actionedMsg);
          setReflect(!reflect);
        } else {
          setIsNewFamily(true);
          let result = resp?.data?.data;
          result = result || [];


          // copying the program & condition array 
          result = result.map((item) => {
            item['copyProgramArr'] = [...item['programArr']];
            return item;
          });

          setSingleUserDataList(result || []);
          setSingleUserData(result[0] || {});
          setAlternateMobileNo(result[0]?.altMobile);
          isAltNoChangedByUser.current = false;
          fetchAgentUserlist();
          setMemberCall(resp?.data?.callData);
          setMemberCallBackList(resp?.data?.callBackData);
          // fetching user's notes
          fetchCallNotesList(userId);

          if (type === "single") {
            // callModalOpener.current?.click(); 
            familyCallModalOpener.current?.click();

          } else {
            familyCallModalOpener.current?.click();
          }
        }

      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); 
    }

    // empty all the states
    setCallReport({
      doesMemberWantToEnroll: "",
      reason: "",
    });

    setCallAction("");
    setIsSlotAlreadyBooked(false);
    setSlots([]);
    setIsAlreadyEntrolled(false);

    setSelectedSlot("");
    setCnHold(false);
    setCallReportActionCompleted(false);
    setCallStatus('idle');
    setDisplayCallStatus('Calling');
    setAlternateMobileNoError("");
    setIsCallReportEnabled(false);
    setIsMuteDisabled(false);
    setIsHoldDisabled(false);
    setIsDisconnectDisabled(false);
    setIsCallConnected(false);
    setDisconnectedBy("");
    setEnrollLaterForm({
      date: new Date
    });


    // clearing disposition
    setDispositionFormErrors({
      dataRating: "",
      remarks: "",
      reason: ""
    });

    setCallbackForm({
      date: new Date(),
      time: "",
      notes: "",
    });

    setDispositionForm({
      dataRating: "",
      remarks: "",
      reason: ""
    });

    setDispositionSubmissionMssage({
      status: false,
      message: ""
    });

    setShouldShowDisposition(false);
  };

  const popupInitiateCall = async () => {
    try {
      //setInvitedDataId(inviteId);
      let resp = await agent_call_initiate(userDataId);

      setIsCallDisabled(false);
      setIsHoldDisabled(true);
      setIsMuteDisabled(true);
      setIsDisconnectDisabled(true);
    } catch (error) {
      console.error(error);
    }
    //console.log("phonenumber",phonenumber);
  };


  // Hold call 
  const popupHoldCall = async (unholdTo) => {

    webexCall.doHoldResume();

    setIsHoldDisabled(!isHoldDisabled);


    // old twillio code for holding and unmuting call
    // try {
    //   let resp = await agent_call_hold({confNumber: conferenceNumber, type: unholdTo});
    //   if(unholdTo === "user"){ 
    //     setIsHoldDisabled(false);
    //     setIsUnHoldDisabled(true);
    //   }

    //   if(unholdTo === "cn"){ 
    //     setCnHold(true); 
    //   }

    // } catch (error) {
    //   console.error(error);
    // }
    //console.log("phonenumber",phonenumber);
  };

  // reset all call report states
  function resetCallReportStates() {
    //mute & call Status 
    setIsCallReportEnabled(false);
    setIsMuteDisabled(false);
    setIsHoldDisabled(false);
    setIsDisconnectDisabled(false);
    setDisplayCallStatus('Calling');
    setCallStatus('idle');


    setCallReport({
      doesMemberWantToEnroll: "",
      reason: "",
    });

    setSlotBookingFormData({
      date: ""
    });

    setSlots([]);

    setSelectedSlot("");
    setSelectedCareNavigator(null);
    setSlotBookingFormDataErrors({
      date: "",
      selectedSlot: "",
    });

    setCallAction("");

    setCareNavigatorTransferStatus({
      callStatus: "idle",
      callDisplayStatus: "calling",
      cnCallPollInterval: null
    });

    setCallTransferForm({
      careNavigatorId: "",
    });

    setCallTransferFormErrors({
      careNavigatorId: "",
    });
    setIsCallMergedWithCn(false);
    setCallReportActionCompleted(false);
    setShouldShowDisposition(false);
    setCallReportValidation({
      doesMemberWantToEnroll: "",
      reason: "",
    });

    setCallbackForm({
      date: "",
      time: "",
      notes: "",
    });

    setCallbackFormErrors({
      date: "",
      time: "",
      notes: "",
    });
  }

  // unholding the call
  const popupUnHoldCall = async (holdTo) => {

    webexCall.doHoldResume();
    setIsHoldDisabled(true);
    setIsUnHoldDisabled(false);

    // old Twillio code for unholding the call
    // try {
    //   let resp = await agent_call_unhold({confNumber: conferenceNumber, type: holdTo});
    //   if(holdTo === "user"){ 
    //     setIsHoldDisabled(true);
    //     setIsUnHoldDisabled(false);
    //   }

    //   if(holdTo === "cn"){ 
    //     setCnHold(false); 
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
    //console.log("phonenumber",phonenumber);
  };

  // muting call 
  const popupMuteCall = async (mute) => {
    webexCall.mute(webexLocalMediaStream);
    setIsMuteDisabled(!isMuteDisabled);
    setIsUnMuteDisabled(!isUnMuteDisabled);

    return;

    // old Twillio code for muting and unmuting call

    // try {
    //   if (mute === "Y") {
    //     setIsMuteDisabled(false);
    //     setIsUnMuteDisabled(true);
    //   } else {
    //     setIsMuteDisabled(true);
    //     setIsUnMuteDisabled(false);
    //   }
    //   let resp = await agent_call_mute({userId: userDataId, mute, confNumber: conferenceNumber, type: 'agent'});
    // } catch (error) {
    //   console.error(error);
    // }
    //console.log("phonenumber",phonenumber);
  };

  // disconnecting call 
  async function popupDisconnectCall(disconnectedBy = "") {
    setIsCallPollOn(false);
    

    if (disconnectedBy === "agent") {
      setCallStatus('disconnecting');
      setDisplayCallStatus('Disconnecting');
    }

    if (disconnectedBy === "agent" && !showDispositionModal) {
      // closeModal.current.disabled = false; 
      // setShowDispositionModal(true); 
      setDisconnectedBy("agent");
    }

    setDispositionForm({
      dataRating: "",
      remarks: ""
    });

    setRequestStatus(status => ({ ...status, isCallDisconnectLoading: true }));

    try {
      let resp = await agent_call_disconnect(conferenceNumber);
    } catch (error) {
      console.error(error);
    }finally{
      setRequestStatus((status) => ({ ...status, isCallDisconnectLoading: false }));
    }


    setCopyConferenceNumber(null);
    setIsCallDisabled(true);
    setIsHoldDisabled(false);
    setIsMuteDisabled(false);
    setIsDisconnectDisabled(false);
    setIsUnHoldDisabled(false);
    setIsUnMuteDisabled(false);
    setDisplayCallStatus('Calling');
    
  };

  // mock popup disconnect call
  async function popupMockDisconnectCall(disconnectedBy = "") {
    setIsCallPollOn(false);

    if (callingAgent === 'webex') {
      stopRingtone();
      webexCall?.end();
      callAudio.current?.srcObject?.getTracks().forEach((track) => track.stop());
    }

    if (disconnectedBy === "agent") {
      setCallStatus('disconnecting');
      setDisplayCallStatus('Disconnecting');
    }

    if (disconnectedBy === "agent" && !showDispositionModal) {
      // closeModal.current.disabled = false; 
      // setShowDispositionModal(true); 
      setDisconnectedBy("agent");
    }

    try {
      await direct_agent_call_disconnect({ confNumber: conferenceNumber, poolId, universalConfNumber: universalConfNumber });
    } catch (e) { }
    finally{
      setRequestStatus(status => ({ ...status, isCallDisconnectLoading: false }));
    }


    setDispositionForm({
      dataRating: "",
      remarks: ""
    });

    setCallStatus('idle');
    setCopyConferenceNumber(null);
    setIsCallDisabled(true);
    setIsHoldDisabled(false);
    setIsMuteDisabled(false);
    setIsDisconnectDisabled(false);
    setIsUnHoldDisabled(false);
    setIsUnMuteDisabled(false);
    setWebexCall(null); 
  };

  function getFamilyProgramsList() {
    let programArray = [];
    singleUserDataList.map((user) => {
      programArray.push(user.carePrograms);
    })


    return programArray.join(' , ');
  }




  // starting Soft call 
  const handleButtonClick = async (userId = null) => {
    if (callStatus !== 'idle') return;
    setCallStatus('initiating');
    setDisplayCallStatus('Calling');
    setCallInfoMessaage("");
    setIsCallConnected(false);
    setDisconnectedBy("");


    let stream;
    try {
      stream = await navigator?.mediaDevices?.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
    } catch (e) {
      try {
        await soft_number_logger({ userId, exName: e.name, exMessage: e.message, type: "getUserMedia" });
      } catch (e) {
        // handle error 
        console.log(e);
      }


      if (e.name === 'NotAllowedError') {
        alert('Please allow Microphone Permissions');
      } else {
        alert('No microphone Found in your device');
      }
      return;
    }



    // setting timer to zero before staring new call
    setTimer({
      seconds: "00",
      minutes: "00",
      hours: "00",
      isTimerAlreadyStarted: false,
      intervalId: null,
    });

    // enabling the disposition, which will trigger after disconnecting call 
    // setShouldShowDisposition(true);
    try {
      setCallStatus('initiating');
      let response = await fetch_soft_number({ userId: userDataId, poolId });
      if (response.data.code === 200) {
        let confNumber = response.data.data.confNumber;
        let tokenResponse = await fetch_soft_phone_token(confNumber);
        const token = tokenResponse.data.token;
        setConferenceNumber(confNumber);
        setCopyConferenceNumber(confNumber);
        const newDevice = new Device(token, {
          debug: true,
          closeProtection: true,
          audioConstraints: {
            optional: [
              { echoCancellation: true },
              { noiseSuppression: true },
              { autoGainControl: true },
            ],
          },
        });

        setDevice(newDevice);

        newDevice.on('offline', async (error) => {
          setDisplayCallStatus("Calling");
          setCallStatus("idle");
          return;
        });

        newDevice.on("error", async (error) => {

          if (error.code === 31205 || error.code === 31204) {

          }

          try {
            await soft_number_logger({ userId, exName: error.name, exMessage: error.message, exCode: error.code, exDescription: error.description, exCauses: error.causes, exExplaination: error.explanation, exOriginalError: error.originalError, exSolutions: error.solutions, type: "fetch_soft_number" });
          } catch (e) { }
          setCallStatus("idle");
          return;
        });

        newDevice.on("ready", () => {
          let connection = newDevice.connect({ From: confNumber });
          newDevice.on("connect", async () => {


            setCurrConnection(connection);

            let result = await make_soft_phone_call(confNumber);
          });
        });



        // handling call disconnection
        newDevice.on("disconnect", () => {
          newDevice.disconnectAll();
          setIsMuteDisabled(false);
          setIsHoldDisabled(false);
          setIsDisconnectDisabled(false);
          setCallStatus("idle");
        });




      }
    } catch (error) {
      setCallStatus("idle");
      console.error("Error:", error);
      try {
        await soft_number_logger({ userId, exName: error.name, exMessage: error.message, type: "disconnect" });
      } catch (e) { }
    }
  };

  // handle Mocked call
  const handleMockedCall = async () => {
    if(webexCall) setWebexCall(null);
    if (callingAgent === 'webex' && await checkMicrophonesAndPermission() === false) {
      setShowCheckMicrophonePermissionModal(true);
      return;
    };
    // checking if call is already in progress
    if (callStatus !== 'idle') return;

    setIsCallPlaced(true); 
    // resetting call states
    setCallStatus('initiating');
    setDisplayCallStatus('Calling');
    setCallInfoMessaage("");
    setIsCallConnected(false);
    setDisconnectedBy("");
    setCallReportAction("");
    setTimer({
      seconds: "00",
      minutes: "00",
      hours: "00",
      isTimerAlreadyStarted: false,
      intervalId: null,
    });
    setCallReportActionType("");

    setRequestStatus(status => ({...status, isCallPlacingRequestLoading: true})); 
    try {
      let response = await direct_fetch_soft_number({ userId: singleUserData.encUserId, universalConfNumber, poolId: singleUserData.poolId, callFrom: callingAgent });
      if (response.data.code === 200) {
        let confNumber = response.data.data.confNumber;
        setConferenceNumber(confNumber);
        setUniversalConfNumber(response.data.data.universalConfNumber);

        // creating call based on the calling agent
        if (callingAgent === "webex") {
          //  Get media devices 
          let localAudioStream = await window.Calling?.createMicrophoneStream({ audio: true });
          setWebexLocalMediaStream(localAudioStream);
          const destination = response.data.data.extensionNo;


          // Create the call
          let call = webexLine.makeCall({
            type: 'uri',
            address: `${destination}`,
          });

          setWebexCall(call);
          call.dial(localAudioStream);
          playRingtone();

          call.on('remote_media', (track) => {
            stopRingtone(); 
            callAudio.current.srcObject = new MediaStream([track]);
          });

          call.on('connect', (correlationId) => {
            stopRingtone();
            setIsMuteDisabled(true);
            setIsHoldDisabled(true);
            setIsCallReportEnabled(true);
            setDisplayCallStatus('Connected');
            setIsCallConnected(true); 
          });

          call.on('established', (correlationId) => {
            stopRingtone();
            setCallStatus("in-progress");
          });

          call.on('call_error', async (e) => {
            stopRingtone();
            call.end();
            const errorInfo = {
              code: e.code || null,
              message: e.message || 'No message available',
              stack: e.stack || null,
              details: e.details || null,
              name: e.name || 'Unknown Error',
            };

            await soft_number_logger({ errorAt: "Error with call", ...errorInfo });
          });

          call.on('disconnect', () => {
            setCallStatus("idle");
            setIsMuteDisabled(false);
            setIsHoldDisabled(false);
            stopRingtone();
          });

          call.on('held', () => {
            setIsHoldDisabled(false);
          });
        } else {
          setCallStatus("in-progress");
          setIsCallReportEnabled(true);
        }
      }
    } catch (e) {
      const errorInfo = {
        code: e.code || null,
        message: e.message || 'No message available',
        stack: e.stack || null,
        details: e.details || null,
        name: e.name || 'Unknown Error',
      };
      await soft_number_logger({ errorAt: "Error with call", ...errorInfo });
      setCallStatus("idle");
      stopRingtone();
    }finally{ 
      setRequestStatus(status => ({...status, isCallPlacingRequestLoading: false})); 
    }
  }

    const disconnectCall = () => {
    if (device) {
      device.disconnectAll();
      setCallStatus("idle");
      console.log("Call disconnected");
      //page reload
      window.location.reload();
    } else {
      console.error("Twilio device is not initialized");
    }
  };

  const [formData, setFormData] = useState({ adminId: "" });

  const handleChange = (e) => {
    const { value } = e.target;
    setCallTransferForm({
      careNavigatorId: value,
    });

    setCallTransferFormErrors({
      ...callTransferFormErrors,
      [e.target.name]: "",
    });
  };

  // for yes or no buttons in call report 
  const yesButton = useRef();
  const noButon = useRef();

  // refs to webex
  const callAudio = useRef();
  const ringtoneAudio = useRef(new Audio('/caller-tune.mp3'));
  const transferAudio = useRef(new Audio('/caller-tune.mp3'));

  // call action 
  const [callAction, setCallAction] = useState("");

  const [familyCallAction, setFamilyCallAction] = useState({});
  const [conditionSearchQuery, setConditionSearchQuery] = useState("");


  // webex states 
  const [isWebexDeviceReady, setIsWebexDeviceReady] = useState(false);
  const [webexCall, setWebexCall] = useState(null);
  const [webexTransferCall, setWebexTransfercall] = useState(null);
  const [webexCalling, setWebexCalling] = useState(null);
  const [webexLine, setWebexLine] = useState(null);
  const [webexLocalMediaStream, setWebexLocalMediaStream] = useState(null);
  const [webexAccesstoken] = useState(localStorage.getItem('call_client_id'));
  const [showCheckMicrophonePermissionModal, setShowCheckMicrophonePermissionModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log('isExist',isTransfer);
    let resp = await agent_transfer_call(userDataId, formData);

    console.log("Form submitted successfully");
    // Perform any further actions like sending data to a server
  };

  const popupEnrollmentPorcess = async (encUserId) => {
    try {
      console.log("encMemberId", encUserId);
      let userId = encUserId;
      let resp = await agent_enroll_user(userId);
      console.log("enroll", resp.data);
      if (resp.data.code === 200) {
        setButtonDisplay(resp.data.data[0].buttonDisplay);
        setCareNavigatorId(resp.data.data[0].careNavigatorId);
        setCareNavigatorName(resp.data.data[0].fullName);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };

  const formatDateTime = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const popupCareNavigator = async () => {
    setSlotsSearchSubmissionMessage({
      status: false,
      message: "",
    });

    const { date, cnId } = slotBookingFormData;


    let validationError = false;

    let searchSlotsErrors = { ...slotBookingFormDataErrors };

    // validate date and time from setBooking form data
    if (!date) {
      validationError = true;
      searchSlotsErrors.date = "Date is required";
    }



    if (validationError) {
      setSlotBookingFormDataErrors(searchSlotsErrors);
      return;
    }

    let response;

    // call the api for the slots

    setRequestStatus(status => ({
      ...status,
      isSearchLoading: true
    }));

    try {
      response = await fetch_available_slot({ cnId, queryDate: formatDateTime(date) });
      setAreSlotsFetched(true);
      setSlots(response?.data?.data);
    } catch (e) {
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "fetch_available_slot" });
      } catch (e) {
        console.log(e);
      }
      setSlotsSearchSubmissionMessage({
        status: false,
        message: "Something went wrong",
      });
    } finally {
      setRequestStatus(status => ({
        ...status,
        isSearchLoading: false
      }));
    }

  }

  async function familyPopupCareNavigator(carenavigatorId, selectedDateTime) {

    setFamilySelectedSlot((prev) => {
      return {
        ...prev,
        [singleUserData.memberId]: ""
      }
    });

    setFamilySlots((prev) => { 
      return { ...prev, [singleUserData.memberId]: []}
    }); 

    setFamilySlotSearchSubmissionMessage({
      ...familySlotSearchSubmissionMessage,
      [singleUserData.memberId]: {
        status: false,
        message: ""
      }
    })

    const { date, cnId } = familySlotBookingFormData[singleUserData.memberId];


    let validationError = false;

    let searchSlotsErrors = { ...familySlotBookingFormDataErrors[singleUserData.memberId] };

    // validate date and time from setBooking form data
    if (!date) {
      validationError = true;
      searchSlotsErrors.date = "Date is required";
    }



    if (validationError) {
      setFamilySlotBookingFormDataErrors({
        ...familySlotBookingFormDataErrors,
        [singleUserData.memberId]: searchSlotsErrors
      });
      return;
    }

    let response;

    // call the api for the slots

    setRequestStatus(status => ({
      ...status,
      isSearchLoading: true
    }));

    let selectedCnId = carenavigatorId ? carenavigatorId : cnId; 
    let selectedDate = selectedDateTime ? selectedDateTime : date;

    try {
      response = await fetch_available_slot({ cnId: selectedCnId, queryDate: formatDateTime(selectedDate) });
      setAreFamilySlotsFetched({
        ...areFamilySlotsFetched,
        [singleUserData.memberId]: true
      });

      setFamilySlots((prev) => { 
        return { ...prev, [singleUserData.memberId]: response?.data?.data}
      })
      // setFamilySlots({
      //   ...familySlots,
      //   [singleUserData.memberId]: response?.data?.data
      // })
    } catch (e) {
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "fetch_available_slot" });
      } catch (e) {
        console.log(e);
      }

      setFamilySlotSearchSubmissionMessage({
        ...familySlotSearchSubmissionMessage,
        [singleUserData.memberId]: {
          status: false,
          message: "Something went wrong"
        }
      })
    }finally{ 
      setRequestStatus(status => ({
        ...status,
        isSearchLoading: false
      }));
    }



    setFamilySlotBookingFormDataErrors({
      ...familySlotBookingFormDataErrors,
      [singleUserData.memberId]: {
        dae: "",
        time: ""
      }
    })




    setTimeout(() => {
      setSlotsSearchSubmissionMessage({
        status: false,
        message: "",
      });

      setFamilySlotSearchSubmissionMessage({
        ...familySlotSearchSubmissionMessage,
        [singleUserData.memberId]: {
          status: false,
          message: ""
        }
      })
    }, 3000);
  };

  const handleCallDateChange = (e) => {
    setCallBackDate(e.target.value);
  };

  const handleCallTimeChange = (e) => {
    setCallBackTime(e.target.value);
  };


  // on rejecting the enrollment
  const rejectEnrollment = async () => {
    const { doesMemberWantToEnroll, reason } = callReport;

    if (doesMemberWantToEnroll === "") {
      setCallReportValidation({
        ...callReportValidation,
        doesMemberWantToEnroll: "This Field is required",
      });
      return;
    }

    if (doesMemberWantToEnroll === "no") {
      if (reason === "") {
        setCallReportValidation({
          ...callReportValidation,
          reason: "Reason is Required",
        });
        return;
      }


      try {

        setRequestStatus(status => ({
          ...status,
          isRejectEnrollmentLoading: true
        }));

        let response = await reject_enrollment({
          confNumber: conferenceNumber,
          reason,
          poolId,
          universalConfNumber
        });



        let status = response?.data.code;
        let { msg } = response?.data?.data;

        if (status === 200) {
          setCallReportSubmissinMessage({
            status: true,
            message: msg,
          });

          setCallReport({
            ...callReport,
            reason: ""
          });

          setCallReportAction("not_enroll");

          setCallReportActionType("nutral");



          setCallReportActionCompleted(true);

          setCallAction("");

        } else {
          setCallReportSubmissinMessage({
            status: false,
            message: msg,
          });
        }
      } catch (e) {
        setCallReportSubmissinMessage({
          status: false,
          message: "Something went wrong",
        });

      }finally{ 
        setRequestStatus(status => ({
          ...status,
          isRejectEnrollmentLoading: false
        }));
      }

      // resetting the errors
      setCallReportValidation({
        doesMemberWantToEnroll: "",
        reason: "",
      });

      setCallReport({
        ...callReport,
        reason: ""
      })

      setTimeout(() => {
        setCallReportSubmissinMessage({
          status: false,
          message: "",
        });
      }, 3000);
    }
  };

  // reject family member enrollment
  const rejectFamilyEnrollment = async (e, userId, poolId) => {

    const { doesMemberWantToEnroll, reason } = familyCallReport[singleUserData.memberId];
    if (reason === "") {
      setFamilyCallReportValidation({
        ...familyCallReportValidation,
        [singleUserData.memberId]: {
          ...familyCallReportValidation[singleUserData.memberId],
          reason: "Reason is required"
        }
      });
      return;
    }

    try {

      setRequestStatus(status => ({
        ...status,
        isRejectEnrollmentLoading: true
      }));

      let response = await family_reject_enrollment({
        confNumber: conferenceNumber,
        reason,
        poolId: singleUserData.poolId,
        userId: singleUserData.encUserId,
        universalConfNumber
      });



      let status = response?.data.code;
      let { msg } = response?.data?.data;

      if (status === 200) {
        setFamilyCallReportSubmissionMessage({
          ...familyCallReportSubmissionMessage,
          [singleUserData.memberId]: {
            status: true,
            message: msg
          }
        });


        setFamilyCallReport({
          ...familyCallReport,
          [singleUserData.memberId]: {
            ...familyCallReport[singleUserData.memberId],
            reason: ""
          }
        });

        setFamilyCallReportActionType({
          ...familyCallReportActionType,
          [singleUserData.memberId]: "correct"
        })


        setFamilyCallReportAction({
          ...familyCallReportAction,
          [singleUserData.memberId]: "not_enroll"
        });

        setFamilyCallReportActionCompleted({
          ...familyCallReportActionCompleted,
          [singleUserData.memberId]: true,
        });

        setCallAction("");

      } else {
        setFamilyCallReportSubmissionMessage({
          ...familyCallReportSubmissionMessage,
          [singleUserData.memberId]: {
            status: false,
            message: msg
          }
        });
      }
    } catch (e) {
      setFamilyCallReportSubmissionMessage({
        ...familyCallReportSubmissionMessage,
        [singleUserData.memberId]: {
          status: false,
          message: "Something went wrong"
        }
      });

    }finally{
      setRequestStatus(status => ({
        ...status,
        isRejectEnrollmentLoading: false
      }));       
    }


    // resetting the errors
    // setCallReportValidation({
    //   doesMemberWantToEnroll: "",
    //   reason: "",
    // });

    setFamilyCallReport({
      ...familyCallReport,
      [singleUserData.memberId]: {
        doesMemberWantToEnroll: "",
        reason: ""
      }
    })

    setTimeout(() => {
      setFamilyCallReportSubmissionMessage({
        ...familyCallReportSubmissionMessage,
        [singleUserData.memberId]: {
          status: false,
          message: ""
        }
      });
    }, 3000);


  }

  // storing the callback 
  const popupCallBackPlan = async () => {
    // validate the form

    let { date, time, notes } = callbackForm;
    let isavlidationError = false;

    let updatedErrors = { ...callbackFormErrors };

    if (!date) {
      isavlidationError = true;
      updatedErrors.date = "Date is Required";
    }


    if (isavlidationError) {
      setCallbackFormErrors(updatedErrors);
      return;
    }

    setRequestStatus((status) => ({ ...status, isCallbackLoading: true }));

    let resp;
    try {
      resp = await agent_call_back_plan({
        userId: userDataId,
        date: formatDateTime(date),
        notes: notes,
        poolId
      });



      if (resp.data.code === 200) {
        setCallBackTime("");
        setCallBackDate("");
        setCallbackSubmissionMessage({
          status: true,
          message: resp.data?.data.msg,
        });

        setCallReportActionCompleted(true);
      } else {
        setCallbackSubmissionMessage({
          status: false,
          message: resp.data?.data.msg,
        });
      }

    } catch (e) {
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "agent_call_back_plan" });

      } catch (e) {
        console.log(e);
      }
      setCallbackSubmissionMessage({
        status: false,
        message: "Something went wrong",
      });

    }finally{ 
      setRequestStatus((status) => ({ ...status, isCallbackLoading: false }));
    }

    setCallbackFormErrors({
      notes: "",
      date: "",
      time: "",
    });

    fetchCallbackUserDataList();

    setTimeout(() => {
      setCallbackSubmissionMessage({
        status: false,
        message: "",
      });
    }, 4000);
  };

  // store a family call back 
  const familyPopupCallBackPlan = async () => {
    // validate the form

    let { date, time, notes } = familyCallbackForm[singleUserData.memberId];

    let isavlidationError = false;

    let updatedErrors = { ...familyCallbackFormErrors[singleUserData.memberId] };

    if (!date) {
      isavlidationError = true;
      updatedErrors.date = "Date is Required";
    }


    if (isavlidationError) {
      setFamilyCallbackFormErrors({
        ...familyCallbackFormErrors,
        [singleUserData.memberId]: updatedErrors
      });
      return;
    }

    setRequestStatus((status) => ({ ...status, isCallbackLoading: true }));

    let resp;
    try {
      
      resp = await agent_call_back_plan({
        userId: singleUserData.encUserId,
        date: formatDateTime(date),
        notes: notes,
        poolId: singleUserData.poolId, 
        confNumber: conferenceNumber
      });



      if (resp.data.code === 200) {

        setFamilyCallbackSubmissionMessage({
          ...familyCallBackSubmissionMessage,
          [singleUserData.memberId]: {
            status: true,
            message: resp.data?.data.msg,
          }
        });

        setTimeout(() => {
          setFamilyCallbackSubmissionMessage({
            ...familyCallBackSubmissionMessage,
            [singleUserData.memberId]: {
              status: false,
              message: "",
            }
          });
        }, 3000);

        setFamilyCallReportActionCompleted({
          ...familyCallReportActionCompleted,
          [singleUserData.memberId]: true
        });

        setFamilyCallReportAction({
          ...familyCallReportAction,
          [singleUserData.memberId]: "call_back"
        })


      } else {

        setFamilyCallbackSubmissionMessage({
          ...familyCallBackSubmissionMessage,
          [singleUserData.memberId]: {
            status: false,
            message: resp.data?.data.msg,
          }
        });
      }

    } catch (e) {
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "agent_call_back_plan" });

      } catch (e) {
        console.log(e);
      }
      setFamilyCallbackSubmissionMessage({
        ...familyCallBackSubmissionMessage,
        [singleUserData.memberId]: {
          status: false,
          message: "something went wrong",
        }
      });

    }finally{
      setRequestStatus(status => ({ ...status, isCallbackLoading: false }));
    }

    setCallbackFormErrors({
      notes: "",
      date: "",
      time: "",
    });

    fetchCallbackUserDataList();

    setTimeout(() => {
      setCallbackSubmissionMessage({
        status: false,
        message: "",
      });
    }, 4000);
  }

  const callBackOnChange = (e) => {
    setCallbackForm({
      ...callbackForm,
      [e.target.name]: e.target.value,
    });

    setCallbackFormErrors({
      ...callbackFormErrors,
      [e.target.name]: "",
    });
  };


  const familycallbackOnChange = (e) => {
    setFamilyCallbackForm({
      ...familyCallbackForm,
      [singleUserData.memberId]: {
        ...familyCallbackForm[singleUserData.memberId],
        [e.target.name]: e.target.value
      }
    });


    setFamilyCallbackFormErrors({
      ...familyCallbackFormErrors,
      [singleUserData.memberId]: {
        ...familyCallbackFormErrors[singleUserData.memberId],
        [e.target.name]: ""
      }
    })
  };

  const popupCreatePool = async () => {
    if(requestStatus.isRefreshPoolLoading) return;
    setRequestStatus(status => ({
      ...status,
      isRefreshPoolLoading: true
    }));
    try {
      let resp = await agent_create_pool();

    } catch (error) {
      console.error("Error:", error);
    }finally{ 
      setTimeout(() => {
        setRequestStatus(status => ({
          ...status,
          isRefreshPoolLoading: false
        }));
      }, 50);
    }

    fetchUserDataList();
    setReflect(!reflect);

  };

  // Fetching user's stats
  const agentStats = async () => {
    try {
      let resp = await agent_stats();

      setCompletedCall(resp.data?.data?.totalCompletedCall || 0);
      setTotalMemberReachout(resp.data?.data?.reachOutCount);
      setCallsCounducted(resp.data?.data?.todayContactedCount || 0);
      setEnrolledCall(resp.data?.data.totalEnrollCount || 0);
      setTodaysTarget(resp.data?.data.enrollmentTargetBatch || 0);
      setCompleteRateCall(resp.data?.data.totalCompletedRate || 0);
      setAvgCallTime(resp.data?.data.avgCallTime || 0);
      setBatchNo(resp.data?.data.batchName || []);
      setScriptData(resp.data?.data?.scriptArr);
      setArabicScriptData(resp.data?.data?.scriptArbArr);
      setIsEligibleForPool(resp?.data?.data?.eligibleForPool);
      setAgentId(resp?.data?.data?.agentId);

    } catch (error) {
      console.error("Error:", error);
    }
  };

  // handling callreoprt form on change
  function onCallReportFormChange(e, type = "single") {
    if (type === "single") {
      setCallReport({
        ...callReport,
        [e.target.name]: e.target.value,
      });

      setCallReportValidation({
        ...callReportValidation,
        [e.target.name]: ""
      });
    } else {
      setFamilyCallReport({
        ...familyCallReport,
        [singleUserData.memberId]: {
          ...familyCallReport[singleUserData.memberId],
          [e.target.name]: e.target.value
        }
      })


      setFamilyCallReportValidation({
        ...familyCallReportValidation,
        [singleUserData.memberId]: {
          ...familyCallReportValidation[singleUserData.memberId],
          [e.target.name]: ""
        }
      })
    }
  }

  // select slot for booking
  function selectSlot() {
    alert("slot is selected");
  }

  // function to generate timer on call start
  function toggleTimer(stop = false) {
    let { seconds, minutes, hours, isTimerAlreadyStarted, intervalId } = timer;

    seconds = parseInt(seconds);
    minutes = parseInt(minutes);
    hours = parseInt(hours);

    if (!stop && !isTimerAlreadyStarted) {

      intervalId = setInterval(() => {
        seconds++;

        if (seconds === 60) {
          seconds = 0;
          minutes++;
        }

        if (minutes === 60) {
          minutes = 0;
          hours++;
        }

        const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        const formattedHours = hours < 10 ? "0" + hours : hours;

        setTimer({
          seconds: formattedSeconds,
          minutes: formattedMinutes,
          hours: formattedHours,
          isTimerAlreadyStarted: true,
          intervalId: intervalId,
        });
      }, 1000);

    } else {
      clearInterval(intervalId);
      setTimer({
        ...timer,
        isTimerAlreadyStarted: false,
        intervalId: null,
      });
    }
  }


  // transfer call to care navigator
  async function transferCallToCareNavigator() {

    setCareNavigatorTransferStatus({
      ...careNavigatorTransferStatus,
      callStatus: "idle",
      callDisplayStatus: "Dialing"
    });

    let transferCallInterval;
    const { careNavigatorId: id } = callTransferForm;
    setCareNavigatorId(id);
    if (id === "") {
      setCallTransferFormErrors({
        careNavigatorId: "Carenvigator Id is required",
      });

      return;
    }

    let response;

    setRequestStatus(status => ({
      ...status,
      isTransferCallLoading: true
    }));

    try {
      response = await agent_transfer_call({
        confNumber: conferenceNumber,
        carenavigatorId: id,
        userId: userDataId
      });

      setCareNavigatorTransferStatus({
        ...careNavigatorTransferStatus,
        callStatus: "calling",
        callDisplayStatus: "Dialing"
      });
    } catch (e) {
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "agent_transfer_call" });
      } catch (e) {
        console.log(e);
      }
      setCallTransferSubmissionMessage({
        status: "error",
        message: "Something went wrong",
      });
    }finally { 
      setRequestStatus(status => ({
        ...status,
        isTransferCallLoading: false
      }));
    }

    if (response.data.code === 200) {
      // submission message
      let submissionMsssage = response?.data?.data?.msg;

      setCallTransferSubmissionMessage({
        status: "success",
        message: submissionMsssage,
      });

      // poll for the status of transferred call
      transferCallInterval = setInterval(async () => {
        try {
          let response = await cn_call_user_status({ confNumber: conferenceNumber });
          const status = response.data?.callStatusInProgess;

          if (!careNavigatorTransferStatus.cnCallPollInterval) setCareNavigatorTransferStatus({ ...careNavigatorTransferStatus, cnCallPollInterval: transferCallInterval });

          if (status === "in-progress") {
            setCareNavigatorTransferStatus({
              ...careNavigatorTransferStatus,
              callStatus: "in-progress",
              callDisplayStatus: "Connected"

            });
          }

          if (status === "ringing") {
            setCareNavigatorTransferStatus();
            setCareNavigatorTransferStatus({
              ...careNavigatorTransferStatus,
              callStatus: "ringing",
              callDisplayStatus: "Ringing"
            });
          }

          if (
            status === "completed" ||
            status === "canceled" ||
            status === "failed" ||
            status === "busy" ||
            status === "no-answer" ||
            status === "none"
          ) {
            setCareNavigatorTransferStatus({
              ...careNavigatorTransferStatus,
              callStatus: "idle",
              callDisplayStatus: "idle"
            });
            setIsCallMergedWithCn(false);
            clearInterval(transferCallInterval);
          }

        } catch (error) {
          // show Error 

        }
      }, 2000);

    } else {
      try {
        await soft_number_logger({ userId: userDataId, exName: response?.data?.data?.msg, exMessage: response?.data?.data?.msg, type: "cn_call_user_status" });
      } catch (e) {
        console.log(e);
      }
      setCallTransferSubmissionMessage({
        status: "error",
        message: response?.data?.data?.msg || "Something went wrong",
      });
    }


    setTimeout(() => {
      setCallTransferSubmissionMessage({
        status: "success",
        message: "",
      });
    }, 3000);
  }

  // Transfer call updated function 
  async function transferCallToCN() {
    setWebexTransferCall(null); 
    if (!callTransferForm.careNavigatorId) {
      setCallTransferFormErrors({
        ...callTransferFormErrors,
        careNavigatorId: "Please select Carenavigator",
      });
      return;
    }

    setCareNavigatorTransferStatus({
      ...careNavigatorTransferStatus,
      callStatus: "initiating",
      callDisplayStatus: "Connecting"
    })
    // hold the existing call if not already 
    if (!webexCall.isHeld()) {
      webexCall.doHoldResume();
    }

    // find carenavigator object from array of object with carenavigatorId
    let extensionNo = onlineAgentData.find(agent => agent.id === callTransferForm.careNavigatorId);
    extensionNo = extensionNo.webexExtension;

    // make a second call to cn 
    let callTransferObj = webexLine.makeCall({
      type: 'uri',
      address: `${extensionNo}`,
    });

    setWebexTransfercall(callTransferObj);

    // getting remote audio 
    callTransferObj.on('remote_media', (track) => {
      stopRingtone(); 
      callAudio.current.srcObject = new MediaStream([track]); 
    });

    // listening for an error object 
    callTransferObj.on("call_error", async (e) => {
      console.log('webex call error ', e);
      // log error  
      let errorInfo = {
        code: e.code || null,
        message: e.message || 'No message available',
        stack: e.stack || null,
        details: e.details || null,
        name: e.name || 'Unknown Error',
      };


      await soft_number_logger({ ...errorInfo });

    })

    callTransferObj.on('connect', () => {
      stopRingtone(true);
      setCareNavigatorTransferStatus({
        ...careNavigatorTransferStatus,
        callStatus: "in-progress",
        callDisplayStatus: "Connected"
      })
    })

    // handling call connection 
    callTransferObj.on('established', (correlationId) => {
      stopRingtone(true);
    });

    // handling call disconnection 
    callTransferObj.on('disconnect', (correlationId) => {
      stopRingtone(true);
      setCareNavigatorTransferStatus({
        ...careNavigatorTransferStatus,
        callStatus: "idle",
        callDisplayStatus: "Connecting"
      });

      webexCall.doHoldResume();
      setIsHoldDisabled(true);
    });

    callTransferObj.dial(webexLocalMediaStream);
    playRingtone(true);
  }

  // Merge calls
  async function mergeCalls(call) {
    setRequestStatus(status => ({
      ...status,
      isMergeCallLoading: true
    }));

    let resp = await agent_call_unhold({ confNumber: conferenceNumber, poolId, carenavigatorId: callTransferForm.careNavigatorId });
    webexCall.completeTransfer('CONSULT', webexTransferCall.getCallId(), undefined);
    setRequestStatus(status => ({
      ...status,
      isMergeCallLoading: true
    }));
    setFamilyCallReportActionType({
      ...familyCallReportActionType,
      [singleUserData.memberId]: "correct"
    })

    setFamilyCallReportActionCompleted({
      ...familyCallReportActionCompleted,
      [singleUserData.memberId]: true
    });
  }


  //  book slot
  async function bookSlot() {
    // validate the data
    const { date, time } = slotBookingFormData;
    let validationError = false;
    let slotBookingErrors = {
      ...slotBookingFormDataErrors,
    };

    if (!date || date === "") {
      slotBookingErrors.date = "Date is required";
      validationError = true;
    }


    if (!selectedSlot || selectSlot === "") {
      slotBookingErrors.selectedSlot = "Please Select Slot";
      validationError = true;
    }

    if (validationError) {
      setSlotBookingFormDataErrors(slotBookingErrors);
      return;
    }

    setRequestStatus(status => ({
      ...status,
      isBookingLoading: true
    })); 
    // book a slot
    let response;
    try {
      response = await save_carenavigator_slot({
        queryDate: formatDateTime(date),
        userId: userDataId,
        careNavigatorId: selectedCareNavigator,
        confNumber: conferenceNumber,
        poolId,
        selectedSlot,
        universalConfNumber
      });



      const statusCode = response?.data?.code;

      if (statusCode === 200) {
        setSlotBookingSubmissionMessage({
          status: true,
          message: response.data?.data?.message,
        });

        setCallReportActionType("correct");

        setSlots([]);
        setCallReportActionCompleted(true);
        setAreSlotsFetched(false);

        setIsSlotAlreadyBooked(true);
      } else {
        setSlotBookingSubmissionMessage({
          status: false,
          message: response.data?.data?.message,
        });
      }

    } catch (e) {
      // handle error
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "save_carenavigator_slot" });
      } catch (e) {
        console.log(e);
      }

      setSlotBookingSubmissionMessage({
        status: false,
        message: "Something went wrong",
      });

    }finally{ 
      setRequestStatus(status => ({
        ...status,
        isBookingLoading: false
      }));
    }

    setSlotBookingFormDataErrors({
      date: "",
      time: "",
      selectedSlot: "",
    });

    setTimeout(() => {
      setSlotBookingSubmissionMessage({
        status: true,
        message: "",
      });
    }, 3000);
  }

  async function familyBookSlot() {
    // validate the data
    const { date } = familySlotBookingFormData[singleUserData.memberId];

    let validationError = false;
    let slotBookingErrors = {
      ...familySlotBookingFormDataErrors[singleUserData.memberId],
    };

    if (!date || date === "") {
      slotBookingErrors.date = "Date is required";
      validationError = true;
    }


    if (!familySelectedSlot[singleUserData.memberId] || familySelectedSlot[singleUserData.memberId] === "") {
      slotBookingErrors.selectedSlot = "Please Select Slot";
      validationError = true;
    }

    if (validationError) {
      setFamilySlotBookingFormDataErrors({
        ...familySlotBookingFormDataErrors[singleUserData.memberId],
        [singleUserData.memberId]: slotBookingErrors
      })
      return;
    }

    setRequestStatus(status => ({
      ...status,
      isBookingLoading: true
    }));

    // book a slot
    let response;
    try {
      response = await save_carenavigator_slot({
        queryDate: formatDateTime(date),
        userId: singleUserData.encUserId,
        careNavigatorId: familySelectedCareNavigator[singleUserData.memberId],
        confNumber: conferenceNumber,
        poolId: singleUserData.poolId,
        selectedSlot: familySelectedSlot[singleUserData.memberId],
        universalConfNumber
      });



      const statusCode = response?.data?.code;

      if (statusCode === 200) {
        setFamilySlotBookingSubmissionmessage({
          ...familySlotBookingSubmissionMessage,
          [singleUserData.memberId]: {
            status: true,
            message: response.data?.data?.message,
          }
        });

        setFamilyCallReportActionType({
          ...familyCallReportActionType,
          [singleUserData.memberId]: "correct"
        })

        setFamilySlots({
          ...familySlots,
          [singleUserData.memberId]: []
        })

        setFamilyCallReportActionCompleted({
          ...familyCallReportActionCompleted,
          [singleUserData.memberId]: true
        });

        setAreFamilySlotsFetched({
          ...areFamilySlotsFetched,
          [singleUserData.memberId]: false
        });


        // setIsSlotAlreadyBooked(true); 
      } else {
        setFamilySlotBookingSubmissionmessage({
          ...familySlotBookingSubmissionMessage,
          [singleUserData.memberId]: {
            status: false,
            message: response.data?.data?.message,
          }
        });
      }
    } catch (e) {
      // handle error
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "save_carenavigator_slot" });
      } catch (e) {
        console.log(e);
      }

      setFamilySlotBookingSubmissionmessage({
        ...familySlotBookingSubmissionMessage,
        [singleUserData.memberId]: {
          status: false,
          message: "Something went wrong",
        }
      });

    }finally{ 
      setRequestStatus(status => ({
        ...status,
        isBookingLoading: false
      }));
    }




    setSlotBookingFormDataErrors({
      date: "",
      time: "",
      selectedSlot: "",
    });

    setTimeout(() => {
      setSlotBookingSubmissionMessage({
        status: true,
        message: "",
      });
    }, 3000);
  }






  //  save disposition 
  async function saveDisposition() {

    setTimer({
      seconds: "00",
      minutes: "00",
      hours: "00",
      isTimerAlreadyStarted: false,
      intervalId: null,
    });

    // validate the form
    const dispositionValidationErrors = { ...dispositionFormErrors };
    let { dataRating, remarks, reason } = dispositionForm;

    let isValidationError = false;

    if (dataRating) {
      if (!reason) {
        isValidationError = true;
        dispositionValidationErrors.reason = "This field is required";
      }
    }



    if (isValidationError) {
      setDispositionFormErrors(dispositionValidationErrors);
      return;
    }


    // call disposition api

    let response;
    // $userId,$confNumber, $disposition, $dataRating, $remarks)
    setRequestStatus(status => ({
      ...status,
      isDispositionSaveLoading: true
    })); 

    try {
      response = await call_disposition({ userId: userDataId, confNumber: conferenceNumber, dataRating, reason, remarks, poolId });
    } catch (e) {
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "call_disposition" });
      } catch (e) {
        console.log(e);
      }
      setDispositionSubmissionMssage({
        status: false,
        message: "Something went wrong",
      })

      return;
    }finally{ 
      setRequestStatus(status => ({
        ...status,
        isDispositionSaveLoading: false
      }));
    }



    if (response.data.code === 200) {
      setDispositionSubmissionMssage({
        status: true,
        message: response.data?.data?.msg
      });


    } else {
      setDispositionSubmissionMssage({
        status: false,
        message: response.data?.data?.msg
      })
    }


    setDispositionFormErrors({
      dataRating: "",
      remarks: ""
    });


    setShowDispositionModal(false);

    // clear disposition state.
    setDispositionForm({
      dataRating: "",
      remarks: ""
    });

    setDispositionFormErrors({
      dataRating: "",
      remarks: ""
    });

    setDispositionSubmissionMssage({
      status: false,
      message: ""
    });

    setReflect(!reflect);


    setShouldShowDisposition(false);
  }

  // save mock disposition 
  async function saveMockDisposition() {

    setTimer({
      seconds: "00",
      minutes: "00",
      hours: "00",
      isTimerAlreadyStarted: false,
      intervalId: null,
    });

    // validate the form
    const mockDispositionValidationErrors = { ...mockDispositionFormErrors };
    let { dataRating, remarks, reason, callStatus } = mockDispositionForm;

    let isValidationError = false;


    if (dataRating) {
      if (!reason) {
        isValidationError = true;
        mockDispositionValidationErrors.reason = "This field is required";
      }
    }



    if (isValidationError) {
      setMockDispositionFormErrors(mockDispositionValidationErrors);
      return;
    }
    // call disposition api

    let response;
    // $userId,$confNumber, $disposition, $dataRating, $remarks)
    setRequestStatus({
      ...requestStatus,
      isDispositionSaveLoading: true
    })

    try {
      response = await direct_call_disposition({ userId: userDataId, confNumber: conferenceNumber, poolId, callStatus, universalConfNumber: universalConfNumber });
      setCallReportActionType("");
    } catch (e) {
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "direct_call_disposition" });
      } catch (e) {
        console.log(e);
      }
      setDispositionSubmissionMssage({
        status: false,
        message: "Something went wrong"
      })

      return;
    }finally{ 
      setRequestStatus(status => ({
        ...status,
        isDispositionSaveLoading: false
      }))
    }



    if (response.data.code === 200) {
      setDispositionSubmissionMssage({
        status: true,
        message: response.data?.data?.msg
      });


    } else {
      setDispositionSubmissionMssage({
        status: false,
        message: response.data?.data?.msg
      })
    }

    

    setDispositionFormErrors({
      dataRating: "",
      remarks: ""
    });

    setMockDispositionFormErrors({
      callStatus: "",
      dataRating: "",
      remarks: "",
      reason: ""
    });


    setShowDispositionModal(false);
    setShowMockDispositionModal(false);

    // clear disposition state.
    setDispositionForm({
      dataRating: "",
      remarks: ""
    });

    setMockDispositionForm({
      callStatus: "",
      dataRating: "",
      remarks: "",
      reason: ""
    });

    setDispositionFormErrors({
      dataRating: "",
      remarks: ""
    });

    setDispositionSubmissionMssage({
      status: false,
      message: ""
    });

    setReflect(!reflect);

    setCallReportAction("");
    setShouldShowDisposition(false);
  }

  // save family disposition 
  async function familySaveMockDisposition() {


    // validate the form
    const mockDispositionValidationErrors = { ...mockDispositionFormErrors };
    let { dataRating, remarks, reason, callStatus } = mockDispositionForm;

    let isValidationError = false;


    if (dataRating) {
      if (!reason) {
        isValidationError = true;
        mockDispositionValidationErrors.reason = "This field is required";
      }
    }



    if (isValidationError) {
      setMockDispositionFormErrors(mockDispositionValidationErrors);
      return;
    }
    // call disposition api

    let response;
    // $userId,$confNumber, $disposition, $dataRating, $remarks)
    setRequestStatus(status => ({
      ...status,
      isDispositionSaveLoading: true
    }));

    try {
      response = await family_direct_call_disposition({ userId: singleUserData.encUserId, confNumber: conferenceNumber, dataRating, reason, remarks, poolId: singleUserData.poolId, universalConfNumber: universalConfNumber, prefferedLanguage: languageButton, communicationMedium: connectType.length > 0 ? connectType[0] : "" });

      if (response.data.code === 200) {
        let enrollmentStatus = response?.data?.data?.enrollmentStatus;
        let actionData = response?.data?.data?.actionData;

        setSingleUserDataList((previous) => {
          return previous.map((item) => {
            if (item.memberId === singleUserData.memberId) {
              item['enrollmentStatus'] = enrollmentStatus;
              item['actionData'] = actionData;

              return item;
            }

            return item;
          })
        })


        setSingleUserData({
          ...singleUserData,
          enrollmentStatus: enrollmentStatus,
          actionData: actionData
        })
      }

      setFamilyCallReportActionType({
        ...familyCallReportActionType,
        [singleUserData.memberId]: ""
      })
    } catch (e) {
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "direct_call_disposition" });
      } catch (e) {
        console.log(e);
      }
      setDispositionSubmissionMssage({
        status: false,
        message: "Something went wrong",
      })

      return;
    }finally{ 
      setRequestStatus(status => ({
        ...status,
        isDispositionSaveLoading: false
      })); 
    }



    if (response.data.code === 200) {
      setDispositionSubmissionMssage({
        status: true,
        message: response.data?.data?.msg
      });


    } else {
      setDispositionSubmissionMssage({
        status: false,
        message: response.data?.data?.msg
      })
    }


    setDispositionFormErrors({
      dataRating: "",
      remarks: ""
    });

    setMockDispositionFormErrors({
      callStatus: "",
      dataRating: "",
      remarks: "",
      reason: ""
    });


    setShowDispositionModal(false);
    setShowMockDispositionModal(false);

    // clear disposition state.
    setDispositionForm({
      dataRating: "",
      remarks: ""
    });

    setMockDispositionForm({
      callStatus: "",
      dataRating: "",
      remarks: "",
      reason: ""
    });

    setDispositionFormErrors({
      dataRating: "",
      remarks: ""
    });

    setDispositionSubmissionMssage({
      status: false,
      message: ""
    });

    setReflect(!reflect);

    setCallReportAction("");
    setShouldShowDisposition(false);
  }

  // loading call disposition list 
  async function callDispositionList() {
    let response;

    try {
      response = await call_disposition_list();
      setDispositionList(response.data?.data || []);
    } catch (e) {

    }
  }

  async function fetchIncorrectDataList() {
    let response;

    try {
      response = await fetch_incorrect_data();
      setIncorrectDataList(response.data?.data || []);
    } catch (e) {

    }
  }

  // fetching disposition user list 
  async function fetchDispositionReasonsList() {
    let result;
    try {
      result = await data_quality_disposition_list();

      if (result && result.data && result.data.code === 200) {
        setDispositionReasons({
          good: result?.data?.data.correct || [],
          poor: result?.data?.data?.incorrect || []
        });
      }
    } catch (e) { }

  }

  // save alternate mobile Number 
  useEffect(() => {
    setAlternateMobileNoError("");

    let debouncerTimeout;
    if (isAltNoChangedByUser.current) {

      debouncerTimeout = setTimeout(() => {

        if (alternateMobileNo.length > 13) {
          return;
        }

        if (!(/^\d*$/.test(alternateMobileNo))) {
          return;
        }
        // save alternate mobile number
        saveAlternateMobileNo();

      }, 800);
    }

    return () => {
      clearTimeout(debouncerTimeout);
    };
  }, [alternateMobileNo]);


  // debouncing the mobile no update requests
  // useEffect(() => { 
  //   // setAlternateMobileNoError("");
  //   let debouncerTimeout; 
  //   if(isMobilNoChangedByUser[singleUserData.memberId]) {
  //       let mobileNo = singleUserData.mobile; 
  //       debouncerTimeout = setTimeout(() => { 

  //       if(mobileNo.length > 13){   
  //         return; 
  //       }

  //       if (!(/^\d*$/.test(mobileNo))) {
  //         return; 
  //       }      
  //       // save alternate mobile number
  //       saveMobileNo(mobileNo, "main");

  //     }, 800);  
  //   }

  //   return () => {
  //     clearTimeout(debouncerTimeout);
  //   };
  // }, [singleUserData.mobile])


  // debouncing the mobile no update requests
  useEffect(() => {
    // setAlternateMobileNoError(""); 
    let debouncerTimeout;
    if (isAltMobileNoChangedByUser[singleUserData.memberId]) {
      let mobileNo = singleUserData.altMobile;
      debouncerTimeout = setTimeout(() => {

        if (mobileNo.length > 13) {
          return;
        }

        if (!(/^\d*$/.test(mobileNo))) {
          return;
        }
        // save alternate mobile number
        saveAltMobile(mobileNo, "alternate");

      }, 800);
    }

    return () => {
      clearTimeout(debouncerTimeout);
    };
  }, [singleUserData.altMobile]);

  // reseting the program conditions on the click of reset 
  useEffect(() => {
    if (conditionSearchQuery === "") {
      setFilterProgramConditions(programConditions);
    }
  }, [conditionSearchQuery]);

  // saving call notes with debouncing
  // useEffect(() => { 
  //   let debouncerTimeout  = setTimeout(() => { 
  //       if(!callNotes){ 
  //         return; 
  //       } 

  //       saveCallNotes(); 
  //   }, 1200); 

  //   return () => {
  //     clearTimeout(debouncerTimeout);
  //   };
  // }, [callNotes]); 


  // save call notes 
  async function saveCallNotes(e, userId) {

    if (!callNotes) {
      setCallNotesErrors("Please Add Call Notes");
      return;
    }

    let resp;

    try {
      resp = await save_members_notes({ userId: userId, notes: callNotes, confNumber: conferenceNumber });

      if (resp?.data.code === 200) {
        setCallNotes("");
        setCallNotes("");
        setCallNotesSubmissionMessage(resp?.data?.data?.msg);
        fetchCallNotesList(userId);
      } else {
        setCallNotesSubmissionMessage(resp?.data?.data?.msg);
      }
    } catch (e) {
      setCallNotesSubmissionMessage("Something went wrong");
    }

    setTimeout(() => {
      setCallNotesSubmissionMessage("");
    }, 2000);
  }

  // onAlternateMobileNoChange
  function onAlternateMobileNoChange(e) {
    setAlternateMobileNo(e.target.value);
    isAltNoChangedByUser.current = true;
  }

  // save alternate mobile no 
  async function saveAlternateMobileNo() {

    if (alternateMobileNo.length > 13 || alternateMobileNo.length < 8) {
      // mobile no validation error
      setAlternateMobileNoError("Enter Valid Mobile No");
      return;
    }


    if (!(/^\d*$/.test(alternateMobileNo))) {
      setAlternateMobileNoError('Enter Valid Number');
      return;
    }

    setRequestStatus(status => ({ ...status, isAlternateMobileNoLoading: true }));

    // request api
    let response;

    try {
      response = await save_alt_number({ userId: userDataId, altNumber: alternateMobileNo });
    } catch (e) {
      try {
        await soft_number_logger({ userId: userDataId, exName: e.name, exMessage: e.message, type: "save_alt_number" });
      } catch (e) {
        console.log(e);
      }
      setAlternateMobileNoSubmissionmessage({
        status: false,
        message: "Something Went wrong",
      })
    }finally{ 
      setRequestStatus(status => ({ ...status, isAlternateMobileNoLoading: false }));
    }

    if (response?.data?.code === 200) {
      setAlternateMobileNoSubmissionmessage({
        status: true,
        message: response.data?.data?.msg
      });
    } else {
      setAlternateMobileNoSubmissionmessage({
        status: false,
        message: response?.data?.data?.msg
      })
    }

    setTimeout(() => {
      setAlternateMobileNoSubmissionmessage({
        status: false,
        message: ""
      })
    }, 3000);
  }

  async function saveMobileNo(e, mobileNo, type) {
    let isValid = true;
    let errorMsg = "";

    if (!(/^\d*$/.test(mobileNo))) {
      errorMsg = "Enter Valid Mobile No";
      isValid = false;
    }

    if (!mobileNo) {
      errorMsg = "Enter Valid Mobile No";
      isValid = false;
    }

    if (!isValid) {
      setMemberInfoChangeErrors({
        ...memberInfoChangeErrors,
        [singleUserData.memberId]: {
          ...memberInfoChangeErrors[singleUserData.memberId],
          'mobileNo': errorMsg
        }
      });
      return;
    }

    try {
      let resp = await updateMobileNo(singleUserData.encUserId, mobileNo, type);
      if (resp.data?.code === 200) {
        chagneMemberInfoFieldMode(e, "mobileNo", "display");

        // update the main membership no in copy state as well here 
        setSingleUserDataList((previous) => {
          return previous.map((item) => {
            if (item.memberId === singleUserData.memberId) {
              item['extensionNo'] = resp?.data?.data?.extensionNo
            }
            item['copymobile'] = mobileNo;
            return item;
          })
        });

        setSingleUserData({
          ...singleUserData,
          extensionNo: resp?.data?.data?.extensionNo, 
          copymobile: mobileNo
        });

      } else {
        setMemberInfoChangeErrors({
          ...memberInfoChangeErrors,
          [singleUserData.memberId]: {
            ...memberInfoChangeErrors[singleUserData.memberId],
            'mobileNo': resp?.data?.data?.msg || "Something went wrong"
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }


  async function saveAltMobile(mobileNo, type) {
    try {
      let resp = await updateMobileNo(singleUserData.encUserId, mobileNo, type);
    } catch (e) {
      console.log(e);
    }
  }

  async function saveMainMembershipNo(e) {

    let mainMembershipNo = singleUserData.mainMembershipNo.trim();
    if (!mainMembershipNo) {
      setMemberInfoChangeErrors({
        ...memberInfoChangeErrors,
        [singleUserData.memberId]: {
          ...memberInfoChangeErrors[singleUserData.memberId],
          mainMembershipNo: "Main Membership No is required"
        }
      });
      return;
    }

    try {
      let resp = await updateMainMembershipNo({ userId: singleUserData.encUserId, mainMembershipNo: mainMembershipNo });
      if (resp.data.code === 200) {
        setReflect(!reflect);
        chagneMemberInfoFieldMode(e, "mainMembershipNo", "display");

        // update the main membership no in copy state as well here 
        setSingleUserDataList((previous) => {
          return previous.map((item) => {
            item['copymainMembershipNo'] = mainMembershipNo;
            return item;
          })
        });

        setSingleUserData({
          ...singleUserData,
          copymainMembershipNo: mainMembershipNo
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // handle mobile edit 
  function onMemberInfoChange(e) {
    let isValid = true;
    let errors = {};
    if (e.target.name === "mobile" || e.target.name === "altMobile") {
      if (!/^\s*[+-]?(?:\d+|\d*\.\d+)?\s*$/.test(e.target.value)) {
        isValid = false;
        let fieldName = e.target.name === "mobile" ? "Mobile Number" : "Alternate Mobile No";
      }
    }

    if (e.target.name === "mainMembershipNo") {
      if (e.target.value.trim() === 0) {
        isValid = false;
      }

      if (!/^[0-9]*$/.test(e.target.value.trim())) {
        isValid = false;
      }
    }

    if (!isValid) {
      setMemberInfoChangeErrors({
        ...memberInfoChangeErrors,
        [singleUserData.memberId]: errors
      });
      return;
    } else {
      setMemberInfoChangeErrors({
        ...memberInfoChangeErrors,
        [singleUserData.memberId]: {
          mainMembershipNo: "",
          altMobile: "",
          mobile: ""
        }
      });
    }

    setSingleUserDataList((previous) => {
      return previous.map((item) => {
        if (item.memberId === singleUserData.memberId) {
          return { ...item, [e.target.name]: e.target.value };
        }
        return item;
      })
    });

    setSingleUserData({
      ...singleUserData,
      [e.target.name]: e.target.value
    });

    // if(e.target.name === "mobile"){ 
    setIsMobilNoChangedByUser({
      ...isMobilNoChangedByUser,
      [singleUserData.memberId]: true
    });
    // }

    if (e.target.name === 'altMobile') {
      setIsAltMobilNoChangedByUser({
        ...isAltMobileNoChangedByUser,
        [singleUserData.memberId]: true
      });
    }
  }


  // save call report 
  async function saveCallReport(e, memberStatus) {

    if (memberStatus === "yes") {
      setRequestStatus(status => ({
        ...status,
        isEnrolling: true
      }));

      try {
        let result = await accept_enrollment({ confNumber: conferenceNumber });
        let slotLimit = result.data?.data.cnSlotDaysLimit
        setCnBookingSlotLimit(slotLimit);
        setIsAlreadyEntrolled(true);
      } catch (error) {
      } finally {
        setRequestStatus(status => ({
          ...status,
          isEnrolling: false
        }));
      }
    }


    // setting members interest to enroll 
    setCallReport({
      ...callReport,
      doesMemberWantToEnroll: memberStatus
    });

    setFamilyCallReport({
      ...familyCallReport,
      [singleUserData.memberId]: {
        ...familyCallReport[singleUserData['memberId']],
        doesMemberWantToEnroll: memberStatus
      }
    });


  }

  // mock call savecallreport function
  async function saveMockCallReport(e, memberStatus, type = "single") {
    if (memberStatus === "yes") {
      setRequestStatus(status => ({
        ...status,
        isEnrolling: true
      }));
      let result;
      try {
        if (type === "family") {
          result = await direct_accept_enrollment({ confNumber: conferenceNumber, poolId: singleUserData.poolId, userId: singleUserData.encUserId, universalConfNumber });

        } else {
          result = await direct_accept_enrollment({ confNumber: conferenceNumber, poolId, userId: userDataId, universalConfNumber });
        }
        let slotLimit = result.data?.data.cnSlotDaysLimit
        setCnBookingSlotLimit(slotLimit);
        setIsAlreadyEntrolled(true);
      } catch (error) {
      } finally {
        setRequestStatus(status => ({
          ...status,
          isEnrolling: false
        }));
      }
    }

    setFamilyCallReport({
      ...familyCallReport,
      [singleUserData.memberId]: {
        ...familyCallReport[singleUserData['memberId']],
        doesMemberWantToEnroll: memberStatus
      }
    });

    // setting members interest to enroll 
    setCallReport({
      ...callReport,
      doesMemberWantToEnroll: memberStatus
    });
  }



  // set call action 
  function performCalAction(e, actionToPerform) {
    // clear the previous state of slots 
    setSlots([]);
    setSelectedSlot("");

    setSlotBookingFormData({
      date: "",
      time: "",
    });

    setSlotBookingFormDataErrors({
      date: "",
      time: "",
      selectedSlot: ""
    });

    setAreSlotsFetched(false);
    setCallAction(actionToPerform);
  }



  async function performFamilyCallAction(e, actionToPerform) {
    // clear the previous state of slots 
    setFamilySlots({
      ...familySlots,
      [singleUserData.memberId]: []
    });

    setFamilySelectedSlot({
      ...familySelectedSlot,
      [singleUserData.memberId]: ""
    });

    fetchOnlineAgents();

    setFamilySlotBookingFormData({
      ...familySlotBookingFormData,
      [singleUserData.memberId]: {
        date: "",
        time: "",
        cnId: "",
      }
    });

    setFamilySlotBookingFormDataErrors({
      ...familySlotBookingFormDataErrors,
      [singleUserData.memberId]: {
        date: "",
        time: "",
        selectedSlot: ""
      }
    });


    let selectedId = await fetchfamilyAgentuserList(singleUserData.encUserId);

    setFamilySlotBookingFormData({
      ...familySlotBookingFormData,
      [singleUserData.memberId]: {
        ...familySlotBookingFormData[singleUserData.memberId],
        cnId: selectedId
      }
    });

    setFamilyCallAction({
      ...familyCallAction,
      [singleUserData.memberId]: actionToPerform
    });

    setAreFamilySlotsFetched({
      ...areFamilySlotsFetched,
      [singleUserData.memberId]: false
    });
  }


  // merge call with carenavigator 
  async function mergeCallWithCareNavigator(e) {
    try {
      let resp = await agent_call_unhold({ confNumber: conferenceNumber, type: "user", poolId, carenavigatorId: careNavigatorId });
      setIsCallMergedWithCn(true);
      console.log(resp.data?.code);
    } catch (e) {

    }
  }


  // drop carenavigator from call 
  async function dropAgentFromCall(e, type, careNavigatorId) {
    if (type === "agent") {
      closeModal.current.disabled = false;
      setShowDispositionModal(true);
      resetCallReportStates();
    }

    if (type === "cn") {
      webexTransferCall?.end();
      stopRingtone(true);
      setCareNavigatorTransferStatus({
        ...careNavigatorTransferStatus,
        callStatus: "idle",
        callDisplayStatus: "Connecting"
      });

      webexCall.doHoldResume();
      setIsHoldDisabled(true);
      setWebexTransferCall(null); 
    }

    try {
      let response = await cn_call_exit({ confNumber: conferenceNumber, poolId, type, careNavigatorId });
    } catch (e) {

    }
  }

  // 
  function callPopupClose() {
    setReflect(!reflect);
    if (isCallConnected && !showDispositionModal) {
      setShowDispositionModal(true);
      resetCallReportStates();
    }
  }

  function mockCallPopupClose() {
    setReflect(!reflect);
    if (isCallPlaced && !showDispositionModal) {
      setShowMockDispositionModal(true);
      resetCallReportStates();
    }
    setIsCallPlaced(false); 
    setRequestStatus(status => ({ ...status, isBookingLoading: false }));
  }

  // mark data as incorrect
  async function markDataAsIncorrect() {
    let response;
    setRequestStatus(status =>({
      ...status,
      isIncorrectDataLoading: true
    }))
    try {
      response = await incorrect_data({ userId: userDataId, poolId, confNumber: conferenceNumber });
    } catch (e) {
      // handle error
    }finally{ 
      setRequestStatus(status => ({
        ...status,
        isIncorrectDataLoading: false
      }))
    }


    if (callStatus !== "idle") {
      popupDisconnectCall("agent");
    } else {
      if (!showDispositionModal) {
        setShowDispositionModal(true);
      }
    }

  }


  async function markFamilyDataAsIncorrect() {
    let response;
    setRequestStatus(status => ({
      ...status,
      isIncorrectDataLoading: true
    })); 

    try {
      response = await family_incorrect_data({ userId: singleUserData.encUserId, poolId: singleUserData.poolId, universalConfNumber, confNumber: conferenceNumber });
    } catch (e) {
      // handle error
    }finally{ 
      setRequestStatus(status => ({
        ...status,
        isIncorrectDataLoading: false
      }));
    }


    setFamilyCallReportActionCompleted({
      ...familyCallReportActionCompleted,
      [singleUserData.memberId]: true
    });

    setFamilyCallReportActionType({
      ...familyCallReportAction,
      [singleUserData.memberId]: "incorrect"
    });

  }

  // mark data as incorrect to mock 
  async function markMockDataAsIncorrect() {
    let response;
    setRequestStatus(status => ({
      ...status,
      isIncorrectDataLoading: true
    }));
    try {
      response = await incorrect_data({ userId: userDataId, poolId, confNumber: conferenceNumber });

      setCallReportActionType("incorrect");


      if (callStatus !== "idle") {
        popupMockDisconnectCall("agent");
      } else {
        if (!showDispositionModal) {
          setShowMockDispositionModal(true);
        }
      }

    } catch (e) {
      // handle error
    } finally {
      setRequestStatus(status => ({
        ...status,
        isIncorrectDataLoading: false
      }));
    }


  }

  // register later enrollment
  async function registerLaterEnrollment(type = "single") {

    if (type === "single") {
      if (!enrollLaterForm.date) {
        setEnrollLaterFormErrors({
          ...enrollLaterFormErrors,
          date: "This Field is required"
        });
        return;
      }

      setRequestStatus(status => ({
        ...status,
        isEnrollLaterLoading: true
      }));

      let response;

      try {
        response = await enroll_later({
          confNumber: conferenceNumber, userId: userDataId, poolId, laterEnrollmentDate: formatDateTime(enrollLaterForm.date)
          , universalConfNumber
        });
        setCallReportActionType("correct");
      } catch (e) { }
      finally{ 
        setRequestStatus(status => ({
          ...status,
          isEnrollLaterLoading: false
        }));
      }
      // hadle error 
      if (!response) { }

      setCallReportActionCompleted(true);

    } else {
      // validate the rquest
      const enrollmentDate = type === 'single' ? enrollLaterForm.date : familyEnrollLaterForm[singleUserData.memberId]?.date;
      if (!enrollmentDate) {
        setFamilyEnrollLaterFormErrors({
          ...familyEnrollLaterFormErrors,
          [singleUserData.memberId]: {
            date: "This Field is required"
          }
        });
        return;
      }

      setRequestStatus(status => ({
        ...status,
        isEnrollLaterLoading: true
      }));

      let response;

      try {
        response = await family_enroll_later({ confNumber: conferenceNumber, userId: singleUserData.encUserId, poolId: singleUserData.poolId, laterEnrollmentDate: formatDateTime(enrollmentDate), universalConfNumber });

        setFamilyCallReportActionType({
          ...familyCallReportActionType,
          [singleUserData.memberId]: "correct"
        })

        if (response?.data?.code === 200) {
          // let enrollmentStatus = response?.data?.data?.enrollmentStatus; 
          // let actionData = response?.data?.data?.actionData; 

          // setSingleUserDataList((previous) => { 
          //    return previous.map((item) => { 
          //       if(item.memberId === singleUserData.memberId){ 
          //         item['enrollmentStatus'] = enrollmentStatus; 
          //         item['actionData'] = actionData; 

          //         return item; 
          //       }

          //       return item; 
          //    })
          // })   
        }
      } catch (e) { }
      finally{ 
        setRequestStatus(status => ({
          ...status,
          isEnrollLaterLoading: false
        }));
      }
      // hadle error 
      if (!response) { }

      setFamilyCallReportActionCompleted({
        ...familyCallReportActionCompleted,
        [singleUserData.memberId]: true
      });
    }

  }

  const handleShowMemberProfileDetailsModal = (from="out") => {
    setAddingMemberFrom(from); 
    let callModal = document.querySelector('#agentCallModal3'); 
    if(callModal){ 
      callModal.removeAttribute('tabindex'); 
    }
    setShowAdMemberModal(true);
  }


  function addDaystoDate(date, n = 21) {
    let currDate = new Date(date);
    currDate.setDate(currDate.getDate() + n);
    return currDate;
  }

  const renderMCA1Tooltip = (props) => (
    <Tooltip className="custom-tooltip" {...props}>
      Number of members contacted.
    </Tooltip>
  );

  const renderMCA2Tooltip = (props) => (
    <Tooltip className="custom-tooltip" {...props}>
      Members interested in the program but will onboard later.
    </Tooltip>
  );

  const renderMCA3Tooltip = (props) => (
    <Tooltip className="custom-tooltip" {...props}>
      Number of members who enrolled in the program.
    </Tooltip>
  );

  const renderMCA4Tooltip = (props) => (
    <Tooltip className="custom-tooltip" {...props}>
      Number of calls successfully completed.
    </Tooltip>
  );

  const renderMCA5Tooltip = (props) => (
    <Tooltip className="custom-tooltip" {...props}>
      Average duration of completed calls.
    </Tooltip>
  );

  const renderMCA6Tooltip = (props) => (
    <Tooltip className="custom-tooltip" {...props}>
      Percentage of calls successfully completed.
    </Tooltip>
  );

  const renderCommonTooltip = (props) => (
    <Tooltip className="custom-tooltip" {...props}>
      {props.text}
    </Tooltip>
  );

  const [showcallHistoryDetailsModal, setShowcallHistoryDetailsModal] = useState(false);

  const [memberInfoChangeErrors, setMemberInfoChangeErrors] = useState({});
  const [addingMemberFrom, setAddingMemberFrom] = useState("out"); 
  const [callCloseWarningPopup,setCallCloseWarningPopup] = useState(false);
  const [showTelehealthModal, setShowTeleHealthModal] = useState(false); 

  const handleShowcallHistoryDetailsModal = () => {
    setShowcallHistoryDetailsModal(true);
  }

  const handleShowcallHistoryDetailsModalFamily = async (userId) => {
    let resp = await getuserCallBack(userId);
    setMemberCall(resp.data.callData);
    setMemberCallBackList(resp.data.callBackData);
    setShowcallHistoryDetailsModal(true);
  }

  const handleShowcallHistoryDetailsModalFamilyNotes = async (userId) => {
    let response = await fetch_members_notes({ userId });
    if (response.data?.code === 200) {
      setCallNotesList(response.data?.data || []);
    }
  }


  const updateMobileNoMember = async (userId, newMobile, mobileType) => {
    let resp = await updateMobileNo(userId, '12345', mobileType);
    setMemberCall(resp.data.callData);
    setMemberCallBackList(resp.data.callBackData);
    setShowcallHistoryDetailsModal(true);
  }


  const changeCallNotes = (e) => {
    setCallNotes(e.target.value);
    setCallNotesErrors("");
  }

  // For Edit functinality of the Member information
  function chagneMemberInfoFieldMode(e, field, mode) {
    if (mode === "edit") {
      let copyFieldName = (field === "mobileNo" ? `copymobile` : `copy${field}`);

      // copying the original mobile no to revert it back in case of any error in updating new mobile No
      setSingleUserDataList((previous) => {
        return previous.map((item) => {
          item[copyFieldName] = (field === "mobileNo" ? item['mobile'] : item[field]);
          return item;
        })
      });


      setSingleUserData({
        ...singleUserData,
        [copyFieldName]: (field === "mobileNo" ? singleUserData['mobile'] : singleUserData[field])
      })
    }

    setMemberInfoMode({
      ...memberInfoMode,
      [field]: mode
    });
  }


  function searchConditions(e) {
    setConditionSearchQuery(e.target.value);

    if (e.target.value === "") {
      setFilterProgramConditions(programConditions);
    } else {
      setFilterProgramConditions((previous) => {
        return previous.filter((item) => {
          if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return item;
          }
        })
      })
    }

  }


  // ------------------------------- webex calling --------------------------------------
  async function initWebexCalling() {
    setIsWebexDeviceReady(true);

    // webex config
    const webexConfig = {
      config: {
        logger: {
          level: 'debug' // Set the desired log level
        },
        meetings: {
          reconnection: {
            enabled: true
          },
          enableRtx: true
        },
        encryption: {
          kmsInitialTimeout: 8000,
          kmsMaxTimeout: 40000,
          batcherMaxCalls: 30,
          caroots: null
        },
        dss: {}
      },
      credentials: {
        access_token: webexAccesstoken
      }
    };

    // Create the calling configuration 
    const callingConfig = {
      clientConfig: {
        calling: true,
        contact: true,
        callHistory: true,
        callSettings: true,
        voicemail: true
      },
      callingClientConfig: {
        logger: {
          level: 'info'
        }
      },
      logger: {
        level: 'info'
      }
    };

    let calling;

    try {
      calling = await window.Calling.init({ webexConfig, callingConfig });
    } catch (error) {
      await soft_number_logger({
        errorAt: "Calling init function", name: error.name,
        message: error.message,
        stack: error.stack,
        code: error.code || null,
        status: error.status || null,
        data: error.data || null,
      });
    }

    setWebexCalling(calling);

    let callingClient;
    calling.on('ready', () => {
      calling.register().then(() => {
        callingClient = calling.callingClient;

        callingClient?.on('callingClient:user_recent_sessions', (sessionData) => {
          console.log('Users recent session data : ', sessionData.data.userSessions.userSessions[0]);
        });

        let line = Object.values(callingClient.getLines())[0];
        setWebexLine(line);

        // create a device  
        line.register();
        setIsWebexDeviceReady(false);
      }).catch(async (error) => {

        // navigate('/logout'); 
        console.log('webex calling error', error);
        setIsWebexDeviceReady(false);

        await soft_number_logger({
          errorAt: "initiallizeing webex Calling", name: error.name,
          message: error.message,
          stack: error.stack,
          code: error.code || null,
          status: error.status || null,
          data: error.data || null,
        });
        setIsWebexDeviceReady(false);
      });
    });
  }

  function selectConditions(e) {

    let selectedCondition = [...singleUserData['programArr']];

    if (e.target.checked) {
      selectedCondition.push(e.target.value);
    } else {
      if (selectedCondition.length === 1) {
        return;
      }

      selectedCondition = selectedCondition.filter((item) => {
        if (item !== e.target.value) {
          return item;
        }
      });
    }

    // set single user data list 
    setSingleUserDataList((previous) => {
      return previous.map((item) => {
        if (item.memberId === singleUserData.memberId) {
          return { ...item, programArr: [...selectedCondition] };
        }

        return item;
      })
    });

    setSingleUserData({
      ...singleUserData,
      programArr: [...selectedCondition]
    });
  }

  // update conditions 
  async function updateCondition() {
    let response;
    try {
      response = await update_program_condition({ userId: singleUserData.encUserId, programArr: JSON.stringify(singleUserData.programArr) });
      if (response?.data?.code === 200) {
        let conditionString = "";

        programConditions.forEach((item, index) => {
          if (singleUserData.programArr.includes(item.id)) {
            conditionString += `${item.conditionName}, `;
          }
        });

        conditionString = conditionString.slice(0, -2);



        setSingleUserDataList((previous) => {
          return previous.map((item) => {
            if (item.memberId === singleUserData.memberId) {
              return {
                ...item,
                chronicConditions: conditionString,
                copyProgramArr: [...item.programArr],
              };
            }
            return item;
          });
        });

        setSingleUserData({
          ...singleUserData,
          chronicConditions: conditionString,
          copyProgramArr: [...singleUserData.programArr]
        });

        setTimeout(() => {
          conditionModalCloseBtn.current?.click();
        }, 50);
      }
    } catch (e) {
    }
  }


  function playRingtone(transfer = false) {
    if (transfer) {
      transferAudio.current.currentTime = 0;
      transferAudio.current.play();
    } else {
      ringtoneAudio.current.currentTime = 0;
      ringtoneAudio.current.play();
    }
  }

  function stopRingtone(transfer = false) {
    if (transfer) {
      transferAudio.current.pause();
      transferAudio.current.currentTime = 0;
    } else {
      ringtoneAudio.current.pause();
      ringtoneAudio.current.currentTime = 0;
    }
  }


  // check microphones and permission
  async function checkMicrophonesAndPermission() {
    try {
      // Check if the browser supports getUserMedia
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        return false;
      }

      // Request microphone permissions
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });


      // Get list of audio input devices
      const devices = await navigator.mediaDevices.enumerateDevices();
      const microphones = devices.filter(device => device.kind === 'audioinput');

      if (microphones.length === 0) {
        // alert('No microphones found');
        return false;
      }

      // Clean up the stream
      stream.getTracks().forEach(track => track.stop());

      return true;

    } catch (error) {
      // alert('Please Check Microphone Permissions');
      return false;
    }
  }


  // tele-health modal states
  
   function closeTelehealthModal () {
    setShowTeleHealthModal(false);
   }  


  return (
    !isValidTab ?
     <div className="container-fluid">
        <h2>You Already have Active, To continue here, please close Already active tab first.</h2>
    </div>: 

    <>
      <div className="container-fluid">
        <div className="member-engagement p-2 mb-3">
          <LoaderLoaderConnect isLoading={isWebexDeviceReady} />

          <div className="row text-center px-2">

            <div className="col-2 px-1" >
              <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col pe-0">
                      <h3 className="card-title1" style={{ lineHeight: "0.9" }}>{totalMemberReachout}</h3>
                      <h5 className="card-text1">Members Reachout
                        <OverlayTrigger placement="top" overlay={renderMCA1Tooltip}>
                          <i className='bx bx-info-circle ms-1'></i>
                        </OverlayTrigger>
                      </h5>
                    </div>
                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCA1Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2 px-1" >
              <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body">
                  <div className="row">

                    <div className="col pe-0">
                      <h3 className="card-title1" style={{ lineHeight: "0.9" }}>{enrolledCall}</h3>
                      <h5 className="card-text1">Enrolled Members
                        <OverlayTrigger placement="top" overlay={renderMCA3Tooltip}>
                          <i className='bx bx-info-circle ms-1'></i>
                        </OverlayTrigger>
                      </h5>
                    </div>
                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCA3Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 px-1" >
              <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body">
                  <div className="row">

                    <div className="col pe-0">
                      <h3 className="card-title1" style={{ lineHeight: "0.9" }}>{callsCounducted}</h3>
                      <h5 className="card-text1">Enrolled Later
                        <OverlayTrigger placement="top" overlay={renderMCA2Tooltip}>
                          <i className='bx bx-info-circle ms-1'></i>
                        </OverlayTrigger>
                      </h5>
                    </div>
                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCA2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            

            <div className="col-2 px-1" >
              <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body">
                  <div className="row">

                    <div className="col pe-0">
                      <h3 className="card-title1" style={{ lineHeight: "0.9" }}>{completedCall}</h3>
                      <h5 className="card-text1">Call Completed
                        <OverlayTrigger placement="top" overlay={renderMCA4Tooltip}>
                          <i className='bx bx-info-circle ms-1'></i>
                        </OverlayTrigger>
                      </h5>
                    </div>
                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCA4Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2 px-1" >
              <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body">
                  <div className="row">

                    <div className="col pe-0">
                      <h3 className="card-title1" style={{ lineHeight: "0.9" }}>{avgCallTime}</h3>
                      <h5 className="card-text1">Avg. Call Time
                        <OverlayTrigger placement="top" overlay={renderMCA5Tooltip}>
                          <i className='bx bx-info-circle ms-1'></i>
                        </OverlayTrigger>
                      </h5>
                    </div>
                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCA5Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2 px-1" >
              <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body">
                  <div className="row">

                    <div className="col pe-0">
                      <h3 className="card-title1" style={{ lineHeight: "0.9" }}>{completeRateCall}<span>%</span></h3>
                      <h5 className="card-text1">Call Completion Rate
                        <OverlayTrigger placement="top" overlay={renderMCA6Tooltip}>
                          <i className='bx bx-info-circle ms-1'></i>
                        </OverlayTrigger>
                      </h5>
                    </div>
                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCA6Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>




        {/* 
        <div className="row"> 
        <div className="col-md">
            <div className="card card-box-agent">
              <div className="card-body">
                <div className="row align-items-center my-1">
                  <div className="col-auto">
                    <h1 className="agent-card-title">
                      <i className="bx bx-phone-call"></i>
                    </h1>
                  </div>
                  <div className="col-auto ps-0">
                    <h2 className="agent-card-subtitle">
                      Members
                      <br /> Reachout
                    </h2> 
                  </div>
                  <div className="col text-end ps-0">
                    <h1 className="agent-card-title">{totalMemberReachout}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="col-md">
            <div className="card card-box-agent">
              <div className="card-body">
                <div className="row align-items-center my-1">
                  <div className="col-auto">
                    <h1 className="agent-card-title">
                      <i className="bx bx-phone-call"></i>
                    </h1>
                  </div>
                  <div className="col-auto ps-0">
                    <h2 className="agent-card-subtitle">
                      Call
                      <br /> Completed
                    </h2> 
                  </div>
                  <div className="col text-end ps-0">
                    <h1 className="agent-card-title">{completedCall}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


        {/* <div className="col-md">
            <div className="card card-box-agent">
              <div className="card-body">
                <div className="row align-items-center my-1">
                  <div className="col-auto">
                    <h1 className="agent-card-title">
                      <i className="bx bx-phone-call"></i>
                    </h1>
                  </div>
                  <div className="col-auto ps-0">
                    <h2 className="agent-card-subtitle">
                      Members 
                      <br /> Connected
                    </h2> 
                  </div>
                  <div className="col text-end ps-0">
                    <h1 className="agent-card-title">{callsCounducted}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="col-md">
            <LoaderLoader isLoading={isLoading} />
            <div className="card card-box-agent">
              <div className="card-body">
                <div className="row align-items-center my-1">
                  <div className="col-auto">
                    <h1 className="agent-card-title">
                      <i className="bi bi-person"></i>
                    </h1>
                  </div>
                  <div className="col-auto ps-0">
                    <h2 className="agent-card-subtitle">
                      Enrolled
                      <br /> Members
                    </h2>
                  </div>
                  <div className="col text-end ps-0">
                    <h1 className="agent-card-title">{enrolledCall}<span>/{todaysTarget}</span></h1>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="col-md">
            <div className="card card-box-agent">
              <div className="card-body">
                <div className="row align-items-center my-1">
                  <div className="col-auto">
                    <h1 className="agent-card-title">
                      <i className="bx bx-phone-call"></i>
                    </h1>
                  </div>
                  <div className="col-auto ps-0">
                    <h2 className="agent-card-subtitle">
                      Avg.
                      <br /> Call Time
                    </h2>
                  </div>
                  <div className="col text-end ps-0">
                    <h1 className="agent-card-title">{avgCallTime} </h1>
                   
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="col-md">
            <div className="card card-box-agent">
              <div className="card-body">
                <div className="row align-items-center my-1">
                  <div className="col-auto">
                    <h1 className="agent-card-title">
                      <i className="bx bx-phone-call"></i>
                    </h1>
                  </div>
                  <div className="col-auto ps-0">
                    <h2 className="agent-card-subtitle">
                      Call
                      <br /> Completion Rate
                    </h2>
                  </div>
                  <div className="col text-end ps-0">
                    <h1 className="agent-card-title">{completeRateCall} % </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row mt-4 mb-4">
          <div className="col-md-6">
            <div className="card card-box-agent-green">
              <div className="card-body">
                <div className="row align-items-center my-1">
                  <div className="col-auto">
                    <h1
                      className="agent-card-title"
                      style={{ fontSize: "22px" }}
                    >
                      <i className="bi bi-telephone-inbound"></i>
                    </h1>
                  </div>

                  <div className="col-auto">
                    <h2 className="agent-card-subtitle">
                      Callbacks Planned for Today
                    </h2>
                  </div>
                  <div className="col-auto">
                    <h1 className="agent-card-title">
                      {pendingCallback}
                      {/* /<span>{totalRequestCallBackCount}</span> */}
                    </h1>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-12">
                    <div className="table-responsive" style={{ height: "380px", overflowY: "scroll" }}>
                      <table className="table mb-0">
                        <thead style={{ position: "sticky", top: "0" }}>
                          <tr>
                            <th scope="col">Member Id</th>
                            <th scope="col">Member Name</th>
                            <th scope="col">Main Membership No</th>
                            <th scope="col">Last Attempted</th>
                            <th scope="col">Call Back Time</th>
                            <th scope="col">Action</th>
                            {/* <th scope="col">Mock Call</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {userCallbackList.map((data) => (
                            <tr key={data.userId}>
                              <td>{data.membershipNo }</td>
                              <td>{data.memberName } {data.isFamily && <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3slhn/qsf289t8lj.png" style={{ height: "14px", verticalAlign: "baseline" }} alt="" />} </td>
                              <td>{data.familyId}</td>
                              <td>{data.lastAttempted}</td>
                              <td>{data.scheduledDateTime}</td>
                              {/* <td>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm call-now-btn d-flex"
                                  data-bs-toggle="modal"
                                  data-bs-target="#agentCallModal"
                                  onClick={() =>
                                    popupSingleUserDetailShow(
                                      data.encUserId,
                                      data.memberId, 
                                      data.poolId
                                    )
                                  }
                                >
                                  <i className="bi bi-telephone-fill pe-2"></i>{" "}
                                  Call
                                </button>
                              </td> */}

                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm call-now-btn d-flex"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#agentCallModal2"
                                  onClick={() =>
                                    popupSingleUserDetailShow(
                                      data.encUserId,
                                      data.memberId,
                                      data.poolId,
                                      data.isFamily ? "family" : "single"
                                    )
                                  }
                                >
                                  <i className="bi bi-telephone-fill pe-2"></i>{" "}
                                  Call
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card card-box-agent-yellow">
              <div className="card-body">
                <div className="row align-items-center my-1">
                  <div className="col-auto">
                    <h1 className="agent-card-title">
                      <i className="bx bx-phone-call" style={{ color: `${webexLine ? 'green' : ''}` }}></i>
                    </h1>
                  </div>

                  <div className="col-auto">
                    <h2 className="agent-card-subtitle">Today's Call List</h2>
                  </div>

                  <div className="col-auto">
                    <h1 className="agent-card-title">
                      {pendingCallCount}/<span>{totalCallCount}</span>
                    </h1>
                  </div>
                  <div className="col-auto ps-0">
                    <button className="btn call-now-btn" onClick={e => handleShowMemberProfileDetailsModal()}><i className="bi bi-person-plus-fill"></i> Add Member </button>
                    <button
                      type="button"
                      ref={callModalOpener}
                      style={{ display: "block", position: 'fixed', top: '50%', zIndex: "-1" }}
                      className="btn btn-primary btn-sm call-now-btn "
                      data-bs-toggle="modal"
                      data-bs-target="#agentCallModal2"
                      // data-bs-target={`#${modalType}`}
                      onClick={() => {
                        // resetting the previous timer 
                        setTimer({
                          seconds: "00",
                          minutes: "00",
                          hours: "00",
                          isTimerAlreadyStarted: false,
                          intervalId: null,
                        });

                        //   popupSingleUserDetailShow(
                        //   data.encUserId,
                        //   data.memberId, 
                        //   data.poolId
                        // )
                      }}

                    >
                      <i className="bi bi-telephone-fill pe-2"></i>{" "}
                      Call Popup Opener
                    </button>


                    <button
                      type="button"
                      ref={familyCallModalOpener}
                      style={{ display: "block", position: 'fixed', top: '50%', zIndex: "-1" }}
                      className="btn btn-primary btn-sm call-now-btn "
                      data-bs-toggle="modal"
                      data-bs-target="#agentCallModal3"
                      // data-bs-target={`#${modalType}`}
                      onClick={() => {
                        // resetting the previous timer 
                        setTimer({
                          seconds: "00",
                          minutes: "00",
                          hours: "00",
                          isTimerAlreadyStarted: false,
                          intervalId: null,
                        });

                        //   popupSingleUserDetailShow(
                        //   data.encUserId,
                        //   data.memberId, 
                        //   data.poolId
                        // )
                      }}

                    >
                      <i className="bi bi-telephone-fill pe-2"></i>{" "}
                      Family Call Popup Opener
                    </button>
                  </div>
                  <div className="col text-end">
                    <h2 className="agent-card-subtitle1">
                      {batchNo}
                    </h2>
                  </div>
                  <div className="col-auto text-end mb-1 ps-0">
                    <button type="button"
                      className="agent-card-subtitle1 btnn call-now-btn"
                      onClick={() => popupCreatePool()}
                      style={{ padding: '5px 10px !important' }}
                      disabled={!isEligibleForPool || requestStatus.isRefreshPoolLoading ? "disabled" : ""}
                    >
                      <span>Refresh <i className="bi bi-arrow-clockwise"></i></span>
                    </button>
                  </div>

                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-12">
                    <div className="table-responsive" style={{ height: "380px", overflowY: "scroll" }}>
                      <table className="table mb-0">
                        <thead style={{ position: "sticky", top: "0" }}>
                          <tr>
                            <th scope="col">Member<br />ID</th>
                            <th scope="col">Member<br /> Name</th>
                            <th scope="col">Main Membership<br /> No</th>
                            <th scope="col">Last<br /> Attempted</th>
                            <th scope="col">Action</th>
                            {/* <th scope="col">Call</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {userDataList.map((data) => (
                            <tr key={data.userId}>
                              {/* {data.rawJson.map((detail, index) => ( */}
                              <>
                                {/* <td>{detail[0]}</td>
                                        <td>{detail[1]}</td>
                                        <td>{detail[4]}</td>
                                        <td>{CalculateAge(detail[5])}</td>
                                        <td>{detail[6]}</td>
                                        <td>0</td>
                                        <td><button type="button" className="btn btn-primary btn-sm call-now-btn d-flex" data-bs-toggle="modal" data-bs-target="#agentCallModal" onClick={() => popupSingleUserDetailShow(data.memberId,detail[0])}><i className="bi bi-telephone-fill pe-2"></i> Call</button></td> */}

                                <td>{data.memberId}</td>
                                <td>{data.memberName} {data.isFamily && <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3slhn/qsf289t8lj.png" style={{ height: "14px", verticalAlign: "baseline" }} alt="" />}</td>
                                <td>{data.familyId}</td>
                                <td>{data.attemptedCount}</td>
                                {/* <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm call-now-btn d-flex"
                                      data-bs-toggle="modal"  
                                      data-bs-target="#agentCallModal"
                                      onClick={() =>{ 
                                          // resetting the previous timer 
                                          setTimer({ 
                                            seconds: "00",
                                            minutes: "00",
                                            hours: "00",
                                            isTimerAlreadyStarted: false,
                                            intervalId: null,
                                          }); 

                                          popupSingleUserDetailShow(
                                          data.encUserId,
                                          data.memberId, 
                                          data.poolId
                                        )
                                      }}
                                    >
                                      <i className="bi bi-telephone-fill pe-2"></i>{" "}
                                      Call
                                    </button>
                                  </td> */}

                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm call-now-btn d-flex"
                                    // data-bs-toggle="modal"  
                                    // data-bs-target="#agentCallModal2"
                                    onClick={() => {
                                      // resetting the previous timer 
                                      setTimer({
                                        seconds: "00",
                                        minutes: "00",
                                        hours: "00",
                                        isTimerAlreadyStarted: false,
                                        intervalId: null,
                                      });

                                      popupSingleUserDetailShow(
                                        data.encUserId,
                                        data.memberId,
                                        data.poolId,
                                        data.isFamily ? "family" : "single"
                                      );

                                    }}
                                  >
                                    <i className="bi bi-telephone-fill pe-2"></i>{" "}
                                    Call
                                  </button>
                                </td>
                              </>
                              {/* ))} */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-4 mb-3">
            <div className="card card-box-agent-white">
              <div className="card-body">
                <div className="row align-items-center my-1">
                  <div className="col">
                    <h2 className="agent-card-subtitle">Call Disposition 
                        <OverlayTrigger placement="top" overlay={renderCommonTooltip({text: "Overview of call details and outcomes, enabling effective tracking and management of each call."})}>
                          <i className='bx bx-info-circle ms-1'></i>
                        </OverlayTrigger>
                    </h2>
                    <audio ref={callAudio} id="remote-audio" autoPlay></audio>
                  </div>
                </div> 
                <div className="row align-items-center mt-3">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Member ID</th>
                            <th scope="col">Member Name</th>
                            <th scope="col">Main Membership No</th>
                            <th scope="col">Enrollment Status</th>
                            <th scope="col">Call Duration</th>
                            <th scope="col">Call Status</th>
                            <th scope="col">Data Rating </th>
                            <th scope="col">Reason</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">Call Time</th>

                          </tr>
                        </thead>
                        <tbody>
                          {
                            dispositionList?.length === 0 ?
                              <tr>
                                <td>No Data Available</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                              </tr>
                              :
                              dispositionList?.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.memberId}</td>
                                  <td>{item.memberName}</td>
                                  <td>{item.mainMembershipNo}</td>
                                  <td>{item.enrollStatus}</td>
                                  <td>{item.callDuration}</td>
                                  <td>{item.callStatus}</td>
                                  <td>{item.dataRating || "-"}</td>
                                  <td>{item.reason || "-"}</td>
                                  <td>{item.remarks || "-"}</td>
                                  <td>{item.callDate || "-"}</td>
                                </tr>
                              ))
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="col-md-12 mt-4 mb-2">
            <div className="card card-box-agent-white">
              <div className="card-body">
                <div className="row align-items-center my-1">
                  <div className="col">
                    <h2 className="agent-card-subtitle">Incorrect Data
                        <OverlayTrigger placement="top" overlay={renderCommonTooltip({text: "Members marked incorrect due to data issues, such as policy termination, data inaccuracies or discrepancies."})}>
                          <i className='bx bx-info-circle ms-1'></i>
                        </OverlayTrigger>
                    </h2>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Member ID</th>
                            <th scope="col">Member Name</th>
                            <th scope="col">Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            incorrectDataList?.length === 0 ?
                              <tr>
                                <td>No Data Available</td>
                                <td></td>
                                <td></td>
                              </tr>
                              :
                              incorrectDataList?.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.membershipNo}</td>
                                  <td>{item.memberName}</td>
                                  <td>{item.date}</td>
                                </tr>
                              ))
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CallBackList reflect={reflect} setReflect={setReflect} tooltip={renderCommonTooltip} />
        </div>
      </div>




      <div className="modal fade" id="memberProfileDetails" tabindex="-1" aria-labelledby="memberProfileDetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog  modal-xl">
          <div className="modal-content ">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Add Member</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {/* <AddMember/> */}
            </div>

          </div>
        </div>
      </div>


      {/* main Call Modal --> */}
      <div
        className="modal fade"
        id="agentCallModal"
        tabindex="1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="agentCallModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          {singleUserDataList.map((data, index) => (
            <div className="modal-content" key={index}>
              {/* <div className="modal-header mb-2">
                        <h5 className="modal-subtitle mb-0">Call</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
              <div className="agent-call-box">
                <div className="row align-items-center">
                  <div className="col-3">
                    <h2 className="coach-card-title">{data?.memberName}</h2>
                    {/* <h3 className="coach-card-subtitle">
                      Policy Status: {data.enrollmentStatus}
                    </h3> */}
                  </div>
                  <div className="col">
                    <h3 className="coach-card-subtitle">
                      <i className="bi bi-card-list"></i> Program:{" "}
                      {data?.carePrograms}
                    </h3>


                  </div>
                  <div className="col-auto">
                    {softCallEnabled === "Y" ? (
                      <>
                        {
                          callStatus === "idle" ?
                            <button
                              onClick={() => handleButtonClick(data?.encUserId)}
                              disabled={callStatus === "connected"}
                              className='btn btn-dark coach-calling-btn mx-2'
                              alt="soft phone call"
                            >
                              <i className="bx bxs-phone"   ></i>
                            </button>
                            :
                            <button
                              className='btn btn-dark coach-calling-btn mx-2'
                              alt="soft phone call"
                            >
                              {displayCallStatus}
                            </button>
                        }
                      </>
                    ) : (
                      ""
                    )}
                    {phoneCallEnabled === "Y" ? (
                      <>
                        {isCallDisabled ? (
                          <button
                            type="button"
                            className="btn btn-dark coach-calling-btn mx-2"

                            onClick={() => popupInitiateCall()}

                          >
                            {/* <i className="bx">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/n4doj/djss4244g3.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i> */}
                            <i className="bx bxs-phone"   ></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-dark coach-calling-btn mx-2"

                            onClick={() => popupInitiateCall()}
                            disabled
                          >
                            {/* <i className="bx">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/n4doj/djss4244g3.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i> */}
                            <i className="bx bxs-phone"   ></i>
                          </button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-auto">
                    <div className="coach-call-btn-box">

                      {isMuteDisabled ? (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-btn mx-2"
                          onClick={() => popupMuteCall("Y")}
                        >
                          <i className="bi">
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                              style={{ height: "28px" }}
                              alt=""
                            />
                          </i>
                          Mute
                        </button>

                      ) : (
                        <>
                          {isUnMuteDisabled ? (
                            <button
                              type="button"
                              className="btn btn-dark coach-call-btn mx-2"
                              onClick={() => popupMuteCall("N")}
                            >
                              <i className="bi">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i>
                              UnMute
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-dark coach-call-btn mx-2"
                              onClick={() => popupMuteCall("Y")}
                              style={{ display: 'none' }}
                              disabled
                            >
                              <i className="bi">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i>
                              Mute
                            </button>
                          )}
                        </>
                      )}

                      {isHoldDisabled ? (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-btn mx-2"
                          onClick={() => popupHoldCall('user')}
                        >
                          <i className="bi">
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/f8q0b/msra7ofjna.png"
                              style={{ height: "28px" }}
                              alt=""
                            />
                          </i>{" "}
                          Hold
                        </button>
                      ) : (
                        <>
                          {isUnHoldDisabled ? (
                            <button
                              type="button"
                              className="btn btn-dark coach-call-btn mx-2"
                              onClick={() => popupUnHoldCall('user')}
                            >
                              <i className="bi">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/f8q0b/msra7ofjna.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i>
                              UnHold
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-dark coach-call-btn mx-2"
                              onClick={() => popupMuteCall("N")}
                              style={{ display: 'none' }}
                              disabled
                            >
                              <i className="bi">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/f8q0b/msra7ofjna.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i>
                              Hold
                            </button>
                          )}
                        </>

                      )}

                    </div>

                    <h3 className="coach-card-subtitle text-center">
                      <i className="bx bx-time-five"></i> Call Duration{" "}
                      {timer.hours}:{timer.minutes}:{timer.seconds}
                    </h3>
                    <div className="call-status-message">
                      <p className="mb-0">{callInfoMessaage}</p>
                    </div>

                    {softCallEnabled === "N" || softCallEnabled === "N" ? (
                      <div className="coach-call-btn-box">
                        <form onSubmit={handleSubmit}>
                          {/* <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />   */}
                          <div className="row">
                            <div className="col">
                              <select
                                className="form-select input-field-ui"
                                id="agentId"
                                name="adminId"
                                value={formData["adminId"]}
                                onChange={handleChange}
                                aria-label="Default select example"
                              >
                                <option value="">Select Name</option>
                                {agentData.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.fullName}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm call-transfer-btn"
                              >
                                Transfer Call
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm call-transfer-exit-btn"
                              >
                                Transfer Call & Exit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}



                  </div>
                  {(softCallEnabled === "Y" || softCallEnabled === "Y") && !isCallMergedWithCn ? (
                    <div className="col-auto">
                      {isDiconnectDisabled && callStatus === "in-progress" ? (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-close-btn"
                          onClick={() => popupDisconnectCall("agent")}
                        >
                          <i className="bx bxs-phone"   ></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-close-btn"
                          onClick={() => popupDisconnectCall("agent")}
                          style={{ display: 'none' }}
                          disabled
                        >
                          <i className="bx bxs-phone"   ></i>
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

              </div>
              {/* <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
              <div className="modal-body agent-call-box-1">
                <div className="row">
                  <div className="col-md-5">
                    <div className="agent-bg-box-grey">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <h5 className="user-card-title mb-1">
                            Member Information
                          </h5>
                        </div>


                        <div className="col-12">
                          <ul className="list-group list-group-flush" style={{ overflowY: "scroll", maxHeight: "400px" }}>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Member Name{" "}</h3>
                                <h3 className="user-card-subtitle col-7">: {data.memberName || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Member ID{" "}</h3>
                                <h3 className="user-card-subtitle col-7">: {data.memberId || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Main Membership No{" "}</h3>
                                <h3 className="user-card-subtitle col-7">: {data.mainMembershipNo || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Policy Member Type{" "}</h3>
                                <h3 className="user-card-subtitle col-7">: {data.memberType || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Mobile No{" "}</h3>
                                <h3 className="user-card-subtitle col-7">: {data.mobile || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Contract No</h3>
                                <h3 className="user-card-subtitle col-7">: {data.contractNo || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Contract Name</h3>
                                <h3 className="user-card-subtitle col-7">: {data.contractName || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Gender</h3>
                                <h3 className="user-card-subtitle col-7">: {data.gender || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">City</h3>
                                <h3 className="user-card-subtitle col-7">: {data.city}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Policy Start Date</h3>
                                <h3 className="user-card-subtitle col-7">: {data.policyStartDate || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Policy End Date</h3>
                                <h3 className="user-card-subtitle col-7">: {data.policyEndDate || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Date of Birth{" "}</h3>
                                <h3 className="user-card-subtitle col-7">: {data.dob || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Chronic Conditions</h3>
                                <h3 className="user-card-subtitle col-7">: {data.chronicConditions || "-"}</h3>
                              </div>
                            </li>
                          </ul>
                        </div>




                        {/* <div className="col-6">
                          <h3 className="user-card-subtitle">Member Name:{" "}</h3>
                          <h3 className="user-card-subtitle">Member ID:{" "} </h3>
                          <h3 className="user-card-subtitle">Mobile No:{" "}</h3>
                          <h3 className="user-card-subtitle">Contract No: </h3>
                          <h3 className="user-card-subtitle">Contract Name: </h3>
                          <h3 className="user-card-subtitle">Gender: </h3>
                          <h3 className="user-card-subtitle">City: </h3>
                          <h3 className="user-card-subtitle">Policy Start Date: </h3>
                          <h3 className="user-card-subtitle">Policy End Date: </h3>
                          <h3 className="user-card-subtitle"> Date of Birth:{" "}</h3>
                          <h3 className="user-card-subtitle">Chronic Conditions: </h3>
                        </div>
                        <div className="col-6">
                          <h3 className="user-card-subtitle"> {data.memberName || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.memberId || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.mobile || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.contractNo || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.contractName || "-"} </h3>
                          <h3 className="user-card-subtitle">{data.gender || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.city}</h3>
                          <h3 className="user-card-subtitle">{data.policyStartDate || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.policyEndDate || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.dob || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.chronicConditions || "-"}</h3>
                        </div> */}
                      </div>
                    </div>

                    <div className="agent-bg-box-grey mt-3">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <h5 className="col-form-label">
                            Alternate Mobile No
                          </h5>

                        </div>
                        <div className="col">
                          <input
                            placeholder="Alternate Mobile No"
                            className="form-control input-field-ui"
                            name="reason"
                            value={alternateMobileNo}
                            onChange={e => onAlternateMobileNoChange(e)}
                          />


                          {/* <button className="btn btn-dark coach-call-enroll-btn mt-3 w-100"
                            disabled= {requestStatus.isAlternateMobileNoLoading ? "disabled": ""}
                            onClick={e => saveAlternateMobileNo(e)}
                          >

                            {
                              requestStatus.isAlternateMobileNoLoading? "Saving": "Save"
                            }

                          </button> */}
                        </div>
                        <div className="col-12">
                          <div className="error message text-danger">
                            {
                              alternateMobileNoError?.length > 0 &&
                              <p className="mb-0 mt-1"> {alternateMobileNoError}</p>
                            }
                          </div>

                          {alternateMobileNoSubmissionMessage?.message?.length > 0 ? (
                            <div
                              className={
                                alternateMobileNoSubmissionMessage?.status
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {alternateMobileNoSubmissionMessage?.message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md px-0">
                    <div
                      className="agent-bg-box-grey"
                      style={{ overflowY: "scroll", maxHeight: "554px" }}
                    >
                      <div className="row align-items-center">
                        {/* <div className="col-12"> */}
                        {/* <h5 className="user-card-title mb-1">Actionable</h5> */}

                        {/* <button type="button" className="btn btn-primary btn-sm call-now-btn d-flex" data-bs-toggle="modal" data-bs-target="#agentCallModal" onClick={() => popupSingleUserDetailShow(data.userId,data.encMemberId)}></button> */}
                        {/* </div> */}
                        <div className="response-message">
                          {callReportSubmissionMessage?.message?.length > 0 ? (
                            <div
                              className={
                                callReportSubmissionMessage?.status
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {callReportSubmissionMessage?.message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-12 mb-2">
                          <label className="col-form-label">
                            Does the member want to enroll in the program?
                          </label><br />
                          <button
                            type="button"
                            ref={yesButton}
                            className={`btn btn-dark coach-call-enroll-btn me-3 ${callReport.doesMemberWantToEnroll === "yes" && "active"}`}
                            // disabled={callReport.doesMemberWantToEnroll === "no"?"disabled": "" || isCallReportEnabled ? "" : "disabled" || callReportActionCompleted? "disabled" : ""}
                            disabled={!isCallReportEnabled || callReportActionCompleted ? "disabled" : ""}
                            onClick={e => saveCallReport(e, "yes")}
                          >
                            Yes, Enroll
                          </button>
                          <button
                            type="button"
                            ref={noButon}
                            className={`btn btn-dark coach-call-enroll-btn me-3 ${callReport.doesMemberWantToEnroll === "no" && "active"}`}
                            // disabled={callReport.doesMemberWantToEnroll === "yes"? "disabled": "" || isCallReportEnabled ? "" : "disabled" 
                            //   || callReportActionCompleted? "disabled" : "" 
                            // }

                            disabled={isSlotAlreadyBooked || !isCallReportEnabled
                              || callReportActionCompleted ? "disabled" : ""
                            }
                            onClick={e => saveCallReport(e, "no")}
                          >No, Not Interested</button>

                          <button
                            type="button"
                            ref={noButon}
                            className={`btn btn-dark coach-call-enroll-btn  ${callReport.doesMemberWantToEnroll === "enroll_later" && "active"}`}
                            disabled={isSlotAlreadyBooked || !isCallReportEnabled
                              || callReportActionCompleted ? "disabled" : ""
                            }
                            onClick={e => saveCallReport(e, "enroll_later")}
                          >Enroll Later</button>


                          {callReportValidation["doesMemberWantToEnroll"] && (
                            <div className="text-danger">
                              {" "}
                              {callReportValidation["doesMemberWantToEnroll"]}
                            </div>
                          )}
                        </div>

                        {callReport.doesMemberWantToEnroll === "no" && (
                          <div className="col-12 mb-2">
                            <label className="col-form-label">
                              Reason for not enrolling
                            </label>
                            {/* <input
                              placeholder="Enter Reason For Not Enrolling"
                              className="form-control input-field-ui"
                              name="reason"
                              value={callReport.reason}
                              onChange={onCallReportFormChange}
                            /> */}
                            <textarea
                              placeholder="Enter Reason For Not Enrolling"
                              className="form-control input-field-ui"
                              name="reason"
                              value={callReport.reason}
                              onChange={onCallReportFormChange}
                              rows="3"
                              disabled={callReportActionCompleted ? 'disabled' : ""}
                            ></textarea>
                            {callReportValidation["reason"] && (
                              <div className="text-danger">
                                {callReportValidation["reason"]}
                              </div>
                            )}

                            <div className="col-12 mt-3">
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                style={{ width: "100%" }}
                                onClick={() => rejectEnrollment()}
                                disabled={requestStatus.isRejectEnrollmentLoading ? "disabled" : "" || callReportActionCompleted ? "disabled" : ""}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        )}

                        {callReport.doesMemberWantToEnroll === "yes" && (
                          <>
                            <div className="col-12">
                              <hr />
                            </div>
                            <div className="yes_reponse">
                              <div className="col-12 mb-3">
                                <label className="col-form-label">
                                  Book Care Navigator or Transfer call to Care Navigator
                                </label><br />
                                <button type="button" className={`btn btn-dark bookAppointment-btn ${callAction === 'schedule' ? 'active' : ''}`}
                                  onClick={e => performCalAction(e, "schedule")}
                                  disabled={callReportActionCompleted ? "disabled" : ""}
                                >
                                  Book Appointment
                                </button>
                                <label className="col-form-label px-3">
                                  Or
                                </label>
                                <button
                                  type="button"
                                  className={`btn btn-dark bookAppointment-btn ${callAction === 'transfer' ? 'active' : ''}`}
                                  onClick={e => performCalAction(e, "transfer")}
                                  disabled={callStatus === "idle" || callReportActionCompleted || isSlotAlreadyBooked ? "disabled" : ""}
                                >
                                  Transfer Now
                                </button>
                              </div>
                              <div className="col-12">
                                {/* <div>
                                  <h2 className="user-card-text mb-2">
                                    Book Care Navigator Consultation
                                  </h2>
                                </div> */}
                                {/* {careNavigatorId ? (
                                  <>
                                    <div className="col-12">
                                      <label
                                        for="inputdate"
                                        className="col-form-label"
                                      >
                                        Select Appointment Date & Timeeeee
                                      </label>
                                      {careNavigatorName}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              {
                                callAction === "schedule"
                                &&
                                <div className="row">

                                  <div className="col ps-1">
                                    <DatePicker
                                      selected={slotBookingFormData.date}
                                      onChange={updatedDate => setSlotBookingFormData({ ...slotBookingFormData, date: updatedDate })}
                                      showTimeSelect
                                      timeIntervals={15}
                                      maxDate={addDaystoDate(new Date(), cnBookingSlotLimit)}
                                      minTime={slotBookingFormData.date > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                      maxTime={new Date().setHours(23, 59, 59)}
                                      timeInputLabel="Time:"
                                      dateFormat="dd/MM/yyyy h:mm aa"
                                      placeholderText="Select Date and Time"
                                      className='form-control control-input-ui'
                                      disabled={callReportActionCompleted ? "disabled" : ""}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />

                                    {slotBookingFormDataErrors["date"] && (
                                      <div className="text-danger">
                                        {slotBookingFormDataErrors["date"]}
                                      </div>
                                    )}




                                    {slotsSearchSubmissionMessage?.message?.length >
                                      0 ? (
                                      <div
                                        className={
                                          slotsSearchSubmissionMessage.status
                                            ? "text-success"
                                            : "text-danger"
                                        }
                                      >
                                        {slotsSearchSubmissionMessage.message}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-auto ps-0">
                                    <button
                                      type="button"
                                      className="btn btn-dark coach-call-enroll-btn"
                                      onClick={() => popupCareNavigator()}
                                      disabled={callReportActionCompleted || requestStatus.isSearchLoading ? "disabled" : ""}
                                    >
                                      Search
                                    </button>


                                  </div>
                                  <div className="col-12 mt-1">
                                    {!slots || slots.length === 0 ? (
                                      <div>
                                        {areSlotsFetched ? (
                                          <h5 className="user-card-title mt-3">No Slots Available</h5>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <div className="col-md-12">
                                        <label className="col-form-label">
                                          Slots Available
                                        </label>
                                        <div className="d-flex flex-wrap" style={{ justifyContent: "space-between !important" }}>
                                          {slots &&
                                            slots.map(
                                              ({
                                                carenavigatorId,
                                                slots: agentSlots,
                                              }) => (
                                                <div className="time_slot-d-flex" key={carenavigatorId}>
                                                  {agentSlots && agentSlots.map((data) => (
                                                    <div
                                                      className={`col-auto mb-2 time_slot_book ${data === selectedSlot
                                                          ? "active"
                                                          : ""
                                                        }`}
                                                      onClick={() => {
                                                        setSelectedSlot(data);
                                                        setSelectedCareNavigator(
                                                          carenavigatorId
                                                        );
                                                        setSlotBookingFormDataErrors(
                                                          {
                                                            ...slotBookingFormDataErrors,
                                                            selectedSlot: "",
                                                          }
                                                        );
                                                      }}
                                                      key={data}
                                                    >
                                                      {data}
                                                    </div>
                                                  ))}
                                                </div>
                                              )
                                            )}
                                        </div>
                                        {slotBookingFormDataErrors[
                                          "selectedSlot"
                                        ] && (
                                            <div className="text-danger">
                                              {" "}
                                              {
                                                slotBookingFormDataErrors[
                                                "selectedSlot"
                                                ]
                                              }
                                            </div>
                                          )}

                                        {slotBookingSubmissionMessage?.message?.length > 0 ? (
                                          <div
                                            className={
                                              slotBookingSubmissionMessage.status
                                                ? "text-success"
                                                : "text-danger"
                                            }
                                          >
                                            {slotBookingSubmissionMessage.message}
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        <div className="text-center">
                                          <button
                                            type="button"
                                            className="btn btn-dark coach-call-enroll-btn me-2 mt-3"
                                            onClick={(e) => bookSlot()}
                                            disabled={requestStatus.isBookingLoading ? "disabled" : ""}
                                          >
                                            Book
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              }

                              {

                                callAction === "transfer" &&
                                <div className="col-12 mt-3">
                                  {callTransferSubmissionMessage?.message?.length >
                                    0 ? (
                                    <div
                                      className={
                                        callTransferSubmissionMessage.status ===
                                          "success"
                                          ? "text-success"
                                          : "text-danger"
                                      }
                                    >
                                      {callTransferSubmissionMessage.message}
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="row align-items-start">
                                    <div className="col-5">
                                      <select
                                        className="form-select input-field-ui"
                                        id="agentId"
                                        name="careNavigatorId"
                                        value={callTransferForm.careNavigatorId}
                                        onChange={handleChange}
                                        aria-label="Default select example"
                                        disabled={callStatus === "idle" || callReportActionCompleted || isSlotAlreadyBooked ? "disabled" : ""}
                                      >
                                        <option value="">Select CareNavigator</option>
                                        {agentData.map((option) => (
                                          <option key={option.id} value={option.id}>
                                            {option.fullName}
                                          </option>
                                        ))}
                                      </select>
                                      {callTransferFormErrors["careNavigatorId"] && (
                                        <div className="text-danger">
                                          {" "}
                                          {callTransferFormErrors["careNavigatorId"]}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col ps-0 d-flex" style={{ alignItems: "center" }}>
                                      <div className="input-field-ui py-1" style={{ width: "auto" }}>
                                        {
                                          careNavigatorTransferStatus.callStatus === "idle" ?

                                            <button type="button" className="btn" onClick={e => transferCallToCareNavigator()}
                                              disabled={callReportActionCompleted || isSlotAlreadyBooked || callStatus === "idle" || requestStatus.isTransferCallLoading ? 'disabled' : ""}
                                            >
                                              {
                                                requestStatus.isTransferCallLoading ? "Dialing" : "Dial"
                                              }
                                            </button> :
                                            <button type="button" className="btn" onClick={e => transferCallToCareNavigator()} disabled>
                                              {careNavigatorTransferStatus.callDisplayStatus}
                                            </button>
                                        }

                                        {
                                          careNavigatorTransferStatus.callStatus === "in-progress" &&
                                          <>
                                            <button
                                              type="button"
                                              className="btn"
                                              onClick={e => mergeCallWithCareNavigator(e)}
                                            >
                                              <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/b43sh/j4thc4t2h3.png" style={{ height: "20px" }} alt="" />
                                            </button>
                                            {
                                              !cnHold ?
                                                <button
                                                  type="button"
                                                  className="btn"
                                                  onClick={e => popupHoldCall("cn")}
                                                >
                                                  <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/whkuh/7jghcsm4d2.png" style={{ height: "20px" }} alt="" />
                                                </button> :

                                                <button
                                                  type="button"
                                                  className="btn"
                                                  onClick={e => popupUnHoldCall("cn")}
                                                >
                                                  <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/whkuh/7jghcsm4d2.png" style={{ height: "20px" }} alt="" />
                                                  unhold
                                                </button>
                                            }


                                          </>
                                        }


                                        {
                                          careNavigatorTransferStatus.callStatus !== "idle" && !isCallMergedWithCn &&
                                          <button
                                            type="button"
                                            className="btn"
                                            onClick={e => dropAgentFromCall(e, "cn", careNavigatorId)}
                                          >
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3a84c/b47h3jwh48.png" style={{ height: "22px" }} alt="" />
                                          </button>
                                        }
                                      </div>
                                      {
                                        isCallMergedWithCn &&
                                        <button
                                          type="button"
                                          className="btn coach-call-exit-btn ms-4"
                                          onClick={e => dropAgentFromCall(e, "agent", careNavigatorId)}
                                        >
                                          Exit Call
                                        </button>
                                      }
                                    </div>
                                  </div>
                                </div>
                              }
                              {/* <hr /> */}
                            </div>
                          </>
                        )}

                        {callReport.doesMemberWantToEnroll === "enroll_later" && (
                          <div className="col-12 mb-2 mt-5">
                            <div className="tw-flex tw-justify-between tw-items-center tw-gap-4 tw-w-full">
                              <DatePicker
                                selected={enrollLaterForm.date}
                                onChange={(updatedDate) => {
                                  setEnrollLaterFormErrors({ ...enrollLaterFormErrors, date: "" })
                                  setEnrollLaterForm({ date: updatedDate }
                                  )
                                }
                                }

                                showTimeSelect
                                timeIntervals={15}
                                minTime={enrollLaterForm.date > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                maxTime={new Date().setHours(23, 59, 59)}
                                timeInputLabel="Time:"
                                dateFormat="dd/MM/yyyy h:mm aa"
                                placeholderText="Select Date and Time"
                                className='form-control control-input-ui me-3'
                                disabled={callReportActionCompleted ? "disabled" : ""}
                              />

                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                onClick={() => registerLaterEnrollment()}
                                disabled={requestStatus.isEnrollLaterLoading ? "disabled" : "" || callReportActionCompleted ? "disabled" : ""}
                              >

                                Register
                              </button>
                            </div>
                            {enrollLaterFormErrors["date"] && (
                              <div className="text-danger">
                                {enrollLaterFormErrors["date"]}
                              </div>
                            )}
                          </div>
                        )}



                        {callReport.doesMemberWantToEnroll === "" ? (
                          <>
                            <div className="mt-3">


                              {callBackSubmissionMessage?.message?.length >
                                0 ? (
                                <div className="col-12">
                                  <div className="form-check-inline">

                                    <div className="response-message">
                                      <div
                                        className={
                                          callBackSubmissionMessage.status
                                            ? "text-success"
                                            : "text-danger"
                                        }
                                      >
                                        {callBackSubmissionMessage.message}
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}


                              <div className="row">
                                <div className="col-12">
                                  <label
                                    className="col-form-label"
                                  >
                                    Call Back Request
                                  </label>
                                </div>
                                <div className="col-12">

                                  {/* <input
                                    type="date"
                                    id="inputdate"
                                    name="date"
                                    value={callbackForm.date}
                                    onChange={(e) => callBackOnChange(e)}
                                    className="form-control input-field-ui"
                                    
                                  /> */}

                                  <DatePicker
                                    selected={callbackForm.date}
                                    onChange={updatedDate => setCallbackForm({ ...callbackForm, date: updatedDate })}
                                    showTimeSelect
                                    timeIntervals={15}
                                    minTime={callbackForm.date > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                    maxTime={new Date().setHours(23, 59, 59)}
                                    timeInputLabel="Time:"
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    placeholderText="Select Date and Time"
                                    className='form-control input-field-ui'
                                    disabled={callReportActionCompleted ? "disabled" : ""}
                                    readOnly
                                  />


                                  {callbackFormErrors["date"] && (
                                    <div className="text-danger">
                                      {callbackFormErrors["date"]}
                                    </div>
                                  )}
                                </div>
                                {/* <div className="col-6">

                                  <input
                                    type="time"
                                    id="inputtime"
                                    name="time"
                                    value={callbackForm.time}
                                    onChange={(e) => callBackOnChange(e)}
                                    className="form-control input-field-ui"
                                  />
                                  {callbackFormErrors["time"] && (
                                    <div className="text-danger">
                                      {callbackFormErrors["time"]}
                                    </div>
                                  )}
                                </div> */}
                              </div>
                            </div>

                            <div className="col-12 mt-3">
                              <h2 className="user-card-text mb-2">
                                Notes/Comments (Internal)
                              </h2>
                            </div>
                            <div className="col-12">
                              <textarea
                                className="form-control input-field-ui"
                                id="yourSelf"
                                placeholder="Take Notes"
                                rows="5F"
                                name="notes"
                                value={callbackForm.notes}
                                onChange={(e) => callBackOnChange(e)}
                              ></textarea>
                              {callbackFormErrors["notes"] && (
                                <div className="text-danger">
                                  {callbackFormErrors["notes"]}
                                </div>
                              )}
                            </div>

                            <div className="mt-3 text-center">
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                onClick={() => popupCallBackPlan()}
                                disabled={requestStatus.isCallbackLoading ? "disabled" : ""}
                              >
                                Schedule A Call Back
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}



                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      {
                        callReport.doesMemberWantToEnroll !== "yes" &&
                        <button
                          className="btn btn-dark coach-call-enroll-btn text-center"
                          onClick={e => markDataAsIncorrect()}
                          disabled={requestStatus.isIncorrectDataLoading && "disbaled"}
                        >
                          Incorrect Data
                        </button>
                      }
                    </div>
                  </div>



                  <div className="col-md-1">
                    <div className="">
                      <div className="row">
                        {/* <div className="col-12">
                          <h5 className="user-card-title mb-1">
                            Notes/Comments (ext)
                          </h5>
                        </div> */}
                        <div
                          className="col"
                        // style={{ position: "relative" }}
                        >
                          {/* <textarea
                            className="form-control input-field-ui"
                            id="yourSelf"
                            placeholder="Take Notes"
                            rows="17"
                          ></textarea> */}
                          <div className="agent-bg-box-grey text-center pb-2"
                            style={{ cursor: "pointer" }}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightEng"
                            aria-controls="offcanvasRightEng"
                          >
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/njqhv/3ushhs30d3.png"

                              className="img-fluid"
                              alt=""
                            />
                            <label style={{ fontSize: "12px" }}>English</label>
                          </div>

                          <div className="agent-bg-box-grey text-center pb-2 mt-2"
                            style={{ cursor: "pointer" }}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightArb"
                            aria-controls="offcanvasRightArb"
                          >
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/njqhv/3ushhs30d3.png"

                              className="img-fluid"
                              alt=""
                            />
                            <label style={{ fontSize: "12px" }}>Arabic</label>
                          </div>



                          <div
                            className="offcanvas offcanvas-end box-script-ui"
                            // style={{width:"30%"}}
                            tabindex="-1"
                            id="offcanvasRightEng"
                            aria-labelledby="offcanvasRightEngLabel"
                            data-bs-backdrop="static"
                          >
                            <div className="offcanvas-header pt-2 pb-1">
                              {/* <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                            </div>
                            <div className="offcanvas-body py-0">
                              <div className="row">
                                <textarea className="col mb-3" rows="22" style={{ textAlign: "justify", border: "0", backgroundColor: 'transparent' }} disabled>
                                  {scriptData[0]?.script}
                                </textarea>
                                <div className="col-auto">
                                  <button
                                    type="button"
                                    className="btn btn-dark coach-call-enroll-btn"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>

                            </div>
                            {/* <div className="offcanvas-footer px-3 py-3 pt-2">
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Close
                              </button>
                            </div> */}
                          </div>


                          <div
                            className="offcanvas offcanvas-end box-script-ui"
                            // style={{width:"30%"}}
                            tabindex="-1"
                            id="offcanvasRightArb"
                            aria-labelledby="offcanvasRightArbLabel"
                            data-bs-backdrop="static"
                          >
                            <div className="offcanvas-header pt-2 pb-1 text-end" style={{ justifyContent: "flex-end" }}>

                              {/* <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                            </div>
                            <div className="offcanvas-body py-0">
                              <div className="row">
                                <textarea className="col mb-3" rows="22" style={{ textAlign: "right", border: "0", backgroundColor: 'transparent' }} disabled>
                                  {arabicScriptData[0]?.script}
                                </textarea>
                                <div className="col-auto">
                                  <button
                                    type="button"
                                    className="btn btn-dark coach-call-enroll-btn"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                              {/* <div className=" text-end mb-3">
                              <textarea> 
                                {arabicScriptData[0]?.script}
                              </textarea>
                              </div> */}
                            </div>
                            {/* <div className="offcanvas-footer text-end px-3 py-3 pt-2">
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Close
                              </button>
                            </div> */}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer" style={{ justifyContent: "center" }}>
                <button
                  ref={closeModal}
                  type="button"
                  className="btn btn-secondary clear-btn"
                  data-bs-dismiss="modal"
                  disabled={callStatus !== "idle" && "disbaled"}
                  onClick={e => callPopupClose(e)}
                >
                  Close
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Mocked call Modal */}
      <div
        className="modal fade"
        id="agentCallModal3"
        tabindex="1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="agentCallModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content" >
            {/* <div className="modal-header mb-2">
                        <h5 className="modal-subtitle mb-0">Call</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="agent-call-box">
              <div className="row align-items-center">
                <div className="col-4 d-flex align-items-center">
                  <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/81n4z/zjbjfj88hl.png" style={{ height: "30px", verticalAlign: "baseline" }} alt="" /></i>
                  <h2 className="coach-card-title ms-2">
                    {singleUserDataList.length > 0 && singleUserDataList[0].memberName}<br />
                    {/* <span className="coach-card-subtitle">HOF</span> */}
                  </h2>
                  {/* <h3 className="coach-card-subtitle">
                      Policy Status: {data.enrollmentStatus}
                    </h3> */}
                </div>
                <div className="col">


                  {
                    singleUserData.isFamily &&

                    <h3 className="coach-card-subtitle">
                      <i className=""> <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/sl3hn/jh81c0h9x0.png" style={{ height: "15px", verticalAlign: "baseline" }} alt="" /></i> Family:{" "}
                      <span style={{ color: "#32C100", fontWeight: "500" }}>{singleUserDataList.length} Members</span>
                    </h3>
                  }


                  {/* <h3 className="coach-card-subtitle">
                      <i className="bi bi-card-list"></i> Program:{" "}
                      {getFamilyProgramsList()  }
                    </h3> */}

                </div>
                <div className="col-auto">
                  {softCallEnabled === "Y" ? (
                    <>
                      { 
                        callStatus === "idle" ?
                          <button
                            onClick={() => handleMockedCall(singleUserData.encUserId)}
                            disabled={callStatus === "connected"}
                            className='btn btn-dark coach-calling-btn mx-2'
                            alt="soft phone call"
                          >
                            <i className="bx bxs-phone"   ></i>
                          </button>
                          :
                          (
                            (callingAgent === "webex") ?
                              (
                                <button
                                  type="button"
                                  className="text-white"
                                  style={{
                                    fontSize: "19px",
                                    fontWeight: "400",
                                    marginRight: "20px",
                                    backgroundColor: "transparent",
                                    border: "1px solid #eee",
                                    borderRadius: "10px"
                                  }}
                                >
                                  {displayCallStatus}
                                </button>
                              ) : (
                                <div>
                                  { 
                                    requestStatus.isCallPlacingRequestLoading && 
                                    <button
                                      type="button"
                                      className="btn btn-dark coach-call-close-btn"
                                      onClick={() => popupMockDisconnectCall("agent")} 
                                      disabled={requestStatus.isCallPlacingRequestLoading? 'disabled': '' }
                                    >
                                      <i className="bx bxs-phone"></i> 
                                    </button>
                                  }

                                  { 
                                    !requestStatus.isCallPlacingRequestLoading && 
                                    <button
                                      type="button"
                                      className="btn btn-dark coach-call-close-btn"
                                      onClick={() => popupMockDisconnectCall("agent")} 
                                    >
                                      <i className="bx bxs-phone"></i> 
                                    </button>
                                  }
                                </div>
                              )
                          )
                      }
                    </>
                  ) : (
                    ""
                  )}
                  {phoneCallEnabled === "Y" ? (
                    <>
                      {isCallDisabled ? (
                        <button
                          type="button"
                          className="btn btn-dark coach-calling-btn mx-2"

                          onClick={() => popupInitiateCall()}

                        >
                          {/* <i className="bx">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/n4doj/djss4244g3.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i> */}
                          <i className="bx bxs-phone"   ></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-dark coach-calling-btn mx-2"

                          onClick={() => popupInitiateCall()}
                          disabled
                        >
                          {/* <i className="bx">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/n4doj/djss4244g3.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i> */}
                          <i className="bx bxs-phone"   ></i>
                        </button>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-auto">
                  <div className="coach-call-btn-box">

                    {isMuteDisabled ? (
                      <button
                        type="button"
                        className="btn btn-dark coach-call-btn mx-2"
                        onClick={() => popupMuteCall("Y")}
                      >
                        <i className="bi">
                          <img
                            src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                            style={{ height: "28px" }}
                            alt=""
                          />
                        </i>
                        Mute
                      </button>

                    ) : (
                      <>
                        {isUnMuteDisabled && callStatus === "in-progress" ? (
                          <button
                            type="button"
                            className="btn btn-dark coach-call-btn mx-2"
                            onClick={() => popupMuteCall("N")}
                          >
                            <i className="bi">
                              <img
                                src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                                style={{ height: "28px" }}
                                alt=""
                              />
                            </i>
                            UnMute
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-dark coach-call-btn mx-2"
                            onClick={() => popupMuteCall("Y")}
                            style={{ display: 'none' }}
                            disabled
                          >
                            <i className="bi">
                              <img
                                src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                                style={{ height: "28px" }}
                                alt=""
                              />
                            </i>
                            Mute
                          </button>
                        )}
                      </>
                    )}

                    {
                      callingAgent === "webex" && callStatus === "in-progress" &&
                      (isHoldDisabled ? (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-btn mx-2"
                          onClick={() => popupHoldCall('user')}
                        >
                          <i className="bi">
                            <img
                              src="https://appcdn.goqii.com/storeimg/21247_1720158097.png"
                              style={{ height: "28px" }}
                              alt=""
                            />
                          </i>{" "}
                          Hold
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-dark coach-call-btn mx-2"
                            onClick={() => popupHoldCall('user')}
                          >
                            <i className="bi">
                              <img
                                src="https://appcdn.goqii.com/storeimg/21247_1720158097.png"
                                style={{ height: "28px" }}
                                alt=""
                              />
                            </i>
                            UnHold
                          </button>

                        </>

                      ))
                    }


                    {
                      (callingAgent === "webex" && callStatus !== "idle" && webexCall) &&
                      <button
                        type="button"
                        className="btn btn-dark coach-call-close-btn"
                        onClick={() => popupMockDisconnectCall("agent")}
                      >
                        <i className="bx bxs-phone"></i>
                      </button>
                    }

                  </div>

                  <h3 className="coach-card-subtitle text-center mt-1">
                    <i className="bx bx-time-five"></i> Call Duration{" "}
                    {timer.hours}:{timer.minutes}:{timer.seconds}
                  </h3>
                  <div className="call-status-message">
                    <p className="mb-0">{callInfoMessaage}</p>
                  </div>

                  {softCallEnabled === "N" || softCallEnabled === "N" ? (
                    <div className="coach-call-btn-box">
                      <form onSubmit={handleSubmit}>
                        {/* <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />   */}
                        <div className="row">
                          <div className="col">
                            <select
                              className="form-select input-field-ui"
                              id="agentId"
                              name="adminId"
                              value={formData["adminId"]}
                              onChange={handleChange}
                              aria-label="Default select example"
                            >
                              <option value="">Select Name</option>
                              {agentData.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.fullName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col">
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm call-transfer-btn"
                            >
                              Transfer Call
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm call-transfer-exit-btn"
                            >
                              Transfer Call & Exit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    ""
                  )}



                </div>
                {(softCallEnabled === "Y" || softCallEnabled === "Y") && !isCallMergedWithCn ? (
                  <div className="col-auto">
                    {isDiconnectDisabled && callStatus === "in-progress" ? (
                      <button
                        type="button"
                        className="btn btn-dark coach-call-close-btn"
                        onClick={() => popupDisconnectCall("agent")}
                      >
                        <i className="bx bxs-phone"   ></i>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-dark coach-call-close-btn"
                        onClick={() => popupDisconnectCall("agent")}
                        style={{ display: 'none' }}
                        disabled
                      >
                        <i className="bx bxs-phone"   ></i>
                      </button>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>

            </div>
            {/* <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
            <div className="modal-body agent-call-box-1">
              <div className="row">
                <div className="col-md-5">
                  <div className="agent-bg-box-grey">
                    <div className="row align-items-center">
                      <div className="col-12 d-flex tw-justify-between mb-2">
                        <h5 className="user-card-title mb-2">
                          Member Information
                        </h5>

                        {
                          isAddMemberAllowedInCalingPopup && 
                          <button className="btn call-now-btn" id="addMemberButton" onClick={e => handleShowMemberProfileDetailsModal("in")}><i className="bi bi-person-plus-fill"></i> Add Member </button>
                        }
                      </div>
                      <div className="col-12" style={{ overflowY: "scroll", maxHeight: "430px" }}>

                        <div className="accordion accordion-flush" id="FamilyMemberTab">

                          {
                            singleUserDataList.map((data, ind) => (
                              <div className="accordion-item" key={ind} area-expanded="true">
                                <h2 className="accordion-header" id="HOFFamilyMember1">
                                  <button className={`accordion-button ${data.actionTaken == true ? 'accordion-button-grey' : ''}
                                      ${data.memberId === singleUserData.memberId ? '' : 'collapsed'}                                    
                                    `} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${ind + 1}`} aria-controls={`collapse${ind + 1}`} aria-expanded="true"
                                    onClick={e => {
                                      setSingleUserData(data);
                                    }}
                                    disabled={familyCallReportActionCompleted[singleUserData.memberId] && singleUserData.enrollmentStatus === "inactive"
                                      && familyCallReportAction[singleUserData.memberId] !== 'call_back'
                                      ? "disabled" : ""}
                                  >
                                    <div className="">
                                      {singleUserData.hof == 'Y' && "HOF"} {data.memberName}<br />
                                      <span style={{ fontSize: "14px", fontWeight: "500" }}>{data.memberType}</span>
                                    </div>

                                  </button>
                                </h2>
                                <div id={`collapse${ind + 1}`} className={`accordion-collapse collapse  ${data.memberId === singleUserData.memberId && 'show'}`} aria-labelledby="HOFFamilyMember1" data-bs-parent="#FamilyMemberTab">
                                  <div className="accordion-body p-0 ps-2" style={{ background: "#f5f5f5" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <ul className="list-group list-group-flush" style={{ overflowY: "scroll", maxHeight: "270px" }}>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Member Name{" "}</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.memberName || "-"}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Member ID{" "}</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.memberId || "-"}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Main Membership No{" "}</h3>
                                              {/* <h3 className="user-card-subtitle col-7">: {data.mainMembershipNo || "-"}</h3> */}
                                              {
                                                memberInfoMode.mainMembershipNo === "edit" ?

                                                  <div className="w-50 d-flex align-items-starts">
                                                    <div>
                                                      <input ref={mobileEditInput} type="text"
                                                        placeholder="Main Membership No" name="mainMembershipNo" className="form-control service-action ms-1 editable-mobile-no"
                                                        value={data.mainMembershipNo}
                                                        onChange={e => onMemberInfoChange(e)}
                                                      />
                                                      <p style={{ fontSize: '10px', color: 'red' }}>
                                                        {memberInfoChangeErrors[singleUserData.memberId]?.mainMembershipNo?.length > 0 ?
                                                          memberInfoChangeErrors[singleUserData.memberId]?.mainMembershipNo : ' '
                                                        }</p>
                                                    </div>
                                                    <span className="mt-1" onClick={e => {
                                                      saveMainMembershipNo();
                                                    }} style={{ cursor: "pointer" }}><i className="bi bi-check-circle-fill ms-2"></i></span>

                                                    <span className="mt-1" onClick={e => {
                                                      setMemberInfoChangeErrors({
                                                        ...memberInfoChangeErrors,
                                                        [singleUserData.memberId]: {
                                                          ...memberInfoChangeErrors[singleUserData.memberId],
                                                          mainMembershipNo: ""
                                                        }
                                                      });

                                                      setSingleUserDataList((previous) => {
                                                        return previous.map((item) => {
                                                          if (item.memberId === singleUserData.memberId) {
                                                            item['mainMembershipNo'] = singleUserData['copymainMembershipNo']
                                                            return item;
                                                          }

                                                          return item;
                                                        })
                                                      });

                                                      setSingleUserData({
                                                        ...singleUserData,
                                                        mainMembershipNo: singleUserData.copymainMembershipNo
                                                      });
                                                      chagneMemberInfoFieldMode(e, "mainMembershipNo", "display");
                                                    }} style={{ cursor: "pointer" }}><i className="bi bi-x-circle-fill ms-2"></i></span>
                                                  </div>
                                                  :
                                                  <>
                                                    <h3 className="user-card-subtitle col-7">:
                                                      {data.mainMembershipNo || "-"} &nbsp; &nbsp;
                                                      <span onClick={e => chagneMemberInfoFieldMode(e, "mainMembershipNo", "edit")} style={{ cursor: "pointer" }}><i className="bi bi-pencil-square me-2"></i></span>
                                                    </h3>
                                                  </>

                                              }
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Policy Member Type{" "}</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.memberType || "-"}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Mobile No{" "}</h3>

                                              {
                                                memberInfoMode.mobileNo === "edit" ?
                                                  <div className="w-50 d-flex align-items-start">
                                                    <div>

                                                      <input ref={mobileEditInput} type="text" placeholder="Mobile No" name="mobile" className="form-control service-action  ms-1 editable-mobile-no"
                                                        value={data.mobile}
                                                        onChange={e => onMemberInfoChange(e)}
                                                      />

                                                      <p style={{ fontSize: '10px', color: 'red' }}>
                                                        {
                                                          memberInfoChangeErrors[singleUserData.memberId]?.mobileNo?.length > 0 ?
                                                            memberInfoChangeErrors[singleUserData.memberId]?.mobileNo : ' '
                                                        }
                                                      </p>
                                                    </div>
                                                    <span className="mt-1" onClick={e =>
                                                      saveMobileNo(e, singleUserData.mobile, "main")
                                                    } style={{ cursor: "pointer" }}><i className="bi bi-check-circle-fill ms-2"></i></span>

                                                    <span className="mt-1" onClick={e => {
                                                      setMemberInfoChangeErrors({
                                                        ...memberInfoChangeErrors,
                                                        [singleUserData.memberId]: {
                                                          ...memberInfoChangeErrors[singleUserData.memberId],
                                                          mobileNo: ""
                                                        }
                                                      });

                                                      setSingleUserDataList((previous) => {
                                                        return previous.map((item) => {
                                                          if (item.memberId === singleUserData.memberId) {
                                                            item['mobile'] = singleUserData['copymobile']
                                                            return item;
                                                          }

                                                          return item;
                                                        })
                                                      });

                                                      setSingleUserData({
                                                        ...singleUserData,
                                                        mobile: singleUserData.copymobile
                                                      });
                                                      chagneMemberInfoFieldMode(e, "mobileNo", "display");
                                                    }} style={{ cursor: "pointer" }}><i className="bi bi-x-circle-fill ms-2"></i></span>

                                                  </div> :
                                                  <>
                                                    <h3 className="user-card-subtitle col-7"
                                                    >: {data.mobile || "-"} &nbsp; &nbsp;
                                                      <span onClick={e => chagneMemberInfoFieldMode(e, "mobileNo", "edit")} style={{ cursor: "pointer" }}><i className="bi bi-pencil-square me-2"></i></span>
                                                    </h3>
                                                  </>
                                              }

                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="" style={{ display: 'flex' }}>
                                              <h3 className="user-card-subtitle col-5">Alternate Mobile No{" "}</h3>
                                              <div>
                                                <input placeholder="Alternate Mobile No" name="altMobile" className="form-control service-action  ms-1 editable-mobile-no" value={data.altMobile}
                                                  onChange={e => onMemberInfoChange(e)}
                                                />
                                                {/* <button type="button" className="btn btn-primary btn-sm call-now-btn d-flex" onClick={() => updateMobileNoMember(data.  userId,'','alt')}>Update</button> */}
                                              </div>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Contract No</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.contractNo || "-"}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Contract Name</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.contractName || "-"}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Gender</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.gender || "-"}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">City</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.city}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Policy Start Date</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.policyStartDate || "-"}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Policy End Date</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.policyEndDate || "-"}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Date of Birth{" "}</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.dob || "-"}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item bg-transparent ps-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-5">Chronic Conditions</h3>
                                              <h3 className="user-card-subtitle col-7">: {data.chronicConditions || "-"}
                                                &nbsp; &nbsp;
                                                {
                                                  singleUserData.enrollmentStatus === 'inactive' &&
                                                  <span style={{ cursor: "pointer" }}
                                                    data-bs-target="#conditionsupdateModal"
                                                    data-bs-toggle="offcanvas"
                                                  ><i className="bi bi-pencil-square me-2"></i></span>
                                                }
                                              </h3>
                                            </div>
                                          </li>
                                          {isModalOpen && (

                                            <CommunicationModal
                                              // isOpen={isModalOpen}
                                              onClose={() => setIsModalOpen(false)}
                                              selectedChannel={connectType}
                                              memberName={singleUserData.memberName}
                                              userId={singleUserData.encUserId}
                                              communicationOptions={communicationOptions}
                                              memberMobileNo={singleUserData.mobile}
                                            />
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              // <div className="accordion-item">
                              //   <h2 className="accordion-header" id="FamilyMember1">
                              //     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                              //       Family Member 1
                              //     </button>
                              //   </h2>
                              //   <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="FamilyMember1" data-bs-parent="#FamilyMemberTab">
                              //   <div className="accordion-body p-0 ps-2" style={{background:"#f5f5f5"}}>
                              //     <div className="row">
                              //       <div className="col-12">
                              //         <ul className="list-group list-group-flush" style={{overflowY:"scroll", maxHeight:"350px"}}>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">Member Name{" "}</h3>
                              //               <h3 className="user-card-subtitle col-7">: {singleUserData.memberName || "-"}</h3>
                              //             </div>
                              //           </li>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">Member ID{" "}</h3>
                              //               <h3 className="user-card-subtitle col-7">: {singleUserData.memberId || "-"}</h3>
                              //             </div>
                              //           </li>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">Mobile No{" "}</h3>
                              //               <h3 className="user-card-subtitle col-7 d-flex">: <input placeholder="Mobile No" className="form-control service-action  ms-1"  value=""/>
                              //               </h3>
                              //             </div>
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5"></h3>
                              //               <h3 className="user-card-subtitle col-7 mt-2">
                              //                 <button type="button" className="btn replicateFHN-btn">Replicate Family Head Number</button>
                              //               </h3>
                              //               <h3 className="user-card-subtitle col-12 mt-2" style={{fontSize:"11px"}}>
                              //               Note: Call will be received by the caregiver if no mobile number is provided.
                              //               </h3>
                              //             </div>
                              //           </li>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">Contract No</h3>
                              //               <h3 className="user-card-subtitle col-7">: {singleUserData.contractNo || "-"}</h3>
                              //             </div>
                              //           </li>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">Contract Name</h3>
                              //               <h3 className="user-card-subtitle col-7">: 
                              //                 {singleUserData.contractName || "-"}
                              //               </h3>

                              //             </div>
                              //           </li>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">Gender</h3>
                              //               <h3 className="user-card-subtitle col-7">: {singleUserData.gender || "-"}</h3>
                              //             </div>
                              //           </li>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">City</h3>
                              //               <h3 className="user-card-subtitle col-7">: {singleUserData.city}</h3>
                              //             </div>
                              //           </li>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">Policy Start Date</h3>
                              //               <h3 className="user-card-subtitle col-7">: {singleUserData.policyStartDate || "-"}</h3>
                              //             </div>
                              //           </li>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">Policy End Date</h3>
                              //               <h3 className="user-card-subtitle col-7">: {singleUserData.policyEndDate || "-"}</h3>
                              //             </div>
                              //           </li>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">Date of Birth{" "}</h3>
                              //               <h3 className="user-card-subtitle col-7">: {singleUserData.dob || "-"}</h3>
                              //             </div>
                              //           </li>
                              //           <li className="list-group-item bg-transparent ps-0">
                              //             <div className="row">
                              //               <h3 className="user-card-subtitle col-5">Chronic Conditions</h3>
                              //               <h3 className="user-card-subtitle col-7">: {singleUserData.chronicConditions || "-"}</h3>
                              //             </div>
                              //           </li>
                              //         </ul>
                              //       </div>
                              //       </div>
                              //     </div>
                              //   </div>
                              // </div>

                            ))
                          }


                        </div>



                      </div>



                      {/* <div className="col-6">
                          <h3 className="user-card-subtitle">Member Name:{" "}</h3>
                          <h3 className="user-card-subtitle">Member ID:{" "} </h3>
                          <h3 className="user-card-subtitle">Mobile No:{" "}</h3>
                          <h3 className="user-card-subtitle">Contract No: </h3>
                          <h3 className="user-card-subtitle">Contract Name: </h3>
                          <h3 className="user-card-subtitle">Gender: </h3>
                          <h3 className="user-card-subtitle">City: </h3>
                          <h3 className="user-card-subtitle">Policy Start Date: </h3>
                          <h3 className="user-card-subtitle">Policy End Date: </h3>
                          <h3 className="user-card-subtitle"> Date of Birth:{" "}</h3>
                          <h3 className="user-card-subtitle">Chronic Conditions: </h3>
                        </div>
                        <div className="col-6">
                          <h3 className="user-card-subtitle"> {data.memberName || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.memberId || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.mobile || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.contractNo || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.contractName || "-"} </h3>
                          <h3 className="user-card-subtitle">{data.gender || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.city}</h3>
                          <h3 className="user-card-subtitle">{data.policyStartDate || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.policyEndDate || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.dob || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.chronicConditions || "-"}</h3>
                        </div> */}
                    </div>
                  </div>

                  <div className="agent-bg-box-grey mt-3" style={{ display: "none" }}>
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <h5 className="col-form-label">
                          Alternate Mobile No
                        </h5>

                      </div>
                      <div className="col">
                        <input
                          placeholder="Alternate Mobile No"
                          className="form-control input-field-ui"
                          name="reason"
                          value={alternateMobileNo}
                          onChange={e => onAlternateMobileNoChange(e)}
                        />


                        {/* <button className="btn btn-dark coach-call-enroll-btn mt-3 w-100"
                            disabled= {requestStatus.isAlternateMobileNoLoading ? "disabled": ""}
                            onClick={e => saveAlternateMobileNo(e)}
                          >

                            {
                              requestStatus.isAlternateMobileNoLoading? "Saving": "Save"
                            }

                          </button> */}
                      </div>
                      <div className="col-12">
                        <div className="error message text-danger">
                          {
                            alternateMobileNoError?.length > 0 &&
                            <p className="mb-0 mt-1"> {alternateMobileNoError}</p>
                          }
                        </div>

                        {alternateMobileNoSubmissionMessage?.message?.length > 0 ? (
                          <div
                            className={
                              alternateMobileNoSubmissionMessage?.status
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {alternateMobileNoSubmissionMessage?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md px-0" >

                  {
                    singleUserDataList.length === 0 ? <h2>Member Not Availabale</h2> :
                      (
                        singleUserData.enrollmentStatus === "inactive" || singleUserData.enrollmentStatus === "Inactive" ?
                          <>
                            <div
                              className="agent-bg-box-grey agent-bg-box-corner p-0"
                              style={{ border: "2px solid #003E6B", background: "#fff", position: "relative" }}>
                              <div style={{ overflowY: "scroll", overflowX: "hidden", maxHeight: "100%", padding: "14px" }}>
                                <div className="row align-items-center">
                                  {/* <div className="col-12"> */}
                                  {/* <h5 className="user-card-title mb-1">Actionable</h5> */}

                                  {/* <button type="button" className="btn btn-primary btn-sm call-now-btn d-flex" data-bs-toggle="modal" data-bs-target="#agentCallModal" onClick={() => popupSingleUserDetailShow(data.userId,data.encMemberId)}></button> */}
                                  {/* </div> */}
                                  <div className="response-message">
                                    {familyCallReportSubmissionMessage[singleUserData.memberId]?.message?.length > 0 ? (
                                      <div
                                        className={
                                          familyCallReportSubmissionMessage[singleUserData.memberId]?.status
                                            ? "text-success"
                                            : "text-danger"
                                        }
                                      >
                                        {familyCallReportSubmissionMessage[singleUserData.memberId]?.message}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div className="col-12 mb-2">
                                    <label className="col-form-label pt-0"> 
                                      Does the member want to enroll in the program? 
                                    </label><br />
                                    
                                    <button
                                      type="button"
                                      ref={yesButton}
                                      className={`btn btn-dark coach-call-enroll-btn me-3 ${familyCallReport[singleUserData.memberId]?.doesMemberWantToEnroll === "yes" && "active"}`}
                                      // disabled={callReport.doesMemberWantToEnroll === "no"?"disabled": "" || isCallReportEnabled ? "" : "disabled" || callReportActionCompleted? "disabled" : ""}
                                      disabled={!isCallReportEnabled || familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""}
                                      onClick={e => saveMockCallReport(e, "yes", "family")}
                                    >
                                      Yes, Enroll
                                    </button>
                                    <button
                                      type="button"
                                      ref={noButon}
                                      className={`btn btn-dark coach-call-enroll-btn me-3 ${familyCallReport[singleUserData.memberId]?.doesMemberWantToEnroll === "no" && "active"}`}
                                      // disabled={callReport.doesMemberWantToEnroll === "yes"? "disabled": "" || isCallReportEnabled ? "" : "disabled" 
                                      //   || callReportActionCompleted? "disabled" : "" 
                                      // }

                                      disabled={isSlotAlreadyBooked || !isCallReportEnabled
                                        || familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""
                                      }
                                      onClick={e => saveCallReport(e, "no")}
                                    >No, Not Interested</button>

                                    <button
                                      type="button"
                                      ref={noButon}
                                      className={`btn btn-dark coach-call-enroll-btn  ${familyCallReport[singleUserData.memberId]?.doesMemberWantToEnroll === "enroll_later" && "active"}`}
                                      disabled={isSlotAlreadyBooked || !isCallReportEnabled
                                        || familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""
                                      }
                                      onClick={e => saveCallReport(e, "enroll_later")}
                                    >Enroll Later</button>


                                    {callReportValidation["doesMemberWantToEnroll"] && (
                                      <div className="text-danger">
                                        {" "}
                                        {callReportValidation["doesMemberWantToEnroll"]}
                                      </div>
                                    )}
                                  </div>

                                  {familyCallReport[singleUserData.memberId]?.doesMemberWantToEnroll === "no" && (
                                    <div className="col-12 mb-2">
                                      <label className="col-form-label">
                                        Reason for not enrolling <span style={{ color: 'red' }}>*</span>
                                      </label>
                                      {/* <input
                              placeholder="Enter Reason For Not Enrolling"
                              className="form-control input-field-ui"
                              name="reason"
                              value={callReport.reason}
                              onChange={onCallReportFormChange}
                            /> */}
                                      <textarea
                                        placeholder="Enter Reason For Not Enrolling"
                                        className="form-control input-field-ui"
                                        name="reason"
                                        value={familyCallReport[singleUserData.memberId].reason}
                                        onChange={e => onCallReportFormChange(e, "family")}
                                        rows="3"
                                        disabled={familyCallReportActionCompleted[singleUserData.memberId] ? 'disabled' : ""}
                                      ></textarea>
                                      {familyCallReportValidation[singleUserData.memberId]?.reason && (
                                        <div className="text-danger">
                                          {familyCallReportValidation[singleUserData.memberId]?.reason}
                                        </div>
                                      )}

                                      <div className="col-12 mt-3">
                                        <button
                                          type="button"
                                          className="btn btn-dark coach-call-enroll-btn"
                                          style={{ width: "100%" }}
                                          onClick={(e) => rejectFamilyEnrollment(e, singleUserData.encUserId, singleUserData.poolId)}
                                          disabled={requestStatus.isRejectEnrollmentLoading ? "disabled" : "" || familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  )}

                                  {(!familyCallReportActionCompleted[singleUserData.memberId] &&
                                    !familySlotBookingSubmissionMessage[singleUserData.memberId]?.status &&
                                    familyCallReportActionType[singleUserData.memberId] !== "correct") && (
                                      <div>
                                        {familyCallReport[singleUserData.memberId]?.doesMemberWantToEnroll === "yes" && (
                                          <>
                                            <div className="col-12">
                                              <hr />
                                            </div>
                                            <div className="yes_reponse">
                                              <div className="col-12 mb-3">
                                                <label className="col-form-label">
                                                  Book Care Navigator or Transfer call to Care Navigator
                                                </label><br />
                                                <button type="button" className={`btn btn-dark bookAppointment-btn ${familyCallAction[singleUserData.memberId] === 'schedule' ? 'active' : ''}`}
                                                  onClick={e => performFamilyCallAction(e, "schedule")}
                                                  disabled={familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""}
                                                >
                                                  Book Appointment
                                                </button>
                                                <label className="col-form-label px-3">
                                                  Or
                                                </label>
                                                <button
                                                  type="button"
                                                  className={`btn btn-dark bookAppointment-btn ${familyCallAction[singleUserData.memberId] === 'transfer' ? 'active' : ''}`}
                                                  onClick={e => performFamilyCallAction(e, "transfer")}
                                                  disabled={callStatus === "idle" || familyCallReportActionCompleted[singleUserData.memberId] || isFamilyCnSlotAlareadyBooked[singleUserData.memberId] || callingAgent === "mock_call" ? "disabled" : ""}
                                                >
                                                  Transfer Now
                                                </button>
                                              </div>
                                              <div className="col-12">
                                                {/* <div>
                                  <h2 className="user-card-text mb-2">
                                    Book Care Navigator Consultation
                                  </h2>
                                </div> */}
                                                {/* {careNavigatorId ? (
                                  <>
                                    <div className="col-12">
                                      <label
                                        for="inputdate"
                                        className="col-form-label"
                                      >
                                        Select Appointment Date & Timeeeee
                                      </label>
                                      {careNavigatorName}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )} */}
                                              </div>

                                              {
                                                familyCallAction[singleUserData.memberId] === "schedule"
                                                &&
                                                <div className="row">

                                                  <div className="col ps-1 d-flex">
                                                    <div>
                                                      <select
                                                        className="form-select input-field-ui"
                                                        name="cnId"
                                                        value={familySlotBookingFormData[singleUserData.memberId].cnId}
                                                        onChange={e => {
                                                            setFamilySlotBookingFormData((prev) => { 
                                                              return {
                                                                ...prev,
                                                                [singleUserData.memberId]: {
                                                                  ...prev[singleUserData.memberId],
                                                                  cnId: e.target.value
                                                                }
                                                              }
                                                            }); 

                                                            setFamilySelectedSlot((prev) => {
                                                              return {
                                                                ...prev,
                                                                [singleUserData.memberId]: ""
                                                              }
                                                            });

                                                            setFamilySelectedCareNavigator((prev) => { 
                                                              return {
                                                                ...prev,
                                                                [singleUserData.memberId]: e.target.value
                                                              }
                                                            });
                                                           
                                                            familyPopupCareNavigator(e.target.value, null); 
                                                          }
                                                        }
                                                        aria-label="Default select example"
                                                        disabled={familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""}
                                                      >
                                                        <option value="">Any</option>
                                                        {agentData.map((option) => (
                                                          <option key={option.id} value={option.id}>
                                                            {option.fullName}
                                                          </option>
                                                        ))}
                                                      </select>
                                                    </div>
                                                    <div>
                                                      <div className="col ps-1">
                                                        {/* <DatePicker
                                                          selected={familySlotBookingFormData[singleUserData.memberId].date}
                                                          onChange={updatedDate => {

                                                            setFamilySlotBookingFormData({
                                                              ...familySlotBookingFormData,
                                                              [singleUserData.memberId]: {
                                                                ...familySlotBookingFormData[singleUserData.memberId],
                                                                date: updatedDate
                                                              }
                                                            });

                                                            setFamilySlotBookingFormDataErrors({
                                                              ...familySlotBookingFormDataErrors,
                                                              [singleUserData.memberId]: {
                                                                ...familySlotBookingFormDataErrors[singleUserData.memberId],
                                                                date: ""
                                                              }
                                                            });

                                                            familyPopupCareNavigator(null, updatedDate)

                                                          }

                                                          }
                                                          showTimeSelect
                                                          timeIntervals={15}
                                                          minDate={new Date()}
                                                          maxDate={addDaystoDate(new Date(), cnBookingSlotLimit)}
                                                          minTime={familySlotBookingFormData[singleUserData.memberId].date > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                                          maxTime={new Date().setHours(23, 59, 59)}

                                                          timeInputLabel="Time:"
                                                          dateFormat="dd/MM/yyyy h:mm aa"
                                                          placeholderText="Select Date and Time"
                                                          className='form-control control-input-ui'
                                                          disabled={familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""}
                                                          onKeyDown={(e) => {
                                                            e.preventDefault();
                                                          }}
                                                        /> */}


                                                      <DatePicker
                                                        selected={familySlotBookingFormData[singleUserData.memberId].date}
                                                        onChange={updatedDate => {
                                                          const isToday = updatedDate.toDateString() === new Date().toDateString();
                                                          const currentDateTime = new Date();
                                                          const newDateTime =
                                                            isToday && updatedDate < currentDateTime
                                                              ? currentDateTime
                                                              : updatedDate;

                                                          setFamilySlotBookingFormData({
                                                            ...familySlotBookingFormData,
                                                            [singleUserData.memberId]: {
                                                              ...familySlotBookingFormData[singleUserData.memberId],
                                                              date: newDateTime,
                                                            },
                                                          });

                                                          setFamilySlotBookingFormDataErrors({
                                                            ...familySlotBookingFormDataErrors,
                                                            [singleUserData.memberId]: {
                                                              ...familySlotBookingFormDataErrors[singleUserData.memberId],
                                                              date: "",
                                                            },
                                                          });

                                                          familyPopupCareNavigator(null, newDateTime);
                                                        }}
                                                        showTimeSelect
                                                        timeIntervals={15}
                                                        minDate={new Date()}
                                                        maxDate={addDaystoDate(new Date(), cnBookingSlotLimit)}
                                                        minTime={
                                                          familySlotBookingFormData[singleUserData.memberId].date &&
                                                          familySlotBookingFormData[singleUserData.memberId].date.toDateString() ===
                                                            new Date().toDateString()
                                                            ? new Date()
                                                            : new Date(new Date().setHours(0, 0, 0, 0))
                                                        }
                                                        maxTime={new Date().setHours(23, 59, 59)}
                                                        timeInputLabel="Time:"
                                                        dateFormat="dd/MM/yyyy h:mm aa"
                                                        placeholderText="Select Date and Time"
                                                        className="form-control control-input-ui"
                                                        disabled={
                                                          familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""
                                                        }
                                                        onKeyDown={e => {
                                                          e.preventDefault();
                                                        }}
                                                      />



                                                       
                                                      </div>
                                                      {familySlotBookingFormDataErrors[singleUserData.memberId]?.date && (
                                                        <div className="text-danger">
                                                          {familySlotBookingFormDataErrors[singleUserData.memberId]?.date}
                                                        </div>
                                                      )}

                                                    </div>

                                                  </div>
                                                  <div className="col-auto ps-0">
                                                    <button
                                                      type="button"
                                                      className="btn btn-dark coach-call-enroll-btn"
                                                      onClick={() => familyPopupCareNavigator()}
                                                      disabled={familyCallReportActionCompleted[singleUserData.memberId] || requestStatus.isSearchLoading ? "disabled" : ""}
                                                    >
                                                      Search
                                                    </button>
                                                  </div>

                                                  {familySlotSearchSubmissionMessage[singleUserData.memberId]?.message?.length >
                                                    0 ? (
                                                    <div
                                                      className={
                                                        familySlotSearchSubmissionMessage[singleUserData.memberId]?.status
                                                          ? "text-success"
                                                          : "text-danger"
                                                      }
                                                    >
                                                      {familySlotSearchSubmissionMessage[singleUserData.memberId]?.message}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <div className="col-12 mt-1">
                                                    { 
                                                      requestStatus.isSearchLoading ? (
                                                        <div className="text-center">
                                                          <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                          </div>
                                                        </div>
                                                      ): 
                                                    (!familySlots[singleUserData.memberId] || familySlots[singleUserData.memberId]?.length === 0 ? (
                                                      <div>

                                                        {areFamilySlotsFetched[singleUserData.memberId] ? (
                                                          <h5 className="user-card-title mt-3">No Slots Available</h5>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div className="col-md-12">
                                                        <label className="col-form-label">
                                                          Slots Available <span style={{color: 'red'}}>*</span>
                                                        </label>
                                                        <div className="d-flex flex-wrap" style={{ justifyContent: "space-between !important" }}>
                                                          {familySlots[singleUserData.memberId] ?
                                                            familySlots[singleUserData.memberId].map(
                                                              ({
                                                                carenavigatorId,
                                                                slots: agentSlots,
                                                              }, ind) => (
                                                                // <div className="time_slot-d-flex">
                                                                <React.Fragment key={ind}>
                                                                  {agentSlots && agentSlots.map((data) => (
                                                                    <div
                                                                      className={`col-auto mb-2 time_slot_book ${data === familySelectedSlot[singleUserData.memberId]
                                                                          ? "active"
                                                                          : ""
                                                                        }`}
                                                                      onClick={() => {

                                                                        setFamilySelectedSlot({
                                                                          ...familySelectedSlot,
                                                                          [singleUserData.memberId]: data
                                                                        });

                                                                        setFamilySelectedCareNavigator({
                                                                          ...familySelectedCareNavigator,
                                                                          [singleUserData.memberId]: carenavigatorId
                                                                        });

                                                                        setFamilySlotBookingFormDataErrors({
                                                                          ...familySlotBookingFormDataErrors,
                                                                          [singleUserData.memberId]: {
                                                                            ...familySlotBookingFormDataErrors[singleUserData.memberId],
                                                                            selectedSlot: ""
                                                                          }
                                                                        })

                                                                      }}
                                                                      key={data}
                                                                    >
                                                                      {data}
                                                                    </div>
                                                                  ))}

                                                                  {/* <hr /> */}
                                                                  {/* </div> */}
                                                                </React.Fragment>
                                                              )
                                                            ) : <>
                                                              <h5 className="user-card-title mt-3">No Slots Available</h5>
                                                            </>}
                                                        </div>
                                                        {familySlotBookingFormDataErrors[singleUserData.memberId]?.selectedSlot && (
                                                          <div className="text-danger">
                                                            {" "}
                                                            {
                                                              familySlotBookingFormDataErrors[singleUserData.memberId]?.selectedSlot
                                                            }
                                                          </div>
                                                        )}

                                                        {familySlotBookingSubmissionMessage[singleUserData.memberId]?.message?.length > 0 ? (
                                                          <div
                                                            className={
                                                              familySlotBookingSubmissionMessage[singleUserData.memberId]?.status
                                                                ? "text-success"
                                                                : "text-danger"
                                                            }
                                                          >
                                                            {familySlotBookingSubmissionMessage[singleUserData.memberId]?.message}
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}

                                                        <div className="text-center">
                                                          <button
                                                            type="button"
                                                            className="btn btn-dark coach-call-enroll-btn me-2 mt-3"
                                                            onClick={(e) => familyBookSlot()}
                                                            disabled={requestStatus.isBookingLoading ? "disabled" : ""}
                                                          >
                                                            Book
                                                          </button>
                                                        </div>
                                                      </div>
                                                    ))
                                                    }

                                                  </div>
                                                </div>
                                              }

                                              {

                                                familyCallAction[singleUserData.memberId] === "transfer" &&
                                                <div className="col-12 mt-3">
                                                  {callTransferSubmissionMessage?.message?.length >
                                                    0 ? (
                                                    <div
                                                      className={
                                                        callTransferSubmissionMessage.status ===
                                                          "success"
                                                          ? "text-success"
                                                          : "text-danger"
                                                      }
                                                    >
                                                      {callTransferSubmissionMessage.message}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}

                                                  <div className="row align-items-start">
                                                    <div className="col-5">
                                                      <select
                                                        className="form-select input-field-ui"
                                                        id="agentId"
                                                        name="careNavigatorId"
                                                        value={callTransferForm.careNavigatorId}
                                                        onChange={handleChange}
                                                        aria-label="Default select example"
                                                        disabled={callStatus === "idle" || familyCallReportActionCompleted[singleUserData.memberId] || isSlotAlreadyBooked ? "disabled" : ""}
                                                      >
                                                        <option value="">Select CareNavigator </option>
                                                        {onlineAgentData.map((option) => (
                                                          <option key={option.id} value={option.id}>
                                                            {option.fullName}
                                                          </option>
                                                        ))}
                                                      </select>
                                                      {callTransferFormErrors["careNavigatorId"] && (
                                                        <div className="text-danger">
                                                          {" "}
                                                          {callTransferFormErrors["careNavigatorId"]}
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div className="col ps-0" style={{ alignItems: "center" }}>
                                                      <div className="input-field-ui py-1" style={{ width: "auto" }}>
                                                        {
                                                          careNavigatorTransferStatus.callStatus === "idle" ?

                                                            <button type="button" className="btn" onClick={e => transferCallToCN()}
                                                              disabled={familyCallReportActionCompleted[singleUserData.memberId] || isSlotAlreadyBooked || callStatus === "idle" || requestStatus.isTransferCallLoading ? 'disabled' : ""}
                                                            >
                                                              {
                                                                requestStatus.isTransferCallLoading ? "Dialing" : "Dial"
                                                              }
                                                            </button> :
                                                            <button type="button" className="btn" onClick={e => transferCallToCareNavigator()} disabled>
                                                              {careNavigatorTransferStatus.callDisplayStatus}
                                                            </button>
                                                        }




                                                        {
                                                          careNavigatorTransferStatus.callStatus !== "idle" && careNavigatorTransferStatus.callStatus === "in-progress" &&
                                                          <>
                                                            <button
                                                              type="button"
                                                              className="btn coach-call-exit-btn ms-1"
                                                              onClick={e => mergeCalls()}
                                                              disabled={requestStatus.isMergeCallLoading ? 'disabled' : ""}
                                                            >
                                                              <img src="https://appcdn.goqii.com/storeimg/5330_1720687672.png" style={{ height: "22px" }} alt="" />
                                                              {
                                                                requestStatus.isMergeCallLoading ? "Merging" : "Merge & Exit"
                                                              }
                                                            </button>
                                                          </>
                                                        }

                                                        {
                                                          careNavigatorTransferStatus.callStatus !== "idle" && webexTransferCall &&
                                                          <button
                                                            type="button"
                                                            className="btn coach-call-exit-btn ms-1"
                                                            onClick={e => dropAgentFromCall(e, "cn", careNavigatorId)}
                                                          >
                                                            Drop
                                                          </button>
                                                        }
                                                      </div>

                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                              {/* <hr /> */}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  {familyCallReport[singleUserData.memberId]?.doesMemberWantToEnroll === "enroll_later" && (
                                    <div className="col-12 mb-2 mt-5">
                                      <div className="tw-flex tw-justify-between tw-items-center tw-gap-4 tw-w-full">
                                        <DatePicker
                                          selected={familyEnrollLaterForm[singleUserData.memberId].date}
                                          onChange={(updatedDate) => {
                                            setEnrollLaterFormErrors({ ...enrollLaterFormErrors, date: "" })
                                            setFamilyEnrollLaterFormErrors({
                                              ...familyEnrollLaterFormErrors,
                                              [singleUserData.memberId]: {
                                                date: ""
                                              }
                                            })

                                            setFamilyEnrollLaterForm({
                                              ...familyEnrollLaterForm,
                                              [singleUserData.memberId]: {
                                                date: updatedDate
                                              }
                                            });

                                          }
                                          }

                                          showTimeSelect
                                          timeIntervals={15}
                                          minDate={new Date()}
                                          minTime={familyEnrollLaterForm[singleUserData.memberId] > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                          maxTime={new Date().setHours(23, 59, 59)}
                                          timeInputLabel="Time:"
                                          dateFormat="dd/MM/yyyy h:mm aa"
                                          placeholderText="Select Date and Time"
                                          className='form-control control-input-ui-d'
                                          disabled={familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""}
                                        />

                                        <button
                                          type="button"
                                          className="btn btn-dark coach-call-enroll-btn"
                                          onClick={() => registerLaterEnrollment("family")}
                                          disabled={requestStatus.isEnrollLaterLoading ? "disabled" : "" || familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""}
                                        >

                                          Register
                                        </button>
                                      </div>
                                      {familyEnrollLaterFormErrors[singleUserData.memberId]["date"] && (
                                        <div className="text-danger">
                                          {familyEnrollLaterFormErrors[singleUserData.memberId]?.date}
                                        </div>
                                      )}
                                    </div>
                                  )}



                                  {familyCallReport[singleUserData.memberId]?.doesMemberWantToEnroll === "" ? (
                                    <>
                                      <div className="mt-3">


                                        {familyCallBackSubmissionMessage[singleUserData.memberId]?.message?.length >
                                          0 ? (
                                          <div className="col-12">
                                            <div className="form-check-inline">

                                              <div className="response-message">
                                                <div
                                                  className={
                                                    familyCallBackSubmissionMessage[singleUserData.memberId]?.status
                                                      ? "text-success"
                                                      : "text-danger"
                                                  }
                                                >
                                                  {familyCallBackSubmissionMessage[singleUserData.memberId]?.message}
                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}


                                        <div className="row">
                                          <div className="col-12">
                                            <label
                                              className="col-form-label"
                                            >
                                              Call Back Request
                                            </label>
                                          </div>
                                          <div className="col-12">
                                            {/* <input
                                    type="date"
                                    id="inputdate"
                                    name="date"
                                    value={callbackForm.date}
                                    onChange={(e) => callBackOnChange(e)}
                                    className="form-control input-field-ui"
                                    
                                  /> */}

                                            <DatePicker
                                              selected={familyCallbackForm[singleUserData.memberId]?.date || null}
                                              onChange={(updatedDate) =>
                                                setFamilyCallbackForm({
                                                  ...familyCallbackForm,
                                                  [singleUserData.memberId]: {
                                                    ...familyCallbackForm[singleUserData.memberId],
                                                    date: updatedDate,
                                                  },
                                                })
                                              }
                                              showTimeSelect
                                              timeIntervals={15}
                                              minDate={new Date()}
                                              minTime={
                                                familyCallbackForm[singleUserData.memberId]?.date && familyCallbackForm[singleUserData.memberId].date > new Date()
                                                  ? new Date(new Date().setHours(0, 0, 0, 0))
                                                  : new Date()
                                              }
                                              maxTime={new Date().setHours(23, 59, 59)}
                                              timeInputLabel="Time:"
                                              dateFormat="dd/MM/yyyy h:mm aa"
                                              placeholderText="Select Date and Time"
                                              className="form-control input-field-ui"
                                              disabled={familyCallReportActionCompleted[singleUserData.memberId] ? true : false}
                                            />






                                            {familyCallbackFormErrors[singleUserData.memberId]?.date && (
                                              <div className="text-danger">
                                                {familyCallbackFormErrors[singleUserData.memberId]?.date}
                                              </div>
                                            )}
                                          </div>
                                          {/* <div className="col-6">

                                  <input
                                    type="time"
                                    id="inputtime"
                                    name="time"
                                    value={callbackForm.time}
                                    onChange={(e) => callBackOnChange(e)}
                                    className="form-control input-field-ui"
                                  />
                                  {callbackFormErrors["time"] && (
                                    <div className="text-danger">
                                      {callbackFormErrors["time"]}
                                    </div>
                                  )}
                                </div> */}
                                        </div>
                                      </div>

                                      <div className="col-12 mt-3">
                                        <h2 className="user-card-text mb-2">
                                          Notes/Comments (Internal)
                                        </h2>
                                      </div>
                                      <div className="col-12">
                                        <textarea
                                          className="form-control input-field-ui"
                                          id="yourSelf"
                                          placeholder="Take Notes"
                                          rows="5F"
                                          name="notes"
                                          value={familyCallbackForm[singleUserData.memberId]?.notes}
                                          onChange={(e) => familycallbackOnChange(e)}
                                          disabled={familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""}
                                        ></textarea>
                                        {familyCallbackFormErrors[singleUserData.memberId]?.notes && (
                                          <div className="text-danger">
                                            {familyCallbackFormErrors[singleUserData.memberId]?.notes}
                                          </div>
                                        )}
                                      </div>

                                      <div className="mt-3 text-center">
                                        <button
                                          type="button"
                                          className="btn btn-dark coach-call-enroll-btn"
                                          onClick={() => familyPopupCallBackPlan()}
                                          disabled={requestStatus.isCallbackLoading || familyCallReportActionCompleted[singleUserData.memberId] ? "disabled" : ""}
                                        >
                                          Schedule A Call Back
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}


                                  <div className="col-12 mt-3 mb-2">
                                    {
                                      familyCallReportActionCompleted[singleUserData.memberId] && familyCallReportAction[singleUserData.memberId] !== "call_back" ?
                                        <>
                                          <hr />
                                          <div> 
                                          <label className="col-form-label">Does the member want to enroll in the program?</label><br />
                                            <button className="btn btn-dark coach-call-enroll-btn text-center" onClick={e => setShowTeleHealthModal(true)}>TeleHealth Consultation
                                            </button>
                                          </div>
                                          <hr />
                                          <div>
                                            <h5 className="user-card-title">Data Rating </h5>

                                            {/* { 
                                      familyCallReportAction[singleUserData.memberId] === "not_enroll" && familyCallReportActionType[singleUserData.memberId] !== "incorrect"  &&

                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="dataRating"
                                          id="dataRating"
                                          value="1"
                                          onChange={e => handleMockDispositionChange(e)}
                                          defaultChecked={familyCallReportActionType[singleUserData.memberId] === "correct"  && "checked"}
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio3">
                                          Correct
                                        </label>
                                      </div>
                                    }

                                    { 
                                      familyCallReportActionType[singleUserData.memberId] !== "correct" && familyCallReportAction[singleUserData.memberId] === "not_enroll" &&

                                      <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="dataRating"
                                        id="dataRating2"
                                        value="2"
                                        onChange={e => handleMockDispositionChange(e)}
                                        defaultChecked={familyCallReportActionType[singleUserData.memberId] === "incorrect" && "checked"}
                                        
                                      />
                                      <label className="form-check-label" htmlFor="inlineRadio4">
                                        Incorrect
                                      </label>

                                    </div>

                                      
                                    }  */}




                                            {/* {mockDispositionFormErrors["dataRating"] && (
                                                            <div className="text-danger">
                                                              {mockDispositionFormErrors["dataRating"]}
                                                            </div>
                                                          )} */}


                                            {/* </div> */}
                                            <div className="mt-2">
                                              {/* <h2 className="module-card-title mb-2">Select Reason</h2> */}
                                              <label className="form-label">Select Reason <span style={{ color: "red" }}>*</span></label>
                                              <select
                                                className="form-select input-field-ui"
                                                name="reason"
                                                value={mockDispositionForm.reason}
                                                onChange={e => handleMockDispositionChange(e)}
                                              >
                                                <option value="">Select Reason</option>
                                                {



                                                  mockDispositionForm.dataRating.length > 0  ?
                                                    <>
                                                      {
                                                        mockDispositionForm.dataRating === "1" ?

                                                          dispositionReasons.good.map((reason, ind) => (
                                                            <option key={ind} value={reason}>{reason}</option>
                                                          ))
                                                          :
                                                          dispositionReasons.poor.map((reason, ind) => (
                                                            <option value={reason} key={ind}>{reason}</option>
                                                          ))
                                                      }

                                                    </> :

                                                    <>
                                                      {
                                                        dispositionReasons.good.map((reason, ind) => (
                                                          <option key={ind} value={reason}>{reason}</option>
                                                        ))
                                                      }

                                                      {

                                                        dispositionReasons.poor.map((reason, ind) => (
                                                          <option  value={reason} key={ind}>{reason}</option>
                                                        ))
                                                      }
                                                    </>

                                                }
                                              </select>


                                              {
                                                mockDispositionFormErrors["reason"] && (
                                                  <div className="text-danger">
                                                    {mockDispositionFormErrors["reason"]}
                                                  </div>
                                                )}
                                            </div>

                                            <div className="mt-3">
                                              <label>Add Remarks</label>
                                              <textarea
                                                className="form-control input-field-ui"
                                                placeholder="Add Remarks"
                                                rows="3"
                                                name="remarks"
                                                value={mockDispositionForm.remarks}
                                                onChange={e => handleMockDispositionChange(e)}
                                              ></textarea>
                                            </div>
                                          </div>

                                          <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-my-4">
                                            <div className="">
                                              <label className="form-label">Select Preferred Language</label>
                                              <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                                                <div className="tw-flex tw-items-center tw-space-x-2">
                                                  <input
                                                    type="radio"
                                                    id="english"
                                                    name="language"
                                                    value="English"
                                                    checked={languageButton === 'English'}
                                                    onChange={(e) => setLanguageButton(e.target.value)}
                                                    className="form-check-input"
                                                  />
                                                  <label htmlFor="english" className="">English</label>
                                                </div>
                                                <div className="tw-flex tw-items-center tw-space-x-2">
                                                  <input
                                                    type="radio"
                                                    id="arabic"
                                                    name="language"
                                                    value="Arabic"
                                                    checked={languageButton === 'Arabic'}
                                                    onChange={(e) => setLanguageButton(e.target.value)}
                                                    className="form-check-input"
                                                  />
                                                  <label htmlFor="arabic" className="">Arabic</label>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="">
                                              <label className="form-label">Select Communication Channels</label>
                                              <div className="tw-flex tw-items-center tw-space-x-2">
                                                <input
                                                  type="checkbox"
                                                  id="whatsapp"
                                                  name="channel"
                                                  value="whatsapp"
                                                  checked={connectType.includes('whatsapp')}
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setConnectType([...connectType, e.target.value]);
                                                    } else {
                                                      setConnectType(connectType.filter(channel => channel !== e.target.value));
                                                    }
                                                  }}
                                                  className="form-check-input"
                                                />
                                                <label htmlFor="whatsapp" className="">WhatsApp</label>
                                              </div>
                                            </div>
                                          </div>


                                          <div className="text-center mt-2">
                                            <button className="btn btn-dark coach-call-enroll-btn text-center" onClick={e => familySaveMockDisposition()}>Submit</button>
                                          </div>
                                        </> : ""
                                    }
                                  </div>



                                </div>
                              </div>
                            </div>
                            <div className="mt-4 text-center">
                              {
                                familyCallReport[singleUserData.memberId]?.doesMemberWantToEnroll !== "yes" &&
                                <button
                                  className="btn btn-dark coach-call-enroll-btn text-center"
                                  onClick={e => markFamilyDataAsIncorrect()}
                                  disabled={requestStatus.isIncorrectDataLoading || familyCallReportActionCompleted[singleUserData.memberId] && "disbaled"}
                                >
                                  Incorrect Data
                                </button>
                              }
                            </div>
                          </>
                          :

                          <div
                            className="agent-bg-box-grey agent-bg-box-corner p-0"
                            style={{ border: "2px solid #003E6B", background: "#fff", position: "relative", width: "100%" }}>
                            <div style={{ height: "485px", padding: "14px", width: "100%", justifyContent: 'center' }} className=" d-flex ">
                              <div className="row align-items-center">
                                <div className="col-12">
                                  <h5 className="user-card-title mb-1 text-center"><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/dsqjh/kjnamjdjvj.png" style={{ height: "20px" }} /> {singleUserData?.actionData?.message}</h5>
                                </div>

                                <div className="col-12">
                                  <ul className="list-group list-group-flush">
                                    {
                                      singleUserData.enrollmentStatus === "active" ?
                                        <>
                                          <li className="list-group-item">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-4">CareNavigator </h3>
                                              <h3 className="user-card-subtitle col-8">: {singleUserData.actionData?.cnName}</h3>
                                            </div>
                                          </li>
                                          <li className="list-group-item">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-4">Appointment Date and Time</h3>
                                              <h3 className="user-card-subtitle col-8">: {singleUserData?.actionData?.cnSlotBookingTime}</h3>
                                            </div>
                                          </li>
                                        </> : ""
                                    }
                                    <li className="list-group-item">
                                      <div className="row">
                                        <h3 className="user-card-subtitle col-4">Action by Agent Name</h3>
                                        <h3 className="user-card-subtitle col-8">: {singleUserData.actionData?.fullName}</h3>
                                      </div>
                                    </li>
                                    <li className="list-group-item">
                                      <div className="row">
                                        <h3 className="user-card-subtitle col-4">Date and Time</h3>
                                        <h3 className="user-card-subtitle col-8">: {singleUserData?.actionData?.actionDate}</h3>
                                      </div>
                                    </li>
                                  </ul>
                                </div>

                              </div>
                            </div>
                          </div>
                      )

                  }
                </div>



                <div className="col-md-1">
                  <div className="">
                    <div className="row">
                      {/* <div className="col-12">
                          <h5 className="user-card-title mb-1">
                            Notes/Comments (ext)
                          </h5>
                        </div> */}
                      <div
                        className="col"
                      // style={{ position: "relative" }}
                      >
                        {/* <textarea
                            className="form-control input-field-ui"
                            id="yourSelf"
                            placeholder="Take Notes"
                            rows="17"
                          ></textarea> */}
                        <div className="agent-bg-box-grey text-center pb-2"
                          style={{ cursor: "pointer" }}
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRightEng11"
                          aria-controls="offcanvasRightEng11"
                        >
                          <img
                            src="https://storage.googleapis.com/ksabupatest/2024/09/24/njqhv/3ushhs30d3.png"
                            className="img-fluid"
                            alt=""
                          />
                          <label style={{ fontSize: "12px" }}>English</label>
                        </div>

                        <div className="agent-bg-box-grey text-center pb-2 mt-2"
                          style={{ cursor: "pointer" }}
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRightArb11"
                          aria-controls="offcanvasRightArb11"
                        >
                          <img
                            src="https://storage.googleapis.com/ksabupatest/2024/09/24/njqhv/3ushhs30d3.png"

                            className="img-fluid"
                            alt=""
                          />
                          <label style={{ fontSize: "12px" }}>Arabic</label>
                        </div>

                        <div className="agent-bg-box-grey text-center pb-2 mt-2"
                          style={{ cursor: "pointer" }}
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRightNotes"
                          aria-controls="offcanvasRightNotes"
                          onClick={(e) => handleShowcallHistoryDetailsModalFamilyNotes(singleUserData.encUserId)}
                        >
                          <img
                            src="https://storage.googleapis.com/ksabupatest/2024/09/24/go71k/d8f8z1fh7h.png"

                            className="img-fluid"
                            alt=""
                          />
                          <label style={{ fontSize: "12px" }}>Notes</label>
                        </div>

                        <div className="tw-mt-2 tw-p-2 tw-rounded-lg tw-cursor-pointer tw-bg-gray-100 tw-text-center tw-relative"

                          onClick={() => (
                            setIsModalOpen(true)
                          )}
                        >
                          <img
                            src="https://appcdn.goqii.com/storeimg/77429_1729683390.png"

                            className="img-fluid tw-w-12"
                            alt=""
                          />
                          <label className="tw-text-xs tw-cursor-pointer">Send WhatsApp</label>
                          <div className="tw-absolute tw-top-5 tw-right-20">
                            {/* <Toast className="tw-bg-[#2885C4]/95 tw-text-white tw-font-semibold tw-overflow-hidden" show={showA} onClose={() => setShowA(false)}>
                                
                                <Toast.Body className="" onClick={() => setShowA(false)}>
                                
                                  You have to select Communication Channel first.
                                  </Toast.Body>
                              </Toast> */}

                          </div>
                        </div>

                        {/* {(memberCallList?.length > 0 || memberCallBackList?.length > 0 ) &&  */}
                        <div className="agent-bg-box-grey text-center pb-2 mt-2"
                          onClick={(e) => handleShowcallHistoryDetailsModalFamily(singleUserData.encUserId)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://storage.googleapis.com/ksabupatest/2024/09/24/h37j3/yjxakabhmu.png"

                            className="img-fluid mb-1"
                            alt=""
                          />
                          <label style={{ fontSize: "12px", lineHeight: "1" }}>Call History</label>
                        </div>
                        {/* // } */}

                        <div
                          className="offcanvas offcanvas-end box-script-ui"
                          // style={{width:"30%"}}
                          tabindex="-1"
                          id="offcanvasRightEng11"
                          aria-labelledby="offcanvasRightEng1Label"
                          data-bs-backdrop="static"
                        >
                          <div className="offcanvas-header pt-2 pb-1">
                            {/* <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                          </div>
                          <div className="offcanvas-body py-0">
                            <div className="row">
                              <textarea className="col mb-3" value={callScripts.english.script} rows="22" style={{ textAlign: "justify", border: "0", backgroundColor: 'transparent' }} disabled>
                              </textarea>
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className="btn btn-dark coach-call-enroll-btn"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  Close
                                </button>
                              </div>
                            </div>

                          </div>
                          {/* <div className="offcanvas-footer px-3 py-3 pt-2">
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Close
                              </button>
                            </div> */}
                        </div>


                        <div
                          className="offcanvas offcanvas-end box-script-ui"
                          // style={{width:"30%"}}
                          tabindex="-1"
                          id="offcanvasRightArb11"
                          aria-labelledby="offcanvasRightArb1Label"
                          data-bs-backdrop="static"
                        >
                          <div className="offcanvas-header pt-2 pb-1 text-end" style={{ justifyContent: "flex-end" }}>

                            {/* <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                          </div>
                          <div className="offcanvas-body py-0">
                            <div className="row">
                              <textarea className="col mb-3" rows="22" value={callScripts.arabic.script} style={{ textAlign: "right", border: "0", backgroundColor: 'transparent' }} disabled>
                              </textarea>
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className="btn btn-dark coach-call-enroll-btn"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                            {/* <div className=" text-end mb-3">
                              <textarea> 
                                {arabicScriptData[0]?.script}
                              </textarea>
                              </div> */}
                          </div>
                          {/* <div className="offcanvas-footer text-end px-3 py-3 pt-2">
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Close
                              </button>
                            </div> */}
                        </div>

                        {/*----------- call notes -------------------*/}
                        <div
                          className="offcanvas offcanvas-end box-script-ui box-script-ui-width100"
                          tabindex="-1"
                          id="offcanvasRightNotes"
                          aria-labelledby="offcanvasRightNotesLabel"
                          data-bs-backdrop="static"
                        >
                          <div className="offcanvas-header pt-2 pb-1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: "90%" }} className="d-flex align-items-center">
                              <h1 className='modal-title me-4'>{singleUserData.memberName}</h1>
                              {/* <label className="col-form-label pt-0 me-4"> <i className="me-2"><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/nuf9q/dhb4tjf45a.png" style={{height:"14px", verticalAlign:"baseline"}} alt="" /></i>HOF: Irfan Khan</label> */}
                            </div>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                            <button
                              type="button"
                              className="btn btn-dark coach-call-enroll-btn"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={e => setCallNotesErrors("")}
                            >
                              Close
                            </button>
                          </div>
                          <div className="offcanvas-body py-0">
                            <div className="row">
                              <div className="col-12 mt-2">
                                <div className="table-responsive" style={{ height: "350px", overflowY: "scroll" }}>
                                  <table className="table mb-0 ">
                                    <thead style={{ position: "sticky", top: "0" }}>
                                      <tr>
                                        <th scope="col" style={{ width: "35%" }}>Agent Name </th>
                                        <th scope="col" style={{ width: "50%" }}>Notes</th>
                                        <th scope="col" style={{ width: "50%" }}>Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        callNotesList && callNotesList.length > 0 && callNotesList.map((note, index) => (
                                          <tr key={index}>
                                            <td>{note.agentName}</td>
                                            <td>{note.notes}</td>
                                            <td>{note.date}</td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="offcanvas-footer px-3 py-3 pt-2">
                            <div className="row">
                              <div className="col-12">
                                <textarea
                                  className="input-field-ui"
                                  rows="3"
                                  name="callNotes"
                                  value={callNotes}
                                  onChange={e => changeCallNotes(e)}
                                  required
                                ></textarea>
                                {
                                  callNotesErrors && <p className="text-danger">{callNotesErrors}</p>
                                }

                                {
                                  callNotesSubmissionMessage && <p className="text-success">{callNotesSubmissionMessage}</p>
                                }

                              </div>


                              <div className="col-12">
                                <button
                                  type="button"
                                  className="btn btn-dark coach-call-enroll-btn"
                                  onClick={e => saveCallNotes(e, singleUserData.encUserId)}
                                >
                                  Save
                                </button>
                              </div>

                            </div>


                          </div>
                        </div>


                        {/*-------------------- Chronic condition updation ------------ */}
                        <div
                          className="offcanvas offcanvas-end box-script-ui"
                          tabIndex="-1"
                          id="conditionsupdateModal"
                          aria-labelledby="conditionsupdateModalLabel"
                          data-bs-backdrop="static"
                          aria-hidden="true"
                        >
                          <div className="" style={{ margin: "0px", alignItems: "flex-start" }}>
                            <div className="" style={{ border: "0", height: "100%" }}>
                              <div className="modal-header">
                                <h1 className="modal-title" id="pathwaysProgramsModalLabel">Select Conditions</h1>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                  ref={conditionModalCloseBtn}
                                  onClick={e => {
                                    setConditionSearchQuery("");

                                    setSingleUserDataList((previous) => {
                                      return previous.map((item) => {
                                        if (item.memberId === singleUserData.memberId) {
                                          return { ...item, programArr: [...singleUserData.copyProgramArr] };
                                        } else {
                                          return item;
                                        }
                                      });
                                    });


                                    setSingleUserData({
                                      ...singleUserData,
                                      programArr: [...singleUserData.copyProgramArr]
                                    })
                                  }}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="row" >
                                  <div className="col-md-12">
                                    <input
                                      type="text"
                                      id="search_bookmark_services"
                                      className="form-control input-field-ui mb-2"
                                      placeholder="Search Conditions..."
                                      style={{ marginTop: "2px" }}
                                      value={conditionSearchQuery}
                                      onChange={e => searchConditions(e)}
                                    />
                                  </div>
                                  <div className="col-md-12 px-4"></div>
                                  <div className="col-md-12 px-4" style={{ height: '325px', overflowY: "scroll" }}>

                                    {
                                      fitlerProgramConditions.map((condition) => (
                                        <div className="form-check mb-3 d-flex" key={condition.id}>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`condition${condition.id}`}
                                            value={condition.id}
                                            onChange={e => selectConditions(e)}
                                            checked={singleUserData?.programArr?.includes(condition.id)}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="service-example"
                                          >
                                            {condition.name}
                                          </label>
                                        </div>
                                      ))
                                    }
                                    {/* Add more checkboxes as needed */}
                                  </div>
                                  <div className="col-md-12 px-4 my-1 text-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary program-save-btn"
                                      onClick={e => updateCondition(e)}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer" style={{ justifyContent: "center" }}>

              { 
                  ((familyCallReportActionCompleted[singleUserData.memberId] && singleUserData.enrollmentStatus === "inactive"
                    && familyCallReportAction[singleUserData.memberId] !== 'call_back')) ? 
                    <button
                      ref={closeMockModal}
                      type="button"
                      className="btn btn-secondary clear-btn"
                      onClick={e => setCallCloseWarningPopup(true)}
                    >
                      Close
                    </button>: 

                  <button
                    ref={closeMockModal}
                    type="button"
                    className="btn btn-secondary clear-btn"
                    data-bs-dismiss="modal"
                    disabled={(callStatus !== "idle" || (familyCallReportActionCompleted[singleUserData.memberId] && singleUserData.enrollmentStatus === "inactive"
                      && familyCallReportAction[singleUserData.memberId] !== 'call_back')) && "disbaled"}
                    onClick={e => mockCallPopupClose(e)}
                  >
                    Close
                  </button>
              }
            </div>
          </div>

        </div>

      </div>


      <div
        className="modal fade"
        id="agentCallModal2"
        tabIndex="1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="agentCallModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          {singleUserDataList.map((data, index) => (
            <div className="modal-content" key={index}>
              {/* <div className="modal-header mb-2">
                        <h5 className="modal-subtitle mb-0">Call</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
              <div className="agent-call-box">
                <div className="row align-items-center">
                  <div className="col-3">
                    <h2 className="coach-card-title">{data.memberName}</h2>
                    {/* <h3 className="coach-card-subtitle">
                      Policy Status: {data.enrollmentStatus}
                    </h3> */}
                  </div>
                  <div className="col">
                    <h3 className="coach-card-subtitle">
                      <i className="bi bi-card-list"></i> Program:{" "}
                      {data.carePrograms}
                    </h3>

                  </div>
                  <div className="col-auto">
                    {softCallEnabled === "Y" ? (
                      <>
                        {
                          callStatus === "idle" ?
                            <button
                              onClick={() => handleMockedCall(data.encUserId)}
                              disabled={callStatus === "connected"}
                              className='btn btn-dark coach-calling-btn mx-2'
                              alt="soft phone call"
                            >
                              <i className="bx bxs-phone"   ></i>
                            </button>
                            :
                            <button
                              type="button"
                              className="btn btn-dark coach-call-close-btn"
                              onClick={() => popupMockDisconnectCall("agent")}
                            >
                              <i className="bx bxs-phone"   ></i>
                            </button>
                        }
                      </>
                    ) : (
                      ""
                    )}
                    {phoneCallEnabled === "Y" ? (
                      <>
                        {isCallDisabled ? (
                          <button
                            type="button"
                            className="btn btn-dark coach-calling-btn mx-2"

                            onClick={() => popupInitiateCall()}

                          >
                            {/* <i className="bx">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/n4doj/djss4244g3.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i> */}
                            <i className="bx bxs-phone"></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-dark coach-calling-btn mx-2"

                            onClick={() => popupInitiateCall()}
                            disabled
                          >
                            {/* <i className="bx">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/n4doj/djss4244g3.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i> */}
                            <i className="bx bxs-phone"   ></i>
                          </button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-auto">
                    <div className="coach-call-btn-box">

                      {isMuteDisabled ? (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-btn mx-2"
                          onClick={() => popupMuteCall("Y")}
                        >
                          <i className="bi">
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                              style={{ height: "28px" }}
                              alt=""
                            />
                          </i>
                          Mute
                        </button>

                      ) : (
                        <>
                          {isUnMuteDisabled ? (
                            <button
                              type="button"
                              className="btn btn-dark coach-call-btn mx-2"
                              onClick={() => popupMuteCall("N")}
                            >
                              <i className="bi">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i>
                              UnMute
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-dark coach-call-btn mx-2"
                              onClick={() => popupMuteCall("Y")}
                              style={{ display: 'none' }}
                              disabled
                            >
                              <i className="bi">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i>
                              Mute
                            </button>
                          )}
                        </>
                      )}

                      {isHoldDisabled ? (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-btn mx-2"
                          onClick={() => popupHoldCall('user')}
                        >
                          <i className="bi">
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/f8q0b/msra7ofjna.png"
                              style={{ height: "28px" }}
                              alt=""
                            />
                          </i>{" "}
                          Hold
                        </button>
                      ) : (
                        <>
                          {isUnHoldDisabled ? (
                            <button
                              type="button"
                              className="btn btn-dark coach-call-btn mx-2"
                              onClick={() => popupUnHoldCall('user')}
                            >
                              <i className="bi">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/f8q0b/msra7ofjna.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i>
                              UnHold
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-dark coach-call-btn mx-2"
                              onClick={() => popupMuteCall("N")}
                              style={{ display: 'none' }}
                              disabled
                            >
                              <i className="bi">
                                <img
                                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/f8q0b/msra7ofjna.png"
                                  style={{ height: "28px" }}
                                  alt=""
                                />
                              </i>
                              Hold
                            </button>
                          )}
                        </>

                      )}

                    </div>

                    <h3 className="coach-card-subtitle text-center">
                      <i className="bx bx-time-five"></i> Call Duration{" "}
                      {timer.hours}:{timer.minutes}:{timer.seconds}
                    </h3>
                    <div className="call-status-message">
                      <p className="mb-0">{callInfoMessaage}</p>
                    </div>

                    {softCallEnabled === "N" || softCallEnabled === "N" ? (
                      <div className="coach-call-btn-box">
                        <form onSubmit={handleSubmit}>
                          {/* <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />   */}
                          <div className="row">
                            <div className="col">
                              <select
                                className="form-select input-field-ui"
                                id="agentId"
                                name="adminId"
                                value={formData["adminId"]}
                                onChange={handleChange}
                                aria-label="Default select example"
                              >
                                <option value="">Select Name</option>
                                {agentData.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.fullName}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm call-transfer-btn"
                              >
                                Transfer Call
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm call-transfer-exit-btn"
                              >
                                Transfer Call & Exit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}



                  </div>
                  {(softCallEnabled === "Y" || softCallEnabled === "Y") && !isCallMergedWithCn ? (
                    <div className="col-auto">
                      {isDiconnectDisabled && callStatus === "in-progress"  ? (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-close-btn"
                          onClick={() => popupDisconnectCall("agent")}
                        >
                          <i className="bx bxs-phone"   ></i> 
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-close-btn"
                          onClick={() => popupDisconnectCall("agent")}
                          style={{ display: 'none' }}
                          disabled
                        >
                          <i className="bx bxs-phone"   ></i>
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

              </div>
              {/* <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
              <div className="modal-body agent-call-box-1">
                <div className="row">
                  <div className="col-md-5">
                    <div className="agent-bg-box-grey">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <h5 className="user-card-title mb-1">
                            Member Information
                          </h5>
                        </div>
                        <div className="col-12">
                          <ul className="list-group list-group-flush" style={{ overflowY: "scroll", maxHeight: "400px" }}>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Member Name{" "}</h3>
                                <h3 className="user-card-subtitle col-7">: {data.memberName || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Member ID{" "}</h3>
                                <h3 className="user-card-subtitle col-7">: {data.memberId || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Mobile No{" "}</h3>
                                <h3 className="user-card-subtitle col-7">: {data.mobile || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Contract No</h3>
                                <h3 className="user-card-subtitle col-7">: {data.contractNo || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Contract Name</h3>
                                <h3 className="user-card-subtitle col-7">: {data.contractName || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Gender</h3>
                                <h3 className="user-card-subtitle col-7">: {data.gender || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">City</h3>
                                <h3 className="user-card-subtitle col-7">: {data.city}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Policy Start Date</h3>
                                <h3 className="user-card-subtitle col-7">: {data.policyStartDate || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Policy End Date</h3>
                                <h3 className="user-card-subtitle col-7">: {data.policyEndDate || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Date of Birth{" "}</h3>
                                <h3 className="user-card-subtitle col-7">: {data.dob || "-"}</h3>
                              </div>
                            </li>
                            <li className="list-group-item bg-transparent ps-0">
                              <div className="row">
                                <h3 className="user-card-subtitle col-5">Chronic Conditions</h3>
                                <h3 className="user-card-subtitle col-7">: {data.chronicConditions || "-"}</h3>
                              </div>
                            </li>
                          </ul>
                        </div>

                        {/* <div className="col-6">
                          <h3 className="user-card-subtitle">Member Name:{" "}</h3>
                          <h3 className="user-card-subtitle">Member ID:{" "} </h3>
                          <h3 className="user-card-subtitle">Mobile No:{" "}</h3>
                          <h3 className="user-card-subtitle">Contract No: </h3>
                          <h3 className="user-card-subtitle">Contract Name: </h3>
                          <h3 className="user-card-subtitle">Gender: </h3>
                          <h3 className="user-card-subtitle">City: </h3>
                          <h3 className="user-card-subtitle">Policy Start Date: </h3>
                          <h3 className="user-card-subtitle">Policy End Date: </h3>
                          <h3 className="user-card-subtitle"> Date of Birth:{" "}</h3>
                          <h3 className="user-card-subtitle">Chronic Conditions: </h3>
                        </div>
                        <div className="col-6">
                          <h3 className="user-card-subtitle"> {data.memberName || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.memberId || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.mobile || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.contractNo || "-"} </h3>
                          <h3 className="user-card-subtitle"> {data.contractName || "-"} </h3>
                          <h3 className="user-card-subtitle">{data.gender || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.city}</h3>
                          <h3 className="user-card-subtitle">{data.policyStartDate || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.policyEndDate || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.dob || "-"}</h3>
                          <h3 className="user-card-subtitle">{data.chronicConditions || "-"}</h3>
                        </div> */}
                      </div>
                    </div>

                    <div className="agent-bg-box-grey mt-3">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <h5 className="col-form-label">
                            Alternate Mobile No
                          </h5>

                        </div>
                        <div className="col">
                          <input
                            placeholder="Alternate Mobile No"
                            className="form-control input-field-ui"
                            name="reason"
                            value={alternateMobileNo}
                            onChange={e => onAlternateMobileNoChange(e)}
                          />


                          {/* <button className="btn btn-dark coach-call-enroll-btn mt-3 w-100"
                            disabled= {requestStatus.isAlternateMobileNoLoading ? "disabled": ""}
                            onClick={e => saveAlternateMobileNo(e)}
                          >

                            {
                              requestStatus.isAlternateMobileNoLoading? "Saving": "Save"
                            }

                          </button> */}
                        </div>
                        <div className="col-12">
                          <div className="error message text-danger">
                            {
                              alternateMobileNoError?.length > 0 &&
                              <p className="mb-0 mt-1"> {alternateMobileNoError}</p>
                            }
                          </div>

                          {alternateMobileNoSubmissionMessage?.message?.length > 0 ? (
                            <div
                              className={
                                alternateMobileNoSubmissionMessage?.status
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {alternateMobileNoSubmissionMessage?.message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md px-0">
                    <div
                      className="agent-bg-box-grey"
                      style={{ overflowY: "scroll", maxHeight: "100%" }}
                    >
                      <div className="row align-items-center">
                        {/* <div className="col-12"> */}
                        {/* <h5 className="user-card-title mb-1">Actionable</h5> */}

                        {/* <button type="button" className="btn btn-primary btn-sm call-now-btn d-flex" data-bs-toggle="modal" data-bs-target="#agentCallModal" onClick={() => popupSingleUserDetailShow(data.userId,data.encMemberId)}></button> */}
                        {/* </div> */}
                        <div className="response-message">
                          {callReportSubmissionMessage?.message?.length > 0 ? (
                            <div
                              className={
                                callReportSubmissionMessage?.status
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {callReportSubmissionMessage?.message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-12 mb-2">
                          <label className="col-form-label">
                            Does the member want to enroll in the program?
                          </label><br />
                          <button
                            type="button"
                            ref={yesButton}
                            className={`btn btn-dark coach-call-enroll-btn me-3 ${callReport.doesMemberWantToEnroll === "yes" && "active"}`}
                            // disabled={callReport.doesMemberWantToEnroll === "no"?"disabled": "" || isCallReportEnabled ? "" : "disabled" || callReportActionCompleted? "disabled" : ""}
                            disabled={!isCallReportEnabled || callReportActionCompleted ? "disabled" : ""}
                            onClick={e => saveMockCallReport(e, "yes")}
                          >
                            Yes, Enroll
                          </button>
                          <button
                            type="button"
                            ref={noButon}
                            className={`btn btn-dark coach-call-enroll-btn me-3 ${callReport.doesMemberWantToEnroll === "no" && "active"}`}
                            // disabled={callReport.doesMemberWantToEnroll === "yes"? "disabled": "" || isCallReportEnabled ? "" : "disabled" 
                            //   || callReportActionCompleted? "disabled" : "" 
                            // }

                            disabled={isSlotAlreadyBooked || !isCallReportEnabled
                              || callReportActionCompleted ? "disabled" : ""
                            }
                            onClick={e => saveCallReport(e, "no")}
                          >No, Not Interested</button>

                          <button
                            type="button"
                            ref={noButon}
                            className={`btn btn-dark coach-call-enroll-btn  ${callReport.doesMemberWantToEnroll === "enroll_later" && "active"}`}
                            disabled={isSlotAlreadyBooked || !isCallReportEnabled
                              || callReportActionCompleted ? "disabled" : ""
                            }
                            onClick={e => saveCallReport(e, "enroll_later")}
                          >Enroll Later</button>


                          {callReportValidation["doesMemberWantToEnroll"] && (
                            <div className="text-danger">
                              {" "}
                              {callReportValidation["doesMemberWantToEnroll"]}
                            </div>
                          )}
                        </div>

                        {callReport.doesMemberWantToEnroll === "no" && (
                          <div className="col-12 mb-2">
                            <label className="col-form-label">
                              Reason for not enrolling
                            </label>
                            {/* <input
                              placeholder="Enter Reason For Not Enrolling"
                              className="form-control input-field-ui"
                              name="reason"
                              value={callReport.reason}
                              onChange={onCallReportFormChange}
                            /> */}
                            <textarea
                              placeholder="Enter Reason For Not Enrolling"
                              className="form-control input-field-ui"
                              name="reason"
                              value={callReport.reason}
                              onChange={onCallReportFormChange}
                              rows="3"
                              disabled={callReportActionCompleted ? 'disabled' : ""}
                            ></textarea>
                            {callReportValidation["reason"] && (
                              <div className="text-danger">
                                {callReportValidation["reason"]}
                              </div>
                            )}

                            <div className="col-12 mt-3">
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                style={{ width: "100%" }}
                                onClick={() => rejectEnrollment()}
                                disabled={requestStatus.isRejectEnrollmentLoading ? "disabled" : "" || callReportActionCompleted ? "disabled" : ""}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        )}

                        {callReport.doesMemberWantToEnroll === "yes" && (
                          <>
                            <div className="col-12">
                              <hr />
                            </div>
                            <div className="yes_reponse">
                              <div className="col-12 mb-3">
                                <label className="col-form-label">
                                  Book Care Navigator or Transfer call to Care Navigator
                                </label><br />
                                <button type="button" className={`btn btn-dark bookAppointment-btn ${callAction === 'schedule' ? 'active' : ''}`}
                                  onClick={e => performCalAction(e, "schedule")}
                                  disabled={callReportActionCompleted ? "disabled" : ""}
                                >
                                  Book Appointment
                                </button>
                                <label className="col-form-label px-3">
                                  Or
                                </label>
                                <button
                                  type="button"
                                  className={`btn btn-dark bookAppointment-btn ${callAction === 'transfer' ? 'active' : ''}`}
                                  onClick={e => performCalAction(e, "transfer")}
                                  disabled={callStatus === "idle" || callReportActionCompleted || isSlotAlreadyBooked || true ? "disabled" : ""}
                                >
                                  Transfer Now
                                </button>
                              </div>
                              <div className="col-12">
                                {/* <div>
                                  <h2 className="user-card-text mb-2">
                                    Book Care Navigator Consultation
                                  </h2>
                                </div> */}
                                {/* {careNavigatorId ? (
                                  <>
                                    <div className="col-12">
                                      <label
                                        for="inputdate"
                                        className="col-form-label"
                                      >
                                        Select Appointment Date & Timeeeee
                                      </label>
                                      {careNavigatorName}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              {
                                callAction === "schedule"
                                &&
                                <div className="row">

                                  <div className="col ps-1 d-flex">
                                    <div>
                                      <select
                                        className="form-select input-field-ui"
                                        name="cnId"
                                        value={slotBookingFormData.cnId}
                                        onChange={e => setSlotBookingFormData({
                                          ...slotBookingFormData,
                                          cnId: e.target.value
                                        })}
                                        aria-label="Default select example"
                                        disabled={callReportActionCompleted ? "disabled" : ""}
                                      >
                                        <option value="">Any</option>
                                        {agentData.map((option) => (
                                          <option key={option.id} value={option.id}>
                                            {option.fullName}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div>
                                      <div className="col ps-1">
                                        <DatePicker
                                          selected={slotBookingFormData.date}
                                          onChange={
                                            updatedDate => {
                                              setSlotBookingFormData({ ...slotBookingFormData, date: updatedDate });
                                              setSlotBookingFormDataErrors({
                                                ...slotBookingFormDataErrors,
                                                date: ""
                                              })
                                            }
                                          }
                                          showTimeSelect
                                          timeIntervals={15}
                                          minDate={new Date()}
                                          maxDate={addDaystoDate(new Date(), cnBookingSlotLimit)}
                                          minTime={slotBookingFormData.date > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                          maxTime={new Date().setHours(23, 59, 59)}
                                          timeInputLabel="Time:"
                                          dateFormat="dd/MM/yyyy h:mm aa"
                                          placeholderText="Select Date and Time"
                                          className='form-control control-input-ui'
                                          disabled={callReportActionCompleted ? "disabled" : ""}
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                      </div>
                                      {slotBookingFormDataErrors["date"] && (
                                        <div className="text-danger">
                                          {slotBookingFormDataErrors["date"]}
                                        </div>
                                      )}

                                    </div>

                                  </div>
                                  <div className="col-auto ps-0">
                                    <button
                                      type="button"
                                      className="btn btn-dark coach-call-enroll-btn"
                                      onClick={() => popupCareNavigator()}
                                      disabled={callReportActionCompleted || requestStatus.isSearchLoading ? "disabled" : ""}
                                    >
                                      Search
                                    </button>
                                  </div>

                                  {slotsSearchSubmissionMessage?.message?.length >
                                    0 ? (
                                    <div
                                      className={
                                        slotsSearchSubmissionMessage.status
                                          ? "text-success"
                                          : "text-danger"
                                      }
                                    >
                                      {slotsSearchSubmissionMessage.message}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-12 mt-1">
                                    {!slots || slots.length === 0 ? (
                                      <div>
                                        {areSlotsFetched ? (
                                          <h5 className="user-card-title mt-3">No Slots Available</h5>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <div className="col-md-12">
                                        <label className="col-form-label">
                                          Slots Available
                                        </label>
                                        <div className="d-flex flex-wrap" style={{ justifyContent: "space-between !important" }}>
                                          {slots &&
                                            slots.map(
                                              ({
                                                carenavigatorId,
                                                slots: agentSlots,
                                              }) => (
                                                // <div className="time_slot-d-flex">
                                                <React.Fragment key={carenavigatorId}>
                                                  {agentSlots && agentSlots.map((data) => (
                                                    <div
                                                      className={`col-auto mb-2 time_slot_book ${data === selectedSlot
                                                          ? "active"
                                                          : ""
                                                        }`}
                                                      onClick={() => {
                                                        setSelectedSlot(data);
                                                        setSelectedCareNavigator(
                                                          carenavigatorId
                                                        );
                                                        setSlotBookingFormDataErrors(
                                                          {
                                                            ...slotBookingFormDataErrors,
                                                            selectedSlot: "",
                                                          }
                                                        );
                                                      }}
                                                      key={data}
                                                    >
                                                      {data}
                                                    </div>
                                                  ))}

                                                  {/* <hr /> */}
                                                  {/* </div> */}
                                                </React.Fragment>
                                              )
                                            )}
                                        </div>
                                        {slotBookingFormDataErrors[
                                          "selectedSlot"
                                        ] && (
                                            <div className="text-danger">
                                              {" "}
                                              {
                                                slotBookingFormDataErrors[
                                                "selectedSlot"
                                                ]
                                              }
                                            </div>
                                          )}

                                        {slotBookingSubmissionMessage?.message?.length > 0 ? (
                                          <div
                                            className={
                                              slotBookingSubmissionMessage.status
                                                ? "text-success"
                                                : "text-danger"
                                            }
                                          >
                                            {slotBookingSubmissionMessage.message}
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        <div class="text-center">
                                          <button
                                            type="button"
                                            className="btn btn-dark coach-call-enroll-btn me-2 mt-3"
                                            onClick={(e) => bookSlot()}
                                            disabled={requestStatus.isBookingLoading ? "disabled" : ""}
                                          >
                                            Book
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              }

                              {

                                callAction === "transfer" &&
                                <div className="col-12 mt-3">
                                  {callTransferSubmissionMessage?.message?.length >
                                    0 ? (
                                    <div
                                      className={
                                        callTransferSubmissionMessage.status ===
                                          "success"
                                          ? "text-success"
                                          : "text-danger"
                                      }
                                    >
                                      {callTransferSubmissionMessage.message}
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="row align-items-start">
                                    <div className="col-5">
                                      <select
                                        className="form-select input-field-ui"
                                        id="agentId"
                                        name="careNavigatorId"
                                        value={callTransferForm.careNavigatorId}
                                        onChange={handleChange}
                                        aria-label="Default select example"
                                        disabled={callStatus === "idle" || callReportActionCompleted || isSlotAlreadyBooked ? "disabled" : ""}
                                      >
                                        <option value="">Select CareNavigator</option>
                                        {agentData.map((option) => (
                                          <option key={option.id} value={option.id}>
                                            {option.fullName}
                                          </option>
                                        ))}
                                      </select>
                                      {callTransferFormErrors["careNavigatorId"] && (
                                        <div className="text-danger">
                                          {" "}
                                          {callTransferFormErrors["careNavigatorId"]}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col ps-0 d-flex" style={{ alignItems: "center" }}>
                                      <div className="input-field-ui py-1" style={{ width: "auto" }}>
                                        {
                                          careNavigatorTransferStatus.callStatus === "idle" ?

                                            <button type="button" className="btn" onClick={e => transferCallToCareNavigator()}
                                              disabled={callReportActionCompleted || isSlotAlreadyBooked || callStatus === "idle" || requestStatus.isTransferCallLoading ? 'disabled' : ""}
                                            >
                                              {
                                                requestStatus.isTransferCallLoading ? "Dialing" : "Dial"
                                              }
                                            </button> :
                                            <button type="button" className="btn" onClick={e => transferCallToCareNavigator()} disabled>
                                              {careNavigatorTransferStatus.callDisplayStatus}
                                            </button>
                                        }

                                        {
                                          careNavigatorTransferStatus.callStatus === "in-progress" &&
                                          <>
                                            <button
                                              type="button"
                                              className="btn"
                                              onClick={e => mergeCallWithCareNavigator(e)}
                                            >
                                              <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/b43sh/j4thc4t2h3.png" style={{ height: "20px" }} alt="" />
                                            </button>
                                            {
                                              !cnHold ?
                                                <button
                                                  type="button"
                                                  className="btn"
                                                  onClick={e => popupHoldCall("cn")}
                                                >
                                                  <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/whkuh/7jghcsm4d2.png" style={{ height: "20px" }} alt="" />
                                                </button> :

                                                <button
                                                  type="button"
                                                  className="btn"
                                                  onClick={e => popupUnHoldCall("cn")}
                                                >
                                                  <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/whkuh/7jghcsm4d2.png" style={{ height: "20px" }} alt="" />
                                                  unhold
                                                </button>
                                            }


                                          </>
                                        }


                                        {
                                          careNavigatorTransferStatus.callStatus !== "idle" && !isCallMergedWithCn &&
                                          <button
                                            type="button"
                                            className="btn"
                                            onClick={e => dropAgentFromCall(e, "cn", careNavigatorId)}
                                          >
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3a84c/b47h3jwh48.png" style={{ height: "22px" }} alt="" />
                                          </button>
                                        }
                                      </div>
                                      {
                                        isCallMergedWithCn &&
                                        <button
                                          type="button"
                                          className="btn coach-call-exit-btn ms-4"
                                          onClick={e => dropAgentFromCall(e, "agent", careNavigatorId)}
                                        >
                                          Exit Call
                                        </button>
                                      }
                                    </div>
                                  </div>
                                </div>
                              }
                              {/* <hr /> */}
                            </div>
                          </>
                        )}

                        {callReport.doesMemberWantToEnroll === "enroll_later" && (
                          <div className="col-12 mb-2 mt-5">
                            <div className="tw-flex tw-justify-between tw-items-center tw-gap-4 tw-w-full">
                              <DatePicker
                                selected={enrollLaterForm.date}
                                onChange={(updatedDate) => {
                                  setEnrollLaterFormErrors({ ...enrollLaterFormErrors, date: "" })
                                  setEnrollLaterForm({ date: updatedDate }
                                  )
                                }
                                }

                                showTimeSelect
                                timeIntervals={15}
                                minDate={new Date()}
                                minTime={enrollLaterForm.date > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                maxTime={new Date().setHours(23, 59, 59)}
                                timeInputLabel="Time:"
                                dateFormat="dd/MM/yyyy h:mm aa"
                                placeholderText="Select Date and Time"
                                className='form-control control-input-ui me-3'
                                disabled={callReportActionCompleted ? "disabled" : ""}
                              />

                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                onClick={() => registerLaterEnrollment()}
                                disabled={requestStatus.isEnrollLaterLoading ? "disabled" : "" || callReportActionCompleted ? "disabled" : ""}
                              >

                                Register
                              </button>
                            </div>
                            {enrollLaterFormErrors["date"] && (
                              <div className="text-danger">
                                {enrollLaterFormErrors["date"]}
                              </div>
                            )}
                          </div>
                        )}



                        {callReport.doesMemberWantToEnroll === "" ? (
                          <>
                            <div className="mt-3">


                              {callBackSubmissionMessage?.message?.length >
                                0 ? (
                                <div className="col-12">
                                  <div className="form-check-inline">

                                    <div className="response-message">
                                      <div
                                        className={
                                          callBackSubmissionMessage.status
                                            ? "text-success"
                                            : "text-danger"
                                        }
                                      >
                                        {callBackSubmissionMessage.message}
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}


                              <div className="row">
                                <div className="col-12">
                                  <label
                                    className="col-form-label"
                                  >
                                    Call Back Request
                                  </label>
                                </div>
                                <div className="col-12">

                                  {/* <input
                                    type="date"
                                    id="inputdate"
                                    name="date"
                                    value={callbackForm.date}
                                    onChange={(e) => callBackOnChange(e)}
                                    className="form-control input-field-ui"
                                    
                                  /> */}

                                  <DatePicker
                                    selected={callbackForm.date}
                                    onChange={updatedDate => setCallbackForm({ ...callbackForm, date: updatedDate })}
                                    showTimeSelect
                                    timeIntervals={15}
                                    minDate={new Date()}
                                    minTime={callbackForm.date > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                    maxTime={new Date().setHours(23, 59, 59)}
                                    timeInputLabel="Time:"
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    placeholderText="Select Date and Time"
                                    className='form-control input-field-ui'
                                    disabled={callReportActionCompleted ? "disabled" : ""}
                                  />





                                  {callbackFormErrors["date"] && (
                                    <div className="text-danger">
                                      {callbackFormErrors["date"]}
                                    </div>
                                  )}
                                </div>
                                {/* <div className="col-6">

                                  <input
                                    type="time"
                                    id="inputtime"
                                    name="time"
                                    value={callbackForm.time}
                                    onChange={(e) => callBackOnChange(e)}
                                    className="form-control input-field-ui"
                                  />
                                  {callbackFormErrors["time"] && (
                                    <div className="text-danger">
                                      {callbackFormErrors["time"]}
                                    </div>
                                  )}
                                </div> */}
                              </div>
                            </div>

                            <div className="col-12 mt-3">
                              <h2 className="user-card-text mb-2">
                                Notes/Comments (Internal)
                              </h2>
                            </div>
                            <div className="col-12">
                              <textarea
                                className="form-control input-field-ui"
                                id="yourSelf"
                                placeholder="Take Notes"
                                rows="5F"
                                name="notes"
                                value={callbackForm.notes}
                                onChange={(e) => callBackOnChange(e)}
                                disabled={callReportActionCompleted ? "disabled" : ""}
                              ></textarea>
                              {callbackFormErrors["notes"] && (
                                <div className="text-danger">
                                  {callbackFormErrors["notes"]}
                                </div>
                              )}
                            </div>

                            <div className="mt-3 text-center">
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                onClick={() => popupCallBackPlan()}
                                disabled={requestStatus.isCallbackLoading || callReportActionCompleted ? "disabled" : ""}
                              >
                                Schedule A Call Back
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}



                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      {
                        callReport.doesMemberWantToEnroll !== "yes" &&
                        <button
                          className="btn btn-dark coach-call-enroll-btn text-center"
                          onClick={e => markMockDataAsIncorrect()}
                          disabled={requestStatus.isIncorrectDataLoading || callReportActionCompleted && "disbaled"}
                        >
                          Incorrect Data
                        </button>
                      }
                    </div>
                  </div>



                  <div className="col-md-1">
                    <div className="">
                      <div className="row">
                        {/* <div className="col-12">
                          <h5 className="user-card-title mb-1">
                            Notes/Comments (ext)
                          </h5>
                        </div> */}
                        <div
                          className="col"
                        // style={{ position: "relative" }}
                        >
                          {/* <textarea
                            className="form-control input-field-ui"
                            id="yourSelf"
                            placeholder="Take Notes"
                            rows="17"
                          ></textarea> */}
                          <div className="agent-bg-box-grey text-center pb-2"
                            style={{ cursor: "pointer" }}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightEng1"
                            aria-controls="offcanvasRightEng1"
                          >
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/njqhv/3ushhs30d3.png"

                              className="img-fluid"
                              alt=""
                            />
                            <label style={{ fontSize: "12px" }}>English</label>
                          </div>

                          <div className="agent-bg-box-grey text-center pb-2 mt-2"
                            style={{ cursor: "pointer" }}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightArb1"
                            aria-controls="offcanvasRightArb1"
                          >
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/njqhv/3ushhs30d3.png"

                              className="img-fluid"
                              alt=""
                            />
                            <label style={{ fontSize: "12px" }}>Arabic</label>
                          </div>

                          <div className="agent-bg-box-grey text-center pb-2 mt-2"
                            style={{ cursor: "pointer" }}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightNotes"
                            aria-controls="offcanvasRightNotes"
                          >
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/go71k/d8f8z1fh7h.png"

                              className="img-fluid"
                              alt=""
                            />
                            <label style={{ fontSize: "12px" }}>Notes</label>
                          </div>

                          {(memberCallList?.length > 0 || memberCallBackList?.length > 0) && <div className="agent-bg-box-grey text-center pb-2 mt-2"
                            onClick={handleShowcallHistoryDetailsModal}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/h37j3/yjxakabhmu.png"

                              className="img-fluid mb-1"
                              alt=""
                            />
                            <label style={{ fontSize: "12px", lineHeight: "1" }}>Call History</label>
                          </div>}

                          <div
                            className="offcanvas offcanvas-end box-script-ui"
                            // style={{width:"30%"}}
                            tabindex="-1"
                            id="offcanvasRightEng1"
                            aria-labelledby="offcanvasRightEng1Label"
                            data-bs-backdrop="static"
                          >
                            <div className="offcanvas-header pt-2 pb-1">
                              {/* <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                            </div>
                            <div className="offcanvas-body py-0">
                              <div className="row">
                                <textarea className="col mb-3" rows="22" style={{ textAlign: "justify", border: "0", backgroundColor: 'transparent' }} disabled>
                                  {scriptData[0]?.script}
                                </textarea>
                                <div className="col-auto">
                                  <button
                                    type="button"
                                    className="btn btn-dark coach-call-enroll-btn"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>

                            </div>
                            {/* <div className="offcanvas-footer px-3 py-3 pt-2">
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Close
                              </button>
                            </div> */}
                          </div>


                          <div
                            className="offcanvas offcanvas-end box-script-ui"
                            // style={{width:"30%"}}
                            tabindex="-1"
                            id="offcanvasRightArb1"
                            aria-labelledby="offcanvasRightArb1Label"
                            data-bs-backdrop="static"
                          >
                            <div className="offcanvas-header pt-2 pb-1 text-end" style={{ justifyContent: "flex-end" }}>

                              {/* <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                            </div>
                            <div className="offcanvas-body py-0">
                              <div className="row">
                                <textarea className="col mb-3" rows="22" style={{ textAlign: "right", border: "0", backgroundColor: 'transparent' }} disabled>
                                  {arabicScriptData[0]?.script}
                                </textarea>
                                <div className="col-auto">
                                  <button
                                    type="button"
                                    className="btn btn-dark coach-call-enroll-btn"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                              {/* <div className=" text-end mb-3">
                              <textarea> 
                                {arabicScriptData[0]?.script}
                              </textarea>
                              </div> */}
                            </div>
                            {/* <div className="offcanvas-footer text-end px-3 py-3 pt-2">
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Close
                              </button>
                            </div> */}
                          </div>


                          <div
                            className="offcanvas offcanvas-end box-script-ui box-script-ui-width100"
                            tabindex="-1"
                            id="offcanvasRightNotes"
                            aria-labelledby="offcanvasRightNotesLabel"
                            data-bs-backdrop="static"
                          >
                            <div className="offcanvas-header pt-2 pb-1 text-end" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              {/* <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                              <button
                                type="button"
                                className="btn btn-dark coach-call-enroll-btn"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Close
                              </button>
                            </div>
                            <div className="offcanvas-body py-0">
                              <div className="row">
                                <div className="col-12">
                                  <div className="table-responsive" style={{ height: "350px", overflowY: "scroll" }}>
                                    <table className="table mb-0 ">
                                      <thead style={{ position: "sticky", top: "0" }}>
                                        <tr>
                                          <th scope="col" style={{ width: "35%" }}>Agent Name</th>
                                          <th scope="col" style={{ width: "50%" }}>Notes</th>
                                          <th scope="col" style={{ width: "50%" }}>Date</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          callNotesList && callNotesList.length > 0 && callNotesList.map((note, index) => (
                                            <tr key={index}>
                                              <td>{note.agentName}</td>
                                              <td>{note.notes}</td>
                                              <td>{note.date}</td>
                                            </tr>
                                          ))
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div className="offcanvas-footer px-3 py-3 pt-2">
                              <div className="row">
                                <div className="col-12">
                                  <textarea
                                    className="input-field-ui"
                                    rows="3"
                                    name="callNotes"
                                    value={callNotes}
                                    onChange={e => changeCallNotes(e)}
                                    required
                                  ></textarea>
                                  {
                                    callNotesErrors && <p className="text-danger">{callNotesErrors}</p>
                                  }

                                  {
                                    callNotesSubmissionMessage && <p className="text-success">{callNotesSubmissionMessage}</p>
                                  }

                                </div>


                                <div className="col-12">
                                  <button
                                    type="button"
                                    className="btn btn-dark coach-call-enroll-btn"
                                    onClick={e => saveCallNotes(e, data.encUserId)}
                                  >
                                    Save
                                  </button>
                                </div>

                              </div>


                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer" style={{ justifyContent: "center" }}>
                <button
                  ref={closeMockModal}
                  type="button"
                  className="btn btn-secondary clear-btn"
                  data-bs-dismiss="modal"
                  disabled={callStatus !== "idle" && "disbaled"}
                  onClick={e => mockCallPopupClose(e)}
                >
                  Close
                </button>
              </div>
            </div>
          ))}
        </div>

      </div>

      <div
        className="modal fade"
        id="scriptModalModal"
        tabindex="1"
        aria-labelledby="scriptModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Script</h1>
              {/* <button type="button" className="btn-close" data-bs-target="#agentCallModal" data-bs-toggle="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              <h4 className="modal-subtitle mb-3">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
                rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum
                felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
                Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.
              </h4>
              <button
                type="button"
                className="btn btn-dark coach-call-enroll-btn"
                data-bs-target="#agentCallModal"
                data-bs-toggle="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="enrollModalModal"
        tabindex="1"
        aria-labelledby="enrollModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Script</h1>
              {/* <button type="button" className="btn-close" data-bs-target="#agentCallModal" data-bs-toggle="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              <h4 className="modal-subtitle mb-3">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
                rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum
                felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
                Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.
              </h4>
              <button
                type="button"
                className="btn btn-dark coach-call-enroll-btn"
                data-bs-target="#closeModel"
                data-bs-toggle="modal"

              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


      {/*  dispossition modal  */}
      <Modal show={showDispositionModal} onHide={() => { setShowDispositionModal(false); setReflect(!reflect) }} centered backdrop="static">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="pt-0">
          {dispositionSubmissionMessage?.message?.length >
            0 ? (
            <div
              className={
                dispositionSubmissionMessage.status
                  ? "text-success"
                  : "text-danger"
              }
            >
              {dispositionSubmissionMessage.message}
            </div>
          ) : (
            ""
          )}

          <div className="disposition_from">
            <div className="">
              <h2 className="module-card-title mb-2">Data Rating</h2>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="dataRating"
                  id="dataRating"
                  value="1"
                  onChange={e => handleDispositionChange(e)}

                />
                <label className="form-check-label" htmlFor="inlineRadio3">
                  Correct
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="dataRating"
                  id="dataRating2"
                  value="2"
                  onChange={e => handleDispositionChange(e)}


                />
                <label className="form-check-label" htmlFor="inlineRadio4">
                  Incorrect
                </label>



              </div>


              {dispositionFormErrors["dataRating"] && (
                <div className="text-danger">
                  {dispositionFormErrors["dataRating"]}
                </div>
              )}


            </div>
            <div className="mt-4">
              {/* <h2 className="module-card-title mb-2">Select Reason</h2> */}
              <label className="form-label">Select Reason</label>
              <select
                className="form-select input-field-ui"
                name="reason"
                value={dispositionForm.reason}
                onChange={e => handleDispositionChange(e)}
              >
                <option value="">Select Reason</option>
                {
                  dispositionForm.dataRating.length > 0 &&
                  <>
                    {
                      dispositionForm.dataRating === "1" ?

                        dispositionReasons.good.map((reason,ind) => (
                          <option key={ind} value={reason}>{reason}</option>
                        ))
                        :
                        dispositionReasons.poor.map((reason, ind) => (
                          <option key={ind} value={reason}>{reason}</option>
                        ))

                    }

                    <option value="Other">Other</option>
                  </>

                }
              </select>


              {
                dispositionFormErrors["reason"] && (
                  <div className="text-danger">
                    {dispositionFormErrors["reason"]}
                  </div>
                )}
            </div>
            {
              dispositionForm.reason === "Other" &&
              <div className="mt-3">
                <textarea
                  className="form-control input-field-ui"
                  placeholder="Add Remarks"
                  rows="3"
                  name="remarks"
                  value={dispositionForm.remarks}
                  onChange={e => handleDispositionChange(e)}
                ></textarea>

              </div>

            }


            <div className="mt-3">
              <button className="btn btn-success coach-call-enroll-btn" onClick={e => saveDisposition(e)}
                disabled={requestStatus.isDispositionSaveLoading ? "disabled" : ""}
              >
                Save
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      {/*  mock disposition modal  */}
      <Modal show={showMockDispositionModal} onHide={() => { setShowMockDispositionModal(false); setCallReportAction(""); setReflect(!reflect) }} centered backdrop="static">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="pt-0">
          {dispositionSubmissionMessage?.message?.length >
            0 ? (
            <div
              className={
                dispositionSubmissionMessage.status
                  ? "text-success"
                  : "text-danger"
              }
            >
              {dispositionSubmissionMessage.message}
            </div>
          ) : (
            ""
          )}

          <div className="disposition_from">
            <div className="">

                <div className="call-status mb-4">
                  <h2 className="module-card-title mb-2">Call Status</h2>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callStatus"
                      id="call_status"
                      value="completed"
                      onChange={e => handleMockDispositionChange(e)}

                    />
                    <label className="form-check-label" htmlFor="inlineRadio3">
                      Completed
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callStatus"
                      id="call_status2"
                      value="no-answer"
                      onChange={e => handleMockDispositionChange(e)}


                    />
                    <label className="form-check-label" htmlFor="inlineRadio4">
                      No-answer
                    </label>



                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callStatus"
                      id="dataRating2"
                      value="busy"
                      onChange={e => handleMockDispositionChange(e)}
                    />

                    <label className="form-check-label" htmlFor="inlineRadio4">
                      Busy
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callStatus"
                      id="dataRating2"
                      value="failed"
                      onChange={e => handleMockDispositionChange(e)}
                    />

                    <label className="form-check-label" htmlFor="inlineRadio4">
                      Failed
                    </label>
                  </div>


                </div>
              

              {
                callReportActionType !== "" &&

                <div>

                  <h5 className="user-card-title">Data Rating  {callReport.doesMemberWantToEnroll}  {callReportActionType === "correct" ? "- Correct" : (callReportActionType === "incorrect" ? "- Incorrect" : "")}</h5>

                  {
                    callReportActionType !== "incorrect" && callReportAction === "not_enroll" &&

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="dataRating"
                        id="dataRating"
                        value="1"
                        onChange={e => handleMockDispositionChange(e)}
                        defaultChecked={callReportActionType === "correct" && "checked"}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio3">
                        Correct
                      </label>
                    </div>
                  }

                  {
                    callReportActionType !== "correct" && callReportAction === "not_enroll" &&

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="dataRating"
                        id="dataRating2"
                        value="2"
                        onChange={e => handleMockDispositionChange(e)}
                        defaultChecked={callReportActionType === "incorrect" && "checked"}

                      />
                      <label className="form-check-label" htmlFor="inlineRadio4">
                        Incorrect
                      </label>

                    </div>


                  }




                  {/* {mockDispositionFormErrors["dataRating"] && (
                                        <div className="text-danger">
                                          {mockDispositionFormErrors["dataRating"]}
                                        </div>
                                      )} */}


                  {/* </div> */}
                  <div className="mt-2">
                    {/* <h2 className="module-card-title mb-2">Select Reason</h2> */}
                    <label className="form-label">Select Reason {callReportAction != "not_enroll" && <span style={{ color: "red" }}>*</span>}</label>
                    <select
                      className="form-select input-field-ui"
                      name="reason"
                      value={mockDispositionForm.reason}
                      onChange={e => handleMockDispositionChange(e)}
                    >
                      <option value="">Select Reason</option>
                      {
                        mockDispositionForm.dataRating.length > 0 &&
                        <>
                          {
                            mockDispositionForm.dataRating === "1" ?

                              dispositionReasons.good.map((reason, ind) => (
                                <option key={ind} value={reason}>{reason}</option>
                              ))
                              :
                              dispositionReasons.poor.map((reason, ind) => (
                                <option key={ind} value={reason}>{reason}</option>
                              ))

                          }

                          <option value="Other">Other</option>
                        </>

                      }
                    </select>


                    {
                      mockDispositionFormErrors["reason"] && (
                        <div className="text-danger">
                          {mockDispositionFormErrors["reason"]}
                        </div>
                      )}
                  </div>

                  <div className="mt-3">
                    <label>Add Remarks</label>
                    <textarea
                      className="form-control input-field-ui"
                      placeholder="Add Remarks"
                      rows="3"
                      name="remarks"
                      value={mockDispositionForm.remarks}
                      onChange={e => handleMockDispositionChange(e)}
                    ></textarea>
                  </div>
                </div>
              }

              <div className="mt-3">
                <button className="btn btn-success coach-call-enroll-btn" onClick={e => saveMockDisposition(e)}
                  disabled={requestStatus.isDispositionSaveLoading ? "disabled" : ""}
                >
                  Save
                </button>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>



      <Modal id="callHistoryDetails" show={showcallHistoryDetailsModal} onHide={() => setShowcallHistoryDetailsModal(false)} centered size="xl" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title me-4'>{singleUserData.memberName}</h1>
          {/* <label className="col-form-label pt-0 me-4"> <i className="me-2"><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/nuf9q/dhb4tjf45a.png" style={{height:"14px", verticalAlign:"baseline"}} alt="" /></i>HOF: Irfan Khan</label> */}
        </Modal.Header>
        <Modal.Body>
          <div className='row'>

            {

              <div className="col-12 mb-5">
                <h4>Call Back History</h4>
                <div className="table-responsive" style={{ height: memberCallList && memberCallList.length > 0 ? 'auto' : '420px', overflowY: "scroll" }}>
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Agent Name</th>
                        <th scope="col">Call Back Request Date</th>
                        <th scope="col">Call Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {memberCallBackList && memberCallBackList?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.agentName}</td>
                          <td>{item.scheduledDateTime}</td>
                          <td>{item.notes || "-"}</td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            }

            {
              memberCallList && memberCallList.length > 0 &&
              <>
                <div className="col-12">
                  <h4>Call History</h4>
                  <div className="table-responsive" style={{ height: "420px", overflowY: "scroll" }}>
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Agent Name</th>
                          <th scope="col">Enrollment Status</th>
                          <th scope="col">Call Duration</th>
                          <th scope="col">Call Status</th>
                          <th scope="col">Data Rating </th>
                          <th scope="col">Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberCallList?.map((item, index) => (
                          <tr key={index}>
                            <td>{item.placedCallDate}</td>
                            <td>{item.agentName}</td>
                            <td>{item.enrollStatus}</td>
                            <td>{item.callDuration}</td>
                            <td>{item.callStatus}</td>
                            <td>{item.dataRating || "-"}</td>
                            <td>{item.reason || "-"}</td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div></>
            }
          </div>
        </Modal.Body>
      </Modal>

      {/* ---------------------------- already added user ------------------------------ */}
      <Modal id="callHistoryDetails" show={isMemberAlreadyEnrolled} onHide={() => setIsMemberAlreadyEnrolled(false)} centered size="lg" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton center>
        </Modal.Header>
        <Modal.Body>
          <div className='row text-center'>
            <p>
              {alreadyOnboardedMessgae}
            </p>
          </div>
          <div className="text-center">
            <button className="btn btn-secondary text-center" onClick={e => setIsMemberAlreadyEnrolled(false)}>Close</button>
          </div>
        </Modal.Body>
      </Modal>


      {callCloseWarningPopup && (
        <div className='tw-w-full tw-h-full tw-overflow-auto tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-z-[1055]'>

        <Modal id="callCloseWarning" show={callCloseWarningPopup} onHide={() => setCallCloseWarningPopup(false)} centered size="lg" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton center>
        </Modal.Header>
        <Modal.Body>
          <div className='row text-center'>
            <h4>Action Required </h4>
            <p>
              Please provide a data rating before closing.
            </p>  
          </div>
        </Modal.Body>
      </Modal>
      </div>
      )
      }



      {/*  ----- Add Member Modal ---- */}
      {showAdMemberModal && <AddMember refreshStatus={reflect} refresh={setReflect} status="add" heading="Add New Member" hideModal={() => setShowAdMemberModal(false)} postAddMemberCheck={postAddMemberCheck} mainMembershipNo={addingMemberFrom === "in" ? singleUserDataList[0]?.mainMembershipNo: ''} />}

      {/*  ----- Check Microphone Permission Modal ---- */} 
      {
        showCheckMicrophonePermissionModal &&
        <MicrohponeCheck showCheckMicrophonePermissionModal={showCheckMicrophonePermissionModal} setShowCheckMicrophonePermissionModal={setShowCheckMicrophonePermissionModal} />
      }


      {
          showTelehealthModal && <TeleHealthBook showTelehealthModal={showTelehealthModal} closeTelehealthModal={closeTelehealthModal} handleShowUploadTeleHealthReschedule={() => {}} callCallsHistory={() => {setShowTeleHealthModal(false);}} membershipNo={singleUserData['memberId']} userId={singleUserData.encUserId} from={"memberconnect"} 
          fetchDoctorList={fetchDoctorList} fetchDoctorSlots = {fetchDoctorSlots} bookMemberAppointment={bookMemberAppointment} memberDetailsEdge={memberDetailsEdge}
          updateTeleHealthService={function(){}}
          />
      }

    </>
  );
}

export default ContentMemberConnectAgentDetails;